import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { useStyles } from './styles'
import {
    Box,
    Typography,
    Container,
    Link,
} from '@material-ui/core'
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import coreApi from 'app/services/core-api';

import { AdsTop } from "app/components/ads/freestar";
import { BillboardBannerApoie } from "app/components/banners/billboard";

import PillCard from 'app/components/cards/Pill'
import Skeleton from 'app/components/cards/Pill/skeleton'

import PillDialogVideo from 'app/components/Modals/Pill'

import { useSnackbar } from "app/contexts/SnackbarContext";


const responsive = {
  large: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
    paritialVisibilityGutter: 0,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1199, min: 768 },
    items: 4,
    paritialVisibilityGutter: 0

  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
}

export default (props) => {

  const classes = useStyles();
  const history = useHistory();
  const params  = useParams();

  const { setSnackValues } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  const [parentTopic, setParentTopic] = useState(null);

  const [openPillDialog, setOpenPillDialog] = React.useState(false);
  const [showPill, setShowPill] = React.useState(null);
  const handlePillDialog = (open, pill) => (event) => {
    setOpenPillDialog(open);
    setShowPill(pill);
  };

  const loadTopic = (slug) => {
    history.push(`${history.location.pathname}/${slug}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setParentTopic(null);
      setTopics([]);
      const paths = params.topics
      const slug = paths?.split('/')?.filter(Boolean)?.at(-1);
      let url = '/topics';
      url += slug ? `/${slug}/pills` : '/pills';

      try {
        setLoading(true)
        const { data } = await coreApi.get(url);
        if(slug) {
          setParentTopic({id: data.id, title: data.title, pills: data.pills});
          setTopics(data.topics);
        }
        else setTopics(data);

      } catch (error) {
        setSnackValues({
          message: "Ops! Falha ao carregar dados. Tente novamente.",
          open: true,
        });
      } finally {
        setLoading(false);
        window.scrollTo(0, 0);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  function Loader() {
    return (
      <>
        <Carousel
          itemClass={classes.carouselItem}
          responsive={responsive}
          autoPlay={false}
          infinite={true}
          arrows={true}
          partialVisible
        >
          {[0,1,2,3,4,5,6,7].map( (item,key) => {
              return (
                <Skeleton key={`sk-${key}`} height={600} width={300} />
              )
            })
          }
        </Carousel>
      </>
    )
  }

  function ParentTopicTitle({title}) {
    return (
      <Box component="div" display="flex" flexDirection="row" mb={2}>
        <Typography
          className={classes.topicParentTitle}
          component="span"
        >
          {title}
        </Typography>
      </Box>
    )
  }

  function TopicTitle({title, url}) {
    return (
      <Box component="div" display="flex" flexDirection="row" mb={2}>
        <Typography
          className={classes.topicTitle}
          component="span"
        >
          {title}
        </Typography>
        <Link
          component="button"
          onClick={() => loadTopic(url)}
          className={classes.seeMore}
        >
          Ver tudo <ChevronRightIcon />
        </Link>
      </Box>
    )
  }

  function TopicPills({pills = []}) {
    return (
      <>
        <Carousel
          itemClass={classes.carouselItem}
          responsive={responsive}
          autoPlay={false}
          infinite={true}
          arrows={true}
        >
          {pills.map( (pill, key) => {
              return (
                <PillCard
                  key={`pillCard_${key}`}
                  handlePillDialog={handlePillDialog}
                  pill={pill}
                />
              )
            })
          }
        </Carousel>
      </>
    )
  }



  return (
    <React.Fragment>
      <Box component="div" mb={9}>
        <Container maxWidth="xl">
          <Typography component="h1" variant="h1" className={classes.title}>Pílulas de Conhecimento</Typography>
          <Box mb={4}><BillboardBannerApoie fallbackBanner={<AdsTop/>} /></Box>

          {loading && (<Box mb={3}><Loader /></Box>)}

          {
            !loading && parentTopic ? (
              <Box mb={4}>
                <ParentTopicTitle title={parentTopic.title} />
                <TopicPills pills={parentTopic.pills} />
              </Box>
            ) : ''
          }
          {
            !loading && topics.map((topic, key) => {
              return (
                <Box mb={4}>
                  <TopicTitle title={topic.title} url={topic.slug} />
                  <TopicPills pills={topic.pills} />
                </Box>
              )
            })
          }
        </Container>
      </Box>
      <PillDialogVideo
        open={openPillDialog}
        toggleDialog={handlePillDialog}
        pillId={showPill?.uuid}
      />
    </React.Fragment>
  )
}
