import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  wrap: {
    // [theme.breakpoints.up('lg')]: {
    //   marginLeft: theme.overrides.MuiDrawer.paper.width,
    //   width: `calc(100% - ${theme.overrides.MuiDrawer.paper.width}px)`,
    // },
    flexGrow: 1,
    width: "100%",
  }
}))

export { useStyles }
