import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(26),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
        textAlign: 'center'
    }
  },
  topicParentTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.spacing(1),
  },
  topicTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
  },
  seeMore: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  noCoursesFallback: {
    maxWidth: 600,
    textAlign: 'center'
  },
  noCoursesCard: {
    padding: theme.spacing(3)
  },
  noCoursesTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(22),
    marginBottom: theme.spacing(2),
  },
  noCoursesSubtitle: {
    marginBottom: theme.spacing(1),
  },
  carouselItem: {
    maxWidth: 250,
    minWidth:250,
    paddingLeft: theme.spacing(.5),
    paddingRight: theme.spacing(.5),
  },
}))

export { useStyles }
