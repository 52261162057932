/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import dataAccordion from "./data";

import {
  useStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./styles";

function AccordionSectionKlubi({ faqRef }) {
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.content} component="section" ref={faqRef}>
      <Typography variant="h4" className={classes.title}>
        Perguntas Frequentes
      </Typography>

      <Container>
        {dataAccordion.map((accordion, index) => {
          const isActive =
            expanded === accordion.panel ? classes.active : classes.heading;

          return (
            <Accordion
              expanded={expanded === accordion.panel}
              onChange={handleChange(accordion.panel)}
              className={[classes.accordionSummary, classes.accordion]}
              key={`${accordion.id}-${index}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={accordion.controls}
                id={accordion.id}
                className={classes.accordionSummary}
              >
                <Typography className={isActive}>{accordion.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography className={classes.text}>
                  {accordion.content}
                </Typography>

                {accordion.contentSecondary && (
                  <Typography className={classes.text}>
                    {accordion.contentSecondary}
                  </Typography>
                )}
                {accordion.contentTertiary && (
                  <Typography className={classes.text}>
                    {accordion.contentTertiary}
                  </Typography>
                )}

                {accordion.steps && (
                  <Box className={classes.step}>
                    {accordion.steps?.map((step, index) => (
                      <Box
                        key={`${step.title}-${index}`}
                        className={classes.contentStep}
                      >
                        <Typography className={classes.contentTitle}>
                          {step.title}
                        </Typography>
                        <img
                          className={classes.image}
                          src={step.thumbnail}
                          alt={`${index}`}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {accordion.list && (
                  <Box className={classes.listPaths}>
                    {accordion.list.map((itemList) => (
                      <Box className={classes.listItem}>
                        <Typography className={classes.titleItem}>
                          {itemList.title}
                        </Typography>
                        <Link to={itemList.path}>{itemList.path}</Link>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Container>
    </Box>
  );
}

export default AccordionSectionKlubi;
