import { makeStyles } from '@material-ui/core/styles'

const mediaHeight = 150

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
    category: {
        color: theme.palette.grey[600],
        fontSize: theme.typography.pxToRem(9),
        letterSpacing: theme.typography.pxToRem(1),
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    actionArea: {
        height: "100%",
    },
    media: {
        height: mediaHeight,
        position: 'relative',
        overflow: 'hidden',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        height: `calc(100% - ${mediaHeight}px)`, //toda a altura do card - tamanho da imagem
    },
    title: {
        fontWeight: 700,
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(16),
    },
    summary: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
    },
    infoIcon: {
        marginRight: theme.spacing(.5),
        color: theme.palette.grey[500],
    },
    infoContent: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.grey[500],
    },
    infoIconLink: {
        color: theme.palette.primary['main']
    },
    skeleton: {
        boxShadow: '0px 2px 6px rgba(92,84,122,0.2)',
        borderRadius: '10px',
    }
}))

export { useStyles }
