import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 200,
    color: theme.palette.common.white,
  },
  cardActionArea: {
    display: "flex",
  },
  cardContent: {
    width: "100%",
    height: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(2),
  },
  cardFooter: {
    width: "100%",
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: "#FFF",
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
    marginRight: theme.spacing(.7),
    backgroundColor: theme.palette.primary['main'],
  },
  checkedProfile: {
    fontSize: theme.typography.pxToRem(14),
    color: "#44abfe",
    marginLeft: theme.spacing(.1)
  },
  creatorName: {
    fontSize: theme.typography.pxToRem(14)
  }
}))

export { useStyles }