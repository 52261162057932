import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    backgroundImage: "linear-gradient(#1a1a1a, #1e1e1e, #292929)",
    padding: "1.68rem 4rem",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      padding: "2rem 0",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  contentLeft: {
    width: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "0 2rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 2rem",
    },
  },
  contentInfo: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  infoTitle: {
    fontSize: theme.typography.pxToRem(27),
    fontWeight: "bold",
    color: "#FEF080",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(20),
    color: "#FFFFFF",
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  cta: {
    backgroundColor: "#FFFFFF",
    fontSize: theme.typography.pxToRem(17),
  },
  contentRight: {
    width: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  heroThumbnail: {
    width: "300px",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export { useStyles };
