/**
 * Form responsável por realizar a troca de senha do usuário */

import React, {useState, useEffect} from 'react'
import {
    Box,
    Grid,
    IconButton,
    Button,
    InputLabel,
    InputAdornment,
    FormControl,
    FormHelperText,
    CircularProgress,
    Snackbar,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles } from './styles'

// API file
import accountApi from 'app/services/account-api';

// Lotties animations
import Lottie from 'react-lottie';
import shieldLottie from 'lotties/shield'

import { useAuth } from 'app/contexts/AuthContext'

const validationSchema = yup.object({
    password: yup
      .string('Digite a senha atual')
      .required('Campo obrigatório')
      .min(6,"O campo deve conter no mínimo 6 caracteres"),
    newPassword: yup
      .string('Digite a nova senha')
      .required('Campo obrigatório')
      .min(6,"O campo deve conter no mínimo 6 caracteres"),
    password_confirmation: yup
      .string('Confirme a nova senha')
      .required('Campo obrigatório')
      .oneOf([yup.ref('newPassword'), null], 'Nova senha e confirmação de senha não coincidem')
      .min(6,"O campo deve conter no mínimo 6 caracteres")
});

export default (props) => {

    const classes = useStyles();

    const { user } = useAuth()
    const [canChangePassword, setCanChangePassword] = React.useState(false)

    useEffect(()=>{

        setCanChangePassword( user.provider === 1 )

    },[user])

    const [showPassword, setShowPassword] = useState(false)

    const [snackValues, setSnackValues] = React.useState({
        message: '',
        open: false,
    });

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackValues({open:false});
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            password_confirmation: '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values,actions) => {

            actions.setSubmitting(true);

            accountApi.patch(`/me/change-password`, values)
            .then((response) => {
                setSnackValues({message:"Seus dados foram atualizados com sucesso.",open:true});
                actions.resetForm()
            })
            .catch((error) => {
                const { response } = error;
                if (response && response.data) {
                  const {
                    data: { errors },
                  } = response;
                  setSnackValues({
                    message: errors,
                    open: true,
                  });
                } else {
                  setSnackValues({message:"Ops! Falha ao atualizar seus dados. Tente novamente.",open:true});
                }
            })
            .finally( () => {
                actions.setSubmitting(false);
            })
        },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <React.Fragment>
            {!canChangePassword ? (
                <Box component="div" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box component="div" mb={5}>
                        <Lottie
                            style={{
                            maxWidth: 130,
                            padding: 0,
                            margin: 0,
                            }}
                            options={{
                            loop: true,
                            autoplay: true,
                            animationData: shieldLottie,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                            }}
                        />
                    </Box>
                    <Box component="div">
                        <Typography component="p" variant="body2" className={classes.cardSubtitle}>
                            Você se inscreveu na Kultivi usando passaporte Google, Facebook ou Apple. Quando você faz uso de uma dessas opções não é possível alterar a senha.
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <form name="form-password" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                disabled={!canChangePassword}
                            >
                                <InputLabel htmlFor="password">Senha atual</InputLabel>
                                <OutlinedInput
                                    disabled={!canChangePassword}
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    aria-describedby="error-password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="error-password">{formik.touched.password && formik.errors.password}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                disabled={!canChangePassword}
                            >
                                <InputLabel htmlFor="newPassword">Nova senha</InputLabel>
                                <OutlinedInput
                                    disabled={!canChangePassword}
                                    id="newPassword"
                                    name="newPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    aria-describedby="error-newPassword"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="error-password">{formik.touched.newPassword && formik.errors.newPassword}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                disabled={!canChangePassword}
                            >
                                <InputLabel htmlFor="password_confirmation">Confirmar nova senha</InputLabel>
                                <OutlinedInput
                                    notched={true}
                                    disabled={!canChangePassword}
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    aria-describedby="error-password_confirmation"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="error-password_confirmation">{formik.touched.password_confirmation && formik.errors.password_confirmation}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="div" textAlign="right">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    { formik.isSubmitting ? (
                                        <CircularProgress size={30} color="inherit" />
                                    ) : 'Alterar senha' }</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={snackValues.open}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={snackValues.message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}
