import React from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
// Ui Files
import {
  Box,
  Grid,
  IconButton,
  Button,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  CircularProgress,
  Snackbar,
  OutlinedInput,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import accountApi from 'app/services/account-api';

import { resetPasswordValidator } from 'app/validators/auth'

const ResetPassword = (props) => {

  const { token } = props

  const history = useHistory()
  const [showPassword, setShowPassword] = React.useState(false)
  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  })
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackValues({ open: false });
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: resetPasswordValidator,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, actions) => {

      actions.setSubmitting(true)

      const data = {
        token: token,
        password: values.password,
      }

      accountApi.post('/reset-password', data)
        .then((reponse) => {
          setSnackValues({ message: "Sua senha foi resetada com sucesso! Faça seu login para continuar", open: true })
          setTimeout(function () { history.push('/') }, 3000)
        })
        .catch((error) => {
          setSnackValues({ message: "Ops! Falha ao atualizar seus dados. Tente novamente.", open: true })
          const errors = error.response.data
          for (let index = 0; index < errors.length; index++) {
            const error = errors[index];
            var setErr = {};
            setErr[`${error.field}`] = error.message;
            actions.setErrors(setErr)
          }
        }).finally(() => {
          actions.setSubmitting(false);
        })
    },
  });


  return (
    <>
      <form name="form-password" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={formik.touched.password && Boolean(formik.errors.password)}
            >
              <InputLabel htmlFor="password">Nova senha</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-describedby="error-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }

              />
              <FormHelperText id="error-password">{formik.touched.password && formik.errors.password}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
            >
              <InputLabel htmlFor="password_confirmation">Confirmar nova senha</InputLabel>
              <OutlinedInput
                notched={true}
                id="password_confirmation"
                name="password_confirmation"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-describedby="error-password_confirmation"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="error-password_confirmation">{formik.touched.password_confirmation && formik.errors.password_confirmation}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" textAlign="right">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : 'Resetar Senha!'}</Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )

}

export default ResetPassword
