import styled, { keyframes } from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import BackgroundImg from 'assets/images/signin_bg.png';

const useStyles = makeStyles((theme) => ({
  ...contentStyles(theme),
  ...headerStyles(theme),
  ...formStyles(theme),
  ...bottomStyles(theme)
}))

const contentStyles = (theme) => ({
  leftContent: {
    backgroundColor: "#FFF",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4)
    }
  },
  content: {
    maxWidth: "70%",
    minHeight: "100vh",
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      maxWidth: "80%",
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      paddingTop: theme.spacing(1),
    },
  },
  rightContent: {
    background: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.primary['main'],
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})
const headerStyles = (theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      marginBottom: theme.spacing(1)
    },
  },
  logo: {
    width: 130,
    maxWidth: 130,
    [theme.breakpoints.only('xs')]: {
        width: "100%",
        maxWidth: 154
    },
  }
})
const formStyles = (theme) => ({
  formBlock: {
    maxWidth: "100%",
    [theme.breakpoints.up('sm')]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "60%",
    },
  },
  resendMail: {
    cursor: 'pointer'
  }
})

const bottomStyles = (theme) => ({
  bottomContent: {
    fontSize: 12
  },
  signOut: {
    cursor: 'pointer'
  },
})

export { useStyles }

// Use Styled Components
const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div `
  animation: ${apearFromLeft} 1s
`;
