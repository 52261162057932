import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loginFieldset: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[600],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14)
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  socialButton: {
    color: "#FFF",
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1),
    }
  },
  socialButtonFacebook: {
    background: '#3679FA',
    '&:hover': {
      background: '#279AE3',
    },
  },
  socialButtonGoogle: {
    background: '#DD4B38',
    '&:hover': {
      background: '#F53145',
    },
  },
  forgotPasswordButton: {
    marginLeft: 10,
    cursor: 'pointer'
  },
  policy: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600
  }
}))

export { useStyles }
