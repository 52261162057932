import React from 'react'

// FRAMEWORK IMPORTS
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Forms
import FormProfile from 'app/components/forms/Profile'

import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const {open,toggleDialog} = props;

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Box component="div" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={toggleDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        Informações pessoais
      </DialogTitle>
      <DialogContent>
        <FormProfile isDialog={true} />
      </DialogContent>
    </Dialog>
  )
}
