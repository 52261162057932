import styled, { keyframes } from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import BackgroundImg from 'assets/images/signin_bg.png';

const useStyles = makeStyles((theme) => ({
  leftContent: {
    backgroundColor: "#FFF",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4)
    }
  },
  formArea: {
    maxWidth: "70%",
    minHeight: "100vh",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      maxWidth: "80%",
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      paddingTop: theme.spacing(1),
    },
  },
  copySignUp: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400
  },
  ctaSignUp: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  rightContent: {
    background: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.primary['main'],
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      marginBottom: theme.spacing(2)
    },
  },
  logo: {
    width: 130,
    maxWidth: 130,
    [theme.breakpoints.only('xs')]: {
        width: "100%",
        maxWidth: 154
    },
  },
  welcomeText: {
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(1),
  },
  policy: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600
  }
}))

export { useStyles }

// Use Styled Components
const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div `
  animation: ${apearFromLeft} 1s
`;
