import React from 'react';
import { useHistory } from 'react-router-dom'

import {
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Typography,
    Box,
} from '@material-ui/core';

import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import { useStyles } from './styles'

export default (props) => {

    const classes = useStyles();
    const history = useHistory();

    const goToEbook = (url) => {
        if(!url) {
            return false;
        }
        const goTo = props.isEbookPlus ? `/dashboard/aprovare/ebook/${url}` : `/dashboard/ebook/${url}`
        history.push(goTo)
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={()=>goToEbook(props.slug)}>
                <CardMedia
                    className={classes.cover}
                    image={props.thumbnail}
                    title={props.title}
                />
                <CardContent className={classes.content}>

                    <Box component="div" mb={2}>
                        <Typography gutterBottom component="span" className={classes.category}>{!props.category ? "Outros" : props.category.title}</Typography>
                        <Typography gutterBottom component="h2" className={classes.title}>{props.title}</Typography>
                        {props.showSummary && <Typography component="p" className={classes.summary}>{props.summary}</Typography>}
                    </Box>

                    <Box component="div" display="flex" justifyContent="flex-end" flexDirection="row" className={classes.info} mt="auto">
                        <TrendingFlatIcon />
                    </Box>

                </CardContent>
            </CardActionArea>
        </Card>
    );
}
