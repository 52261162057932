import React, { useEffect, useState } from "react";
import { Box, Button, Link, Typography } from "@material-ui/core";

import close from "assets/images/close.svg";
import question from "assets/images/questionPlan.svg";

import { useStyles } from "./styles";

function MobileFirstPlansSection({ plan }) {
  const [content, setContent] = useState({
    title: "",
    description: "",
  });

  const handleOpenQuestion = (option) => {
    setContent({
      title: option.title,
      description: option.description,
      linkPos: option.link,
    });
  };

  const classes = useStyles();

  const plus = plan.title.includes("+") && classes.green;
  const buttonGreen = plan.title.includes("+") && classes.buttonGreen;
  const pos = plan.title.includes("Pós") && classes.yellow;
  const buttonYellow = plan.title.includes("Pós") && classes.buttonYellow;
  const free = plan.title === "Kultivi" && classes.purple;
  const academic = plan.title.includes("Academia") && classes.orange;
  const buttonOrange = plan.title.includes("Academia") && classes.buttonOrange;
  const [token, setToken] = useState(null);

  const handleRedirectToCheckout = (path) => {
    window.open(`${path}?token=${token}`, `_blank`);
  };

  useEffect(() => {
    const responseStorage = localStorage.getItem("@Kultivi:token");

    if (responseStorage) {
      setToken(responseStorage);
    }
  }, [token]);

  return (
    <Box component="div" className={classes.content}>
      {plan.title.includes("Pós") && (
        <Box component="div" className={classes.headerRecommended}>
          <Typography className={classes.titleHeading}>
            Mais recomendado
          </Typography>
        </Box>
      )}
      <Typography
        variant="h4"
        className={[classes.title, plus, pos, free, academic]}
      >
        Plano {plan.title}
      </Typography>

      <Box component="div">
        {plan.content.map((option, index) => {
          return (
            <Box className={classes.option} key={index}>
              <Typography variant="body2" className={classes.listItem}>
                {option.title}
              </Typography>
              <Box className={classes.plus}>
                <Typography className={classes.titleKultivi}>
                  {option.titleKultivi}{" "}
                </Typography>
                {option.icon && (
                  <img
                    src={option.icon}
                    alt="sinal de mais"
                    className={classes.icon}
                  />
                )}
              </Box>

              {option.title && (
                <img
                  src={question}
                  alt="Circulo com ponto de interrogação"
                  className={classes.question}
                  onClick={() => handleOpenQuestion(option)}
                />
              )}
              {content.title === option.title && (
                <Box className={classes.contentOption}>
                  <img
                    src={close}
                    className={classes.close}
                    alt="Icone de um X"
                    onClick={() =>
                      setContent({
                        title: "",
                        option: "",
                      })
                    }
                  />
                  <Typography className={classes.titleOption}>
                    {content.title}
                  </Typography>
                  <Typography className={classes.descriptionOption}>
                    {content.description}
                    <Link
                      className={classes.descriptionOption}
                      href={content.linkPos}
                      target="_blank"
                    >
                      {" "}
                      {content.linkPos}
                    </Link>
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>

      <Box component="div" className={classes.footerPlan}>
        {plan.priceOne && plan.priceTwo && plan.cta && (
          <>
            {!plan.path.yearly && (
              <>
                <Typography variant="body1" className={classes.prices}>
                  {plan.priceOne} <Typography variant="body2">ou</Typography>{" "}
                  {plan.priceTwo}
                </Typography>

                <Button
                  id={plan.id}
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.month)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  {plan.cta}
                </Button>
              </>
            )}

            {plan.path.month && plan.path.yearly && (
              <>
                <Typography variant="body1" className={classes.prices}>
                  {plan.priceOne}
                </Typography>

                <Button
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.month)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  Assinar Kultivi+ mensal
                </Button>

                <Typography variant="body1" className={classes.prices}>
                  {plan.priceTwo}
                </Typography>
                <Typography variant="body1" className={classes.previousPrice}>
                  {plan.priceThree}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.yearly)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  Assinar Kultivi+ anual
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default MobileFirstPlansSection;
