import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import {
  List,
  AppBar,
  ListItem,
  Container,
  Typography,
} from "@material-ui/core";

import NavigationMobile from "./NavigationMobile";

import { useStyles } from "./styles";

function NavigationKlubi({ faqRef, bestRef, partnersRef, allPlansRef }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleChangeDrawer = () => setOpen(!open);

  function handleBackClick(elementRef) {
    elementRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <AppBar position="sticky" component="header" className={classes.content}>
      <Container maxWidth="xl" component="nav" className={classes.navigation}>
        <IconButton
          edge="start"
          className={classes.iconMobile}
          color="inherit"
          aria-label="menu"
          onClick={handleChangeDrawer}
        >
          <MenuIcon />{" "}
          <Typography className={classes.titleIcon}>Menu</Typography>
        </IconButton>

        <List className={classes.listLinks}>
          {/* <ListItem
            className={classes.links}
            onClick={() => handleBackClick(bestRef)}
          >
            Melhor plano
          </ListItem> */}

          <ListItem
            className={classes.links}
            onClick={() => handleBackClick(allPlansRef)}
          >
            Todos os planos
          </ListItem>

          <ListItem
            className={classes.links}
            onClick={() => handleBackClick(faqRef)}
          >
            Perguntas frequentes
          </ListItem>

          {/* <ListItem
            className={classes.links}
            onClick={() => handleBackClick(partnersRef)}
          >
            Parceria
          </ListItem> */}
        </List>
      </Container>

      <NavigationMobile
        open={open}
        faqRef={faqRef}
        bestRef={bestRef}
        partnersRef={partnersRef}
        allPlansRef={allPlansRef}
        handleBackClick={handleBackClick}
        handleChangeDrawer={handleChangeDrawer}
      />
    </AppBar>
  );
}

export default NavigationKlubi;
