import React, { useState } from "react";

import {
  Box,
  Typography,
  Button,
  Container,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from "@material-ui/core";

// Styles files
import { useStyles } from "./styles";

import SmileEmoji from "assets/images/smiling_face_with_sunglasses.png";
import ShockedEmoji from "assets/images/shocked_face.png";
import { AdsTop, AdsIncontent } from "app/components/ads/freestar";
import Campaigns from "app/components/carousels/Campaigns/ads";
import { useScopes } from "app/contexts/ScopesContext";

export default (props) => {
  const classes = useStyles();
  const {scopes} = useScopes();

  const {lesson, ads, isLoadingAds} = props

  const [answers, setAnswers] = useState([]);

  /**
   * Método marca resposta do usuário
   * @param {*} q
   * @param {*} a
   */
  const handleAnswers = (q, a) => {
    setAnswers([
      ...answers.filter((item) => item.question_id !== q),
      {
        question_id: q,
        answer_id: a,
      },
    ]);
  };

  return (
    <React.Fragment>
      <Box component="div" className={classes.root}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          className={classes.header}
          pt={4}
          pb={2}
          mb={5}
        >
          <Container>
            <Typography component="h2" className={classes.subtitle}>
              {lesson.module?.title}
            </Typography>
            <Typography component="h1" className={classes.title}>
              {lesson.title}
            </Typography>
          </Container>
        </Box>

        <Box component="div" mb={10}>
          <Container>
            <Box
              component="div"
              mb={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb={4}>
                {
                  !isLoadingAds && !scopes.includes('student_no_ads_display') ?
                    ads.length > 0 ?
                      <Campaigns campaigns={ads} /> :
                      <AdsTop />
                  : ''
                }
              </Box>
              {lesson.finished && (
                <React.Fragment>
                  <Box
                    component="div"
                    width="100%"
                    className={classes.resultsTitle}
                  >
                    Resultado
                  </Box>
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    justifyContent="center"
                  >
                    <Card
                      className={[
                        classes.cardEvaluation,
                        classes.evaluationCorrect,
                      ].join(" ")}
                    >
                      <CardContent>
                        <Box component="div" mb={2} width="100%">
                          <img
                            src={SmileEmoji}
                            className={classes.evaluationEmoji}
                            alt=""
                          />
                        </Box>
                        <Box
                          component="div"
                          mb={2}
                          className={classes.evaluationTitle}
                        >
                          Acertos
                        </Box>
                        <Box
                          component="div"
                          className={classes.evaluationResult}
                        >
                          {lesson.evaluation?.corrects}
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      className={[
                        classes.cardEvaluation,
                        classes.evaluationWrong,
                      ].join(" ")}
                    >
                      <CardContent>
                        <Box component="div" mb={2} width="100%">
                          <img
                            src={ShockedEmoji}
                            className={classes.evaluationEmoji}
                            alt=""
                          />
                        </Box>
                        <Box
                          component="div"
                          mb={2}
                          className={classes.evaluationTitle}
                        >
                          Erros
                        </Box>
                        <Box
                          component="div"
                          className={classes.evaluationResult}
                        >
                          {lesson.evaluation?.wrongs}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </React.Fragment>
              )}
            </Box>
            {lesson.quiz.map((q, index) => {
              return (
                <React.Fragment>
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    mb={4}
                    className={classes.content}
                  >
                    <Box display="flex" flexDirection="column" mb={1}>
                      <Box component="div" mb={1}>
                        <Chip
                          color="primary"
                          label={`${++index}/${lesson.quiz.length}`}
                        />
                      </Box>
                      <Box
                        component="div"
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: q.question }}
                      ></Box>
                    </Box>

                    {q.answers.map((item, index2) => {
                      return (
                        <Card key={index2} className={classes.cardAnswer}>
                          <CardActionArea
                            className={
                              lesson.finished
                                ? item.is_correct
                                  ? classes.correctAnswer
                                  : /** Se carrega questões já respondidas */
                                  lesson.evaluation?.user_responses
                                  ? /** Se cair aqui, resposta do usuario sempre estará incorreta */
                                    lesson.evaluation?.user_responses?.find(
                                      (a) => a.answer_id === item.id
                                    ) && classes.wrongAnswer
                                  : /** Se usuário acabou de responder */
                                  lesson.evaluation?.feedbacks.find(
                                      (a) => a.correct_answer === item.id
                                    )
                                  ? classes.correctAnswer
                                  : lesson.evaluation?.feedbacks.find(
                                      (a) => a.user_response === item.id
                                    ) && classes.wrongAnswer
                                : answers.find((a) => a.answer_id === item.id)
                                ? classes.checked
                                : classes.unChecked
                            }
                            onClick={() => handleAnswers(q.id, item.id)}
                            disabled={
                              lesson.evaluation || lesson.finished
                            }
                          >
                            <CardContent
                              className={classes.content}
                              dangerouslySetInnerHTML={{
                                __html: lesson.finished
                                  ? /** Se carrega questões já respondidas */
                                    lesson.evaluation?.user_responses
                                    ? lesson.evaluation?.user_responses.find(
                                        (a) => a.answer_id === item.id
                                      )
                                      ? '<div align="right"><i>Você respondeu</i></div>' +
                                        item.answer
                                      : item.answer
                                    : /** Se usuário acabou de responder */
                                    lesson.evaluation?.feedbacks?.find(
                                        (a) => a.user_response === item.id
                                      )
                                    ? '<div align="right"><i>Você respondeu</i></div>' +
                                      item.answer
                                    : item.answer
                                  : item.answer,
                              }}
                            ></CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                  </Box>

                  {index % 2 === 0 && index > 0 && (
                    <Box component="div" mb={4}>
                      <AdsIncontent />
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
            <Box component="div" display="flex" justifyContent="center">
              {lesson.finished ? (
                lesson.next ? (
                  <Button
                    variant="contained"
                    href={`./${lesson.next.slug}`}
                    color="secondary"
                  >
                    Próxima aula
                  </Button>
                ) : (
                  <Button variant="contained" href={`./../`} color="secondary">
                    Retornar ao curso
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  disabled={
                    props.isSubmitting ||
                    lesson.quiz.length !== answers.length
                  }
                  color="primary"
                  onClick={() => props._finishQuizz(answers)}
                >
                  {!props.isSubmitting ? (
                    "Enviar respostas"
                  ) : (
                    <CircularProgress size={30} color="inherit" />
                  )}
                </Button>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};
