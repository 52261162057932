Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var grey = {
	100: "#f0f5f7",
	200: "#d6dde0",
	300: "#b4bbbf",
	400: "#8a9094",
	500: "#71777a",
	600: "#4f5b61",
	700: "#343c40",
	800: "#14181a",
	900: "#0d0f10",
};
var _default = grey;
exports.default = _default;