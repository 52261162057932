import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    userAvatar: {
        fontSize: theme.typography.pxToRem(60),
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.primary['main']
    },
    userName: {
        color: theme.palette.grey[600],
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 700,
        marginRight: theme.spacing(1),
    },
    userSince: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
    },
    title: {
        fontWeight: 700,
        color: theme.palette.grey[600],
        fontSize: theme.typography.pxToRem(18),
    },
    noCoursesTitle: {
        fontWeight: 700,
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(20),
        marginBottom: theme.spacing(1),
    },
    noCoursesSubtitle: {
        fontSize: theme.typography.pxToRem(16),
        marginBottom: theme.spacing(2),
    },
    noInvitesTitle: {
      fontWeight: 700,
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(18),
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    noInvitesText: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(1),
      maxWidth: 700,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(16),
        maxWidth: '100%',
      }
    },
}))

export { useStyles }
