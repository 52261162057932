import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    justifyContent: 'center',
  },
  videoDone: {
    minHeight: "50%",
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
  videoWrapperContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
  },
  lessonHeader: {
    position: "fixed",
    top: 0,
    width: `calc(100% - ${theme.overrides.MuiDrawer.paper.width + 50}px)`,
    color: "#FFF",
    zIndex: 2,
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1))'
  },
  lessonModule: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 800,
    marginBottom: theme.spacing(1),
  },
  lessonTitle: {
    fontSize: theme.typography.pxToRem(23),
    fontWeight: 800
  },
  nextClassCopy: {
    color: "#FFF",
    fontSize: theme.typography.pxToRem(21),
  },
  videoAreaSize: {
    width: "100%",
    [theme.breakpoints.up('lg')]: {
      width: "85%"
    }
  }
}))

export { useStyles }
