import React from 'react';
import {
    Card,
    CardContent,
    Box
 } from '@material-ui/core';

import SmileEmoji from 'assets/images/smiling_face_with_sunglasses.png'
import ShockedEmoji from 'assets/images/shocked_face.png'

import { useStyles } from './styles'

export default (props) => {

    const classes = useStyles();

    const {corrects,wrongs} = props

    return (
        <Box component="div" display="flex" flexDirection="row" width="100%" justifyContent="center">
            <Card className={[classes.cardEvaluation,classes.evaluationCorrect].join(" ")}>
                <CardContent >
                    <Box component="div" mb={2} width="100%"><img src={SmileEmoji} className={classes.evaluationEmoji} alt="" /></Box>
                    <Box component="div" mb={2} className={classes.evaluationTitle}>Acertos</Box>
                    <Box component="div" className={classes.evaluationResult}>{corrects}</Box>
                </CardContent>
            </Card>
            <Card className={[classes.cardEvaluation,classes.evaluationWrong].join(" ")}>
                <CardContent>
                    <Box component="div" mb={2} width="100%"><img src={ShockedEmoji} className={classes.evaluationEmoji} alt="" /></Box>
                    <Box component="div" mb={2} className={classes.evaluationTitle}>Erros</Box>
                    <Box component="div" className={classes.evaluationResult}>{wrongs}</Box>
                </CardContent>
            </Card>
        </Box>
    );
}