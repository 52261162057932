import { makeStyles } from '@material-ui/core/styles'
import styled, { keyframes } from 'styled-components'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(3),
        fontSize: theme.typography.pxToRem(14)
    },
    warning: {
        backgroundColor: theme.palette.warning.main
    },
    error: {
        backgroundColor: theme.palette.error.main
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        fontWeight: 700,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: "#FFF",
        fontWeight: 700
    },
}))

// Use Styled Components
const apearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimationContainer = styled.div `
  animation: ${apearFromTop} 1s
`;


export { useStyles, AnimationContainer }