import React, {memo} from 'react';
import { useHistory } from 'react-router-dom'

import {
    Card,
    CardContent,
    CardActionArea,
    Typography,
    Box,
    LinearProgress,
} from '@material-ui/core';

import VideocamIcon from '@material-ui/icons/Videocam';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import { useStyles } from './styles'

import Thumbnail from 'app/components/cards/Course/thumbnail/Image';
import MediaColor from 'app/components/cards/Course/thumbnail/MediaColor';

export default memo((props) => {
    const classes = useStyles();
    const history = useHistory();

    const goToCourse = (slug) => {
        history.push(`/dashboard/course/${slug}`)
    }

    function LinearProgressWithLabel(props) {
        return (
          <Box display="flex" alignItems="center" mr={1}>
            <Box width="100%" mr={1}>
              <LinearProgress color="primary" variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={()=>goToCourse(props.slug)} className={classes.actionArea}>
                {
                  props.imageUploadedAt ?
                  <Thumbnail id={props.uuid} title={props.title} /> :
                  <MediaColor color={props.category.color} />
                }
                <CardContent className={classes.content}>
                    <Box component="div" mb={2}>
                        <Typography gutterBottom component="span" className={classes.category}>{props.category.title}</Typography>
                        <Typography gutterBottom component="h2" className={classes.title}>{props.title}</Typography>
                        {props.showSummary ? <Typography component="p" className={classes.summary}>{props.summary && props.summary.length > 150 ? props.summary.substring(0, 150) + "..." : props.summary}</Typography> : ''}
                    </Box>
                    <Box component="div" display="flex" alignItems="center" flexDirection="row" className={classes.info} mt="auto">
                        {props.showProgress ?
                            <Box component="div" display="inline" width="100%" alignItems="center">
                                <LinearProgressWithLabel value={props.progress || props.percentage} />
                            </Box> : (
                            <React.Fragment>
                                <Box component="div" display="flex" alignItems="center" mr={1}>
                                    <VideocamIcon className={classes.infoIcon} />
                                    <Typography component="span" className={classes.infoContent}>{props.total_classes || props.totalClasses} aulas</Typography>
                                </Box>
                                <Box component="div" display="flex" alignItems="center" mr="auto">
                                    <SupervisorAccountIcon className={classes.infoIcon} />
                                    <Typography component="span" className={classes.infoContent}>{props.total_enrollments || props.totalEnrollments} alunos</Typography>
                                </Box>
                            </React.Fragment>
                        )}
                        <TrendingFlatIcon fontSize="small" className={[classes.infoIcon,classes.infoIconLink].join(" ")} />
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
})
