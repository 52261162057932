import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Card,
 } from '@material-ui/core';

 import { useStyles } from './styles'

export default () => {

  const classes = useStyles();

  return (
    <>      
      <Card className={classes.root}>
        <Skeleton animation="wave" variant="rect" height={400} />
      </Card>
    </>
  )
}
