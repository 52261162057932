import React from 'react'
import {
  Box,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors';
import Rating from '@material-ui/lab/Rating';

import StarBorderIcon from '@material-ui/icons/StarBorder';

import studentApi from 'app/services/student-api'

export default (props) => {

  const [savingRating,setSavingRating] = React.useState(false);
  const {course_id, lesson_id, copy} = props

  const [rating,setRating] = React.useState(0);

  React.useEffect(()=>{
    const rating = JSON.parse(sessionStorage.getItem(`@Kultivi:/courses/${course_id}/reviews/${lesson_id}`))?.rating || 0
    setRating(rating)
  },[course_id, lesson_id])

  const RatingCourse = (e,value) => {

    if( (value < 1 && value > 5) || !value ) {
      return false;
    }
    setRating(value)
    setSavingRating(true);
    const payload = {rating:value};
    studentApi.put(`/subscribed-courses/${course_id}/lessons/${lesson_id}/rating`,payload)
    .then(response => {
      sessionStorage.setItem(`@Kultivi:/courses/${course_id}/reviews/${lesson_id}`,JSON.stringify(payload))
    })
    .catch((err) => {
      console.warn(err.response)
    })
    .finally(() => {
      setSavingRating(false);
    })

  }

  return (
    <Box component="div" display="flex" flexDirection="column" alignItems="center">
      <Box component="div" mb={2} fontWeight={700} color="white">{copy}</Box>
      <Rating
        name="rating"
        size="large"
        precision={0.5}
        max={5}
        value={rating}
        disabled={savingRating}
        emptyIcon={<StarBorderIcon style={{ color: grey[400] }} fontSize="inherit" />}
        onChange={RatingCourse} />
    </Box>
  )
}
