

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    color: "#FFF",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    }
  },
  ebookTitle: {
    fontSize: theme.typography.pxToRem(46),
    fontWeight: 900,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(38),
    }
  },
  cardEbook: {
    width: "100%",
    maxWidth: 320,
    marginLeft: "auto",
    [theme.breakpoints.down('md')]: {
      marginRight: "auto",
    }
  },
  ebookMedia: {
    height: 320
  },
  ebookContent: {
    height: "100%",
    borderRadius: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  ebookSectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 800,
    marginBottom: theme.spacing(2),
  },
  ebookSectionTitleRelatedCourses: {
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  relatedEbooksAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  adsenseHorizontal: {
    width: 728,
    height: 90,
  },
}))

export { useStyles }
