import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";


// Styles files
import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box px={5} pt={3}>
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              py={3}
            >
              <Box component="div" className={classes.cardLabel}>
                ALUNOS
              </Box>
              <Box component="div" className={classes.cardNumber}>
                +4M
              </Box>
            </Box>
            <Divider />
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              py={3}
            >
              <Box component="div" className={classes.cardLabel}>
                CURSOS
              </Box>
              <Box component="div" className={classes.cardNumber}>
                +100
              </Box>
            </Box>
            <Divider />
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              py={3}
            >
              <Box component="div" className={classes.cardLabel}>
                AULAS
              </Box>
              <Box component="div" className={classes.cardNumber}>
                +5mil
              </Box>
            </Box>
            <Divider />
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              alignItems="center"
              py={3}
            >
              <Box component="div" className={classes.cardLabel}>
                EXERCÍCIOS
              </Box>
              <Box component="div" className={classes.cardNumber}>
                +2mil
              </Box>
            </Box>
          </Box>
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              component="div"
              className={[classes.cardBorder, classes.borderLeft].join(" ")}
            ></Box>
            <Box
              component="div"
              className={[classes.cardBorder, classes.borderRight].join(" ")}
            ></Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
