/* eslint-disable no-throw-literal */
export async function validateCpf(number) {

    const cpf = (number).replace(/[^\d]+/g, '');

    const knowNumbers = [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999",
    ]

    if (cpf.length !== 11 || knowNumbers.includes(cpf)) {
        throw ("Insira um documento válido")
    }

    var soma = 0;
    var resto = 0;
    var i = 0;

    for (i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }

    if (resto !== parseInt(cpf.substring(9, 10))) {
        throw ("Documento inválido")
    }

    soma = 0;

    for (i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }

    if (resto !== parseInt(cpf.substring(10, 11))) {
        throw ("Documento inválido")
    }
}

export const formatDate = (_date) => {
  if(!_date) {
    return;
  }
  const date = new Date(_date);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
