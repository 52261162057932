import React from 'react';
import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Box,
    Chip,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { useStyles } from './styles'

export default (props) => {
    const classes = useStyles();
    const { handlePillDialog, pill } = props;

    return (
        <Card style={{background: `url(${pill.thumbnail}) no-repeat center center`,backgroundSize:'cover'}} className={classes.root}>
            <CardActionArea
              className={classes.actionArea}
              onClick={handlePillDialog(true, pill)}
            >
                <CardContent className={classes.content} >
                      <Box mr="auto">
                        <Chip
                          icon={!pill.isPublic ? <LockIcon /> : ''}
                          color={pill.isPublic ? 'default' : 'primary'}
                          size="small"
                          label={pill.isPublic ? "Público" : "Kultivi+"}
                        />
                      </Box>

                    <Box display="flex" flexDirection="column" mt="auto">
                      <Typography gutterBottom component="h2" className={classes.title}>
                        {pill.title}
                      </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
