import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    padding: "2rem 0",
    backgroundColor: "#292929",
  },

  title: {
    fontSize: theme.typography.pxToRem(28),
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
  },
  contentPlans: {
    paddingBottom: "3rem",
    margin: "2rem auto 0 auto",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "3rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

export { useStyles };
