/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { Box } from '@material-ui/core';
import { useStyles } from './styles'
import { useScopes } from "app/contexts/ScopesContext";

const Desktop = React.memo(() => {
  const slotId = `kultivicom_billboard_video_pages`

  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_billboard_video_pages'
      slotId={slotId}
    />
  )
})

const AdsBillboardLesson = (props) => {
  const classes = useStyles();
  const { scopes } = useScopes();

  return (
    !scopes?.includes("student_no_ads_display") &&
    <div align="center">
      <Box className={classes.ads}>Publicidade</Box>
      <Desktop />
    </div>
  );
}

export default React.memo(AdsBillboardLesson)
