import { createTheme } from "@material-ui/core/styles"
import { green, purple, grey } from 'core/themes/theme1/colors'

const defaultTheme = createTheme();

export default createTheme({
    palette: {
        type: 'light',
        background: {
            default: '#F8F9FA'
        },
        primary: {
            main: green['main'],
            light: green['light'],
            dark: green['dark'],
            contrastText: "#FFF"
        },
        secondary: {
            main: purple['main'],
            light: purple['light'],
            dark: purple['dark'],
            contrastText: "#FFF"
        },
        grey: grey,
    },
    typography: {
        fontFamily: 'Nunito Sans, sans-serif',
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                '&$focusVisible': {
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                textTransform: 'none',
            },
            containedSizeLarge: {
                padding: '14px 60px'
            },
            containedSizeSmall: {
                padding: '7px 32.5px'
            },
            root: {
                fontWeight: 700,
                padding: '12px 52px',
                boxShadow: 0,
                borderRadius: '10px',
            },
            disableElevation: true,
        },
        MuiTab: {
            root: {
                fontWeight: 600
            }
        },
        MuiDrawer: {
            paper: {
                width: 240,
                background: '#FFF',
                color: grey[400],
                border: 0,
            },
            paperAnchorDockedLeft: {
                borderRight: 0,
                boxShadow: `6px 0 8px -5px ${grey[200]}`
            }
        },
        MuiCard: {
            root: {
                borderRadius: '10px',
                boxShadow: '0px 2px 6px rgba(92,84,122,0.2)',
                '&:hover': {
                    boxShadow: '0px 1px 4px rgba(92,84,122,0.2)',
                    transition: 'opacity 0.3s ease-in-out'
                },
            }
        },
        MuiAccordion: {
            root: {
                "&$expanded": {
                    margin:0
                }
            }
        },
        MuiSnackbar: {
          anchorOriginBottomCenter: {
            [defaultTheme.breakpoints.up('xs')]: {
              bottom: 100,
            },
          }
        }
    },
});
