import React from "react";

import { AuthProvider } from "app/contexts/AuthContext";
import { SubscriptionProvider } from "app/contexts/SubscriptionContext";
import { ScopesProvider } from "app/contexts/ScopesContext";
import { SnarckbarProvider } from "app/contexts/SnackbarContext";
import UseLocationChange from "hooks/useLocationChange";

const AppProvider = ({ children }) => {

  return (
    <>
      <UseLocationChange />
      <SnarckbarProvider>
        <AuthProvider>
          <SubscriptionProvider>
            <ScopesProvider>{children}</ScopesProvider>
          </SubscriptionProvider>
        </AuthProvider>
      </SnarckbarProvider>
    </>
  );
};
export default AppProvider;
