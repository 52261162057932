import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  notesMainTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(21),
  },
  noteCreatedAt: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.grey[400],
  },
  noteTitle: {
    fontWeight: 600,
    color: theme.palette.primary['main']
  },
  noteBody: {
    backgroundColor: theme.palette.grey[100],
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
  },
  time: {
    fontSize: theme.typography.pxToRem(13),
  },
  noteKlubi: {
    backgroundColor: theme.palette.grey[100],
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
  },
  noteKlubiTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
  },
  noteKlubiCopy: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  extraContent: {
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}))

export { useStyles }
