import React, { useState } from "react";
import { Box, Container, Link, Typography } from "@material-ui/core";

import Plan from "./Plan";
import MobileFirstPlansSection from "./Mobile";

import close from "assets/images/close.svg";
import question from "assets/images/questionPlan.svg";

import { useStyles } from "./styles";
import { dataPlans, paymentsImages, listOptionsPlans } from "./data";
import { mobileDataPlans } from "./Mobile/data";

function FirstPlansSectionKlubi({ allPlansRef }) {
  const [content, setContent] = useState({
    title: "",
    description: "",
  });
  const classes = useStyles();

  const handleOpenQuestion = (option) => {
    setContent({
      title: option.title,
      description: option.description,
      linkPos: option.link,
    });
  };
  const handleRedirectToNext = () => {
    window.open(`${process.env.REACT_APP_PRODUCT_NEXT}`, `_blank`);
  };
  return (
    <Box component="section" className={classes.content} ref={allPlansRef}>
      <Typography variant="h4" className={classes.title}>
        Conheça nossos planos de assinatura!!
      </Typography>
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.listOption}>
          {listOptionsPlans.map((option, index) => {
            return (
              <Box className={classes.option} key={index}>
                <Typography>{option.title}</Typography>
                <img
                  src={question}
                  alt="Circulo com ponto de interrogação"
                  className={classes.question}
                  onClick={() => handleOpenQuestion(option)}
                />

                {content.title === option.title && (
                  <Box className={classes.contentOption}>
                    <img
                      src={close}
                      className={classes.close}
                      alt="Icone de um X"
                      onClick={() =>
                        setContent({
                          title: "",
                          description: "",
                        })
                      }
                    />
                    <Typography className={classes.titleOption}>
                      {content.title}
                    </Typography>
                    <Typography className={classes.descriptionOption}>
                      {content.description}{" "}
                      <Link
                        className={classes.descriptionOption}
                        href={content.linkPos}
                        target="_blank"
                      >
                        {" "}
                        {content.linkPos}
                      </Link>
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>

        <Box component="div" className={classes.list}>
          {dataPlans.map((plan, index) => {
            return <Plan key={`${plan.title}-${index}`} plan={plan} />;
          })}
        </Box>
      </Container>

      {mobileDataPlans.map((plan, index) => (
        <MobileFirstPlansSection key={`${plan.title}-${index}`} plan={plan} />
      ))}
      <Box component="div" className={classes.footerPlans}>
        <Typography variant="body1" className={classes.titleFooterPlans}>
          Aceitamos as seguintes formas de pagamento
        </Typography>

        <Box component="div" className={classes.listPayments}>
          {paymentsImages.map((payment, index) => (
            <img
              src={payment}
              key={index}
              alt={`Pagamento-${index}`}
              className={classes.icons}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default FirstPlansSectionKlubi;
