import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: " #292929",
  },
  text: {
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  img: {
    marginTop: "1rem",
    display: "flex",
  },
  image: {
    cursor: "pointer",
    width: "1.5rem",
    margin: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      margin: "2rem 0.5rem",
      width: "1.5rem",
    },
  },
}));

export { useStyles };
