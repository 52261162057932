import React from 'react'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'

import { useAuth } from 'app/contexts/AuthContext'
import { useSubscription } from 'app/contexts/SubscriptionContext'

const CheckoutRoute = ({
  component: Component,
  isAuthenticated = false,
  isConfirmed = false,
  hasSubscribed = false,
  suspendCheckoutGuardVerification = false,
  ...rest
}) => {

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        let redirectTo;

        if( (isAuthenticated && !hasSubscribed) || suspendCheckoutGuardVerification )
          return <Component {...rest} />

        if(!isAuthenticated)
          redirectTo = '/'
        else if(!isConfirmed)
          redirectTo = '/confirm-account'
        else
          redirectTo = '/dashboard'

        return <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location }
            }}
          />
      }}
    />
  )
}

export default CheckoutRoute;
