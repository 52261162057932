import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(18)
  },
  buttonDelete: {
    color: theme.palette.error['main']
  },
  suspendingText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(21),
    textAlign: "center",
  },
  suspendedError: {
    backgroundColor: theme.palette.error['main'],
    color: "#FFF",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}))

export { useStyles }
