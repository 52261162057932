import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Hidden
} from "@material-ui/core";
import { useStyles } from './styles'
import { useScopes } from "app/contexts/ScopesContext";

const BillboardBannerApoie = ({fallbackBanner}) => {
  const classes = useStyles();
  const { scopes } = useScopes();

  function Hero() {
    return (
      <Box
      alignSelf='center'
      >
        <img
          className={classes.hero}
          alt="astronaut"
          src="https://cdn-kultivi.s3.amazonaws.com/static/platform/heroes/astronaut-1.png"
        />
      </Box>
    )
  }

  function HeroMobile() {
    return (
      <Hidden smUp>
        <Hero />
      </Hidden>
    )
  }

  function HeroDesktop() {
    return (
      <Hidden smDown>
        <Hero />
      </Hidden>
    )
  }

  function Cta() {
    return (
      <Box>
        <Button
          variant='contained'
          size='small'
          disableElevation={false}
          className={classes.button}
          href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=billboard"
          target="_blank"
        >APRENDER COM KULTIVI+</Button>
      </Box>
    )
  }

  return (
    !scopes.includes('student_no_ads_display') ?
      <Card className={classes.root} elevation={1}>
        <CardContent>
          <Box
            display='flex'
            flexDirection={{xs: 'column', md: 'row'}}
          >
            <Box
              display='flex'
              flexDirection='column'
              marginRight={{md:5}}
            >
              <Typography component="h2" className={classes.title}>Potencialize o seu aprendizado</Typography>
              <HeroMobile />
              <Typography component="p" className={classes.subtitle}>
                Desbloqueie seu máximo potencial, alcance novos patamares de conhecimento e apoie a plataforma #1 em educação
              </Typography>
              <Cta />
            </Box>
            <HeroDesktop />
          </Box>
        </CardContent>
      </Card>
    : fallbackBanner
  )
}

export default BillboardBannerApoie;
