import React from 'react'

// FRAMEWORK IMPORTS
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core'

export default (props) => {

  const { open, toggleDialog, answers, complete, total, confirmFinishQuiz } = props;

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
      onClose={toggleDialog(false)}
    >
      <DialogContent>
        
        <Box component="div" display="flex" flexDirection="column" alignItems="center" mb={2} py={3}>
          <Box fontWeight={700} fontSize={18} mb={4}>Tem certeza de que deseja finalizar seu simulado?</Box>
          {!complete && <DialogContentText>{`Você respondeu ${answers.length} de ${total} questões`}</DialogContentText>}
          <Box component="div" display="flex" flexDirection="row">
            <Box mr={2}><Button onClick={toggleDialog(false)}>Cancelar</Button></Box>
            <Box><Button onClick={confirmFinishQuiz} color="primary" size="small" variant="contained">FINALIZAR</Button></Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
