import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
  },
  videoWrapperContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '177.7%',
    overflow: 'hidden',
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}))

export { useStyles }
