

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    color: "#FFF",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    }
  },
  courseTitle: {
    fontSize: theme.typography.pxToRem(46),
    fontWeight: 900,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(38),
    }
  },
  courseMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  ctaCourse: {
    marginRight: theme.spacing(1)
  },
  courseContent: {
    height: "100%",
    borderRadius: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  courseSectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 800,
    marginBottom: theme.spacing(3),
  },
  courseSectionTitleRelatedCourses: {
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  relatedCoursesAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  alert: {
    background: theme.palette.warning['main'],
    color: "#FFF",
    padding: theme.spacing(2),
  },
  instructorCard: {
    backgroundColor: theme.palette.grey[800],
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px 0`,
    boxShadow: 'none',
  },
  instructorCardHeader: {
    paddingBottom: theme.spacing(1),
    alignItems: 'flex-start',
  },
  instructorCardContent: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: `calc(${theme.spacing(2)}px + 64px)`,
      paddingTop: theme.spacing(1),
    }
  },
  instructorAvatar: {
    width: '64px',
    height: '64px',
  },
  instructorName: {
    display: 'block',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  instructorJobTitle: {
    display: 'block',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
  },
  instructorDescription: {
    color: theme.palette.common.white
  },
  instructorSocialButton: {
    color: theme.palette.grey[300],
    marginRight: theme.spacing(1),
  }
}))

export { useStyles }
