import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./app/routes";

import AppProvider from "app/providers/AppProvider";
import ScrollToTop from "hooks/ScrollToTop";

function App() {
  return (
    <>
      <Router>
        <AppProvider>
          <ScrollToTop />
          <Routes />
        </AppProvider>
      </Router>
    </>
  );
}

export default App;
