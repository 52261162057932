import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    carouselItem: {
        paddingLeft: theme.spacing(.5),
        paddingRight: theme.spacing(.5),
        paddingBottom: 6
    },
    title: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    }
}))

export { useStyles }