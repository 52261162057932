import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.grey[700],
      width: "60%",
      height: "100%",
      borderRadius: "2rem",
      padding: "1rem 0",
      marginBottom: "2rem",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    margin: "2rem 0",
  },
  purple: {
    color: theme.palette.secondary.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  yellow: {
    color: "#FEF080",
    paddingTop: "1rem",
  },
  orange: {
    color: "#F68E60",
  },
  button: {
    fontFamily: "Nunito Sans, sans-serif",
    height: "4rem",
  },
  prices: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bolder",
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "1rem 0",
  },
  previousPrice: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "-0.5rem 0 1rem",
    textDecoration: "line-through",
  },
  buttonGreen: {
    marginBottom: "1.7rem",

    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  buttonYellow: {
    backgroundColor: "#FEF080",
    color: theme.palette.grey[700],
  },
  buttonOrange: {
    backgroundColor: "#F68E60",
    color: "#FFFFFF",
  },
  footerPlan: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
    position: "relative",
  },
  listItem: {
    fontFamily: "Raleway, sans-serif",
    margin: "1rem 0",
    color: "#FFFFFF",
    fontSize: theme.typography.pxToRem(14),
  },
  titleKultivi: {
    fontFamily: "Raleway, sans-serif",
    listStyleType: "none",
    margin: "1rem 0",
    color: "#FFFFFF",
    fontSize: theme.typography.pxToRem(17),
  },
  plus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    width: "3rem",
  },
  headerRecommended: {
    backgroundColor: "#26A678",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    borderTopLeftRadius: "2rem",
    borderTopRightRadius: "2rem",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleHeading: {
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  option: {
    marginBottom: "0.5rem",
    color: "#FFFFFF",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  question: {
    cursor: "pointer",
    marginLeft: "0.5rem",
    width: "0.8rem",
  },
  contentOption: {
    position: "absolute",
    top: "-60px",
    left: "center",
    right: "center",
    padding: "1rem",
    color: theme.palette.grey[800],
    backgroundColor: "#ffffFF",
    width: "18rem",
    flexWrap: "wrap",
    borderRadius: "1rem",
    zIndex: "9999",
  },
  close: {
    position: "absolute",
    right: "10px",
    width: "12px",
    cursor: "pointer",
  },
  titleOption: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: "Nunito Sans, sans-serif",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  descriptionOption: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Nunito Sans, sans-serif",
  },
}));

export { useStyles };
