import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Intercepta response para tratar retorno do erro, caso ocorra.
api.interceptors.response.use( (response) => {
  return response
}, (error) => {

  const status = error?.response?.status

  // Página não encontrada
  if(status === 404) {
    window.location.href = "/dashboard/404"
  }

  // Erro de execução
  else if(status === 500) {
    window.location.href = "/dashboard/error"
  }

  // JWT expires => force logout
  else if(status === 401 &&
    (error.response?.data?.code === "E_JWT_TOKEN_EXPIRED" ||
    ["ExpiredJwtToken","InvalidJwtToken"].includes(error.response?.data?.error?.name) ) ) {
    window.postMessage({data:true, type:'KICK_OUT'});
    localStorage.removeItem('@Kultivi:token')
    localStorage.removeItem('@Kultivi:user')
    window.location.href = "/"

  } else {
    return Promise.reject(error);
  }

});

export default api;
