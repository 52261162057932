import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(36),
        marginBottom: theme.spacing(1),
        fontWeight: 800
    },
    subtitle: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey[600]
    },
    boxKlubi: {
        backgroundColor: theme.palette.grey[100],
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    boxKlubiTitle: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18),
    },
    boxKlubiCopy: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(15)
    },
}))

export { useStyles }