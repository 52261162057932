/**
 * Form responsável por realizar a troca de e-mail (dado de acesso) do usuário */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";

import { useStyles } from "./styles";

// API file
import api from "app/services/api";

import { useAuth } from "app/contexts/AuthContext";

const validationSchema = yup.object({
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  email_confirmation: yup
    .string()
    .email("E-mail inválido")
    .required("Campo obrigatório")
    .oneOf(
      [yup.ref("email"), null],
      "E-mail e confirmação de e-mail não coincidem"
    ),
});

export default (props) => {
  const classes = useStyles();

  const { user, refreshUserInfo } = useAuth();
  const [canChangeEmail, setCanChangeEmail] = React.useState(false);

  useEffect(() => {
    setCanChangeEmail(user.provider !== 3);
  }, [user]);

  const [snackValues, setSnackValues] = React.useState({
    message: "",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackValues({ open: false });
  };

  const formik = useFormik({
    initialValues: {
      email: user.email ?? "",
      email_confirmation: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);

      api
        .put(`/users/me/account`, values)
        .then((response) => {
          setSnackValues({
            message: "Seus dados foram atualizados com sucesso.",
            open: true,
          });
          refreshUserInfo({ email: values.email });
        })
        .catch((err) => {
          setSnackValues({
            message: "Ops! Falha ao atualizar seus dados. Tente novamente.",
            open: true,
          });
          const errors = err.response.data;
          for (let index = 0; index < errors.length; index++) {
            const error = errors[index];
            var setErr = {};
            setErr[`${error.field}`] = error.message;
            actions.setErrors(setErr);
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <React.Fragment>
      {!canChangeEmail && (
        <Typography
          component="p"
          variant="body2"
          className={classes.googleAlert}
        >
          Você se inscreveu na Kultivi usando uma conta do Google. Quando você
          faz o login usando essa opção, seu e-mail é fixo e não pode ser
          alterado.
        </Typography>
      )}
      <form name="form-account" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={!canChangeEmail ? 12 : 6}>
            <TextField
              label="Seu melhor e-mail"
              id="email"
              name="email"
              variant="outlined"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth={true}
              disabled={!canChangeEmail}
              required={true}
              autoComplete="off"
            />
          </Grid>
          {canChangeEmail ? (
            <React.Fragment>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Confirmar e-mail"
                  id="email_confirmation"
                  name="email_confirmation"
                  variant="outlined"
                  type="email"
                  fullWidth={true}
                  required={true}
                  autoComplete="off"
                  value={formik.values.email_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.email_confirmation &&
                    Boolean(formik.errors.email_confirmation)
                  }
                  helperText={
                    formik.touched.email_confirmation &&
                    formik.errors.email_confirmation
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box component="div" textAlign="right">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={30} color="inherit" />
                    ) : (
                      "Alterar e-mail"
                    )}
                  </Button>
                </Box>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
