/**
 * Form responsável por realizar o Login do usuário*/
import React from 'react'
import {
    Box,
    Grid,
    Button,
    TextField,
    CircularProgress,
    Snackbar,
    IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import accountApi from 'app/services/account-api';

//Contexts imports
import { useFormik } from 'formik'

import { recoveryPasswordValidator } from 'app/validators/auth'

export default (props) => {

  const {handleSuccess} = props;

  const [snackValues, setSnackValues] = React.useState({
      message: '',
      open: false,
  })
  const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSnackValues({open:false})
  }

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: recoveryPasswordValidator,
    enableReinitialize: true,
    validateOnMount:true,
    onSubmit: (values,actions) => {
      actions.setSubmitting(true);
      accountApi.post(`/forgot-password`, values)
      .then((response) => {
        handleSuccess(response.data.email)
      })
      .catch((err) => {
          setSnackValues({message:"Ops! Falha ao recuperar sua senha. Tente novamente.",open:true});
          const { response } = err;
          if (response && response.data) {
            const {
              data: { errors },
            } = response;
            const setErrors = {};
            setErrors[`username`] = errors.join(', ');
            actions.setErrors(setErrors);
          }
      })
      .finally( () => {
          actions.setSubmitting(false);
      })

    }
  })


  return (
    <React.Fragment>
      <form name="form-forgot-password" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email de cadastro ou CPF"
              id="username"
              name="username"
              variant="outlined"
              type="text"
              fullWidth={true}
              required={true}
              disabled={formik.isSubmitting}
              autoComplete="off"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>
          <Grid item xs={12}>
            <Box component="div" textAlign="right">
              <Button
                color="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}>
                { formik.isSubmitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : 'Recuperar senha' }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
