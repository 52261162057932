import React from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import YouTube from 'react-youtube'


import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const { open, toggleDialog, presentationVideo } = props

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 2,
      color: 'white',
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Box component="div" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={toggleDialog(false)} size="small" >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <YouTube
          videoId={presentationVideo}
          containerClassName={`${classes.videoWrapperContainer}`}
          className={`${classes.videoWrapper}`}
          opts={opts}
        />
      </DialogContent>
    </Dialog>
  )
}
