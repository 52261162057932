import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Link,
  Typography,
  FormControlLabel,
  Switch,
  FormHelperText
} from '@material-ui/core'

import InputMask from "react-input-mask"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useAuth } from 'app/contexts/AuthContext'
import { useSnackbar } from 'app/contexts/SnackbarContext'

import { signUpValidator } from 'app/validators/auth'
import { CPF } from 'app/validators/cpf'

import { useFormik } from 'formik'
import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const { signUp, signIn } = useAuth()
  const { setSnackValues } = useSnackbar()

  function onReCaptchaChange(value) {
    setRecaptchaToken(value)
  }

  const [socialLoading,setSocialLoading] = useState(false);
  const [documentError,setDocumentError] = useState(false);
  const [recaptchaToken,setRecaptchaToken] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      document: '',
      isForeign: false,
    },
    validationSchema: signUpValidator,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, actions) => {

      try {

        if(!recaptchaToken) {
          setSnackValues({
            message: 'Confirme que você não é um robô 🧐',
            open: true,
          });
          return;
        }

        actions.setSubmitting(true)
        await signUp({...values, indicatedBy: localStorage.getItem('@Kultivi:ref'), captcha: recaptchaToken})
        setSnackValues({ message: "Cadastro realizado com sucesso! Aguarde enquanto redirecionamos seu acesso.", open: true })
        const user = await signIn({
          username: values.email,
          password: values.password
        });
        if(user.is_confirmed)
          window.location.href = "/dashboard";
        else
          window.location.href = "/confirm-account";
      } catch (err) {
        const { response } = err;
        if (response && response.data) {
          const {
            data: { message, errors },
          } = response;
          // if (Array.isArray(errors)) {
          //   for (let index = 0; index < errors.length; index++) {
          //     const error = errors[index];
          //     setErrors[`${error.field}`] = error.message;
          //   }
          // } else
          //   setErrors[`username`] = errors;
          // actions.setErrors(setErrors);
          let feedback = '';
          if(message === 'EXCEPTION:ACCOUNT_ALREADY_EXISTS') {
            feedback = 'Email/CPF já cadastrado'
          }
          else if(message === 'EXCEPTION:AUTHENTICATION_CAPTCHA_INVALID') {
            feedback = 'Captcha não é válido'
          } else {
            feedback = errors.at(0)
          }

          setSnackValues({
            message: feedback || 'Falha ao criar nova conta!',
            open: true,
          });
        } else {
          setSnackValues({ message: "Ops! Falha ao criar seu cadastro. Tente novamente.", open: true });
        }
        actions.setSubmitting(false)

        // if (err.response?.data) {
        //   const errors = err.response.data
        //   for (let index = 0; index < errors.length; index++) {
        //     const error = errors[index];
        //     var setErr = {};
        //     setErr[`${error.field}`] = error.message;
        //     actions.setErrors(setErr)
        //   }
        // }
      }

    }
  })

  const handleBlurDocument = (e) => {
    const target = e.target
    formik.handleChange(e)
    if(!new CPF(target.value).isValid())
      setDocumentError(true)
    else
      setDocumentError(false)
  }

  return (
    <>
      <Box component="div" mb={5}>
        <form id="formSignUp" name="formSignUp" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome completo"
                id="fullname"
                name="fullname"
                variant="outlined"
                type="text"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.fullname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                helperText={formik.touched.fullname && formik.errors.fullname}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <TextField
                label="Seu melhor e-mail"
                id="email"
                name="email"
                variant="outlined"
                type="email"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText="Será o seu usuário (enviaremos um código de validação)"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <TextField
                label="Senha"
                id="password"
                name="password"
                variant="outlined"
                type="password"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  control={
                    <Switch
                      value={formik.values.isForeign}
                      checked={formik.values.isForeign}
                      onChange={formik.handleChange}
                      name="isForeign"
                    />
                  }
                  label="Sou Estrangeiro(a)"
                  labelPlacement="start"
                />
              </Box>
              <Box hidden={formik.values.isForeign}>
              <InputMask
                mask="999.999.999-99"
                value={formik.values.document}
                onChange={formik.handleChange}
                onBlur={handleBlurDocument}
                onFocus={handleBlurDocument}
                required={!formik.values.isForeign}
              >
                {() => <TextField
                  id="document"
                  name="document"
                  label="CPF"
                  variant="outlined"
                  error={formik.touched.document && Boolean(formik.errors.document)}
                  required={!formik.values.isForeign}
                  fullWidth={true}
                ></TextField>
                }
              </InputMask>
              {documentError && <FormHelperText error={documentError}>Documento inválido</FormHelperText>}
              <FormHelperText>O CPF é essencial para garantir a autenticidade dos usuários, validar certificados e evitar duplicidade de cadastro. Fique tranquilo(a), nossa missão é transformar a educação à distância em uma experiência segura e incrível para todos! 🎓😄</FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" display="flex" justifyContent="flex-start" mb={2}>
                <Typography className={classes.policy}>Ao me cadastrar, concordo com os <Link href="https://kultivi.com/politica-de-privacidade" target="_blank">Termos de uso e Política de privacidade</Link></Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' justifyContent="center" mb={3}>
                <ReCAPTCHA onChange={onReCaptchaChange} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" display="flex" alignItems="flex-start">
                <Button
                  id="signupButton"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    socialLoading ||
                    (documentError && !formik.values.isForeign) ||
                    !recaptchaToken
                  }>
                  {formik.isSubmitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <>
                      <Box mr={3}>CRIAR CONTA</Box>
                      <ArrowForwardIcon />
                    </>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}
