import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Card,
  CardContent,
} from '@material-ui/core';


const SkeletonCourse = () => {

  return (
    <>
      <Card>
        <Skeleton animation="wave" variant="rect" height={120} />
          <CardContent>
            <Skeleton animation="wave" height={50} width="100%" />
          </CardContent>
      </Card>
    </>
  )
}

export default SkeletonCourse
