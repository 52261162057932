import React from 'react'

import {
    Box,
    Link,
} from '@material-ui/core';

// Styles files
import { useStyles } from './styles'

const Copyright = () => {

    const classes = useStyles();

    return (
      <>
        <Box component="div" px={3} className={classes.root} display="flex" flexWrap="wrap" justifyContent="space-between" py={2}>
          <Box component="span" flexGrow={1}>
              Copyright © 2017 Kultivi Produção e Edição de Conteúdo Ltda
          </Box>
          <Box component="nav" mx="auto" className={classes.links}>
              <Link href="https://kultivi.com/privacidade" color="inherit">Termos de Uso e Política de Privacidade</Link>
          </Box>
        </Box>
      </>
    )
}

export default React.memo(Copyright);
