/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Paper,
} from "@material-ui/core";

import { Pagination, Skeleton } from "@material-ui/lab";

import FinishingUpConfirmation from "app/components/Modals/Quiz/FinishingUpConfirmation";
import FinishingUpDialog from "app/components/Modals/Quiz/FinishingUp";
import RetakingTestDialog from "app/components/Modals/Quiz/RetakingTest";
import QuizResults from "app/components/cards/QuizResults";

import SaveIcon from "@material-ui/icons/Save";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";

import { AdsTop, AdsIncontent } from "app/components/ads/freestar";

import { useSnackbar } from "app/contexts/SnackbarContext";

// Styles files
import { useStyles } from "./styles";

import studentApi from 'app/services/student-api'

export default (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { setSnackValues } = useSnackbar();

  const { id } = props.match.params;

  const [quiz, setQuiz] = useState();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [finishingUpQuiz, setFinishingUpQuiz] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [retakingTest, setRetaking] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);

  const toggleDialog = (open) => (event) => {
    setOpenDialog(open);
  };

  const fetchQuestions = useCallback((id, page = 1) => {
    setLoading(true);
    studentApi
      .get(`/practice-exams/${id}?page[number]=${page}`)
      .then((response) => {
        window.scrollTo(0, 0);
        setQuestions(response.data.questions);
        setPagination(response.data.pagination);
        setAnswers(response.data.user_responses);
      })
      .catch((error) => {
        setSnackValues({
          message:
            error.response?.data?.message ??
            "Ops! Falha ao listar questões. Tente novamente.",
          open: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  useEffect(() => {
    setLoadingQuiz(true);

    studentApi
      .get(`/practice-exams/${id}?page[number]=1`)
      .then((response) => {
        setQuiz(response.data.quiz);
      })
      .catch((error) => {
        setSnackValues({
          message:
            error.response?.data?.message ??
            "Ops! Falha ao listar infos do quiz. Tente novamente.",
          open: true,
        });
      })
      .finally(() => {
        setLoadingQuiz(false);
      });
  }, [id]);

  useEffect(() => {
    fetchQuestions(id);
  }, [id]);

  const finishLater = () => {
    history.push(`/dashboard/quizzes`);
  };

  const finishQuiz = () => {
    setOpenDialog(true);
  };

  const confirmFinishQuiz = () => {
    setOpenDialog(false);
    setFinishingUpQuiz(true);

    studentApi
      .post(`/practice-exams/${id}/finish`)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setSnackValues({
          message:
            error.response?.data?.message ??
            "Ops! Falha ao finalizar quiz. Tente novamente.",
          open: true,
        });
      })
      .finally(() => {
        setFinishingUpQuiz(false);
      });
  };

  const retakeQuiz = () => {
    setRetaking(true);
    studentApi
      .post(`/practice-exams/${id}/retake`)
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
        setSnackValues({
          message:
            error.response?.data?.message ??
            "Ops! Falha ao resetar quiz. Tente novamente.",
          open: true,
        });
      })
      .finally(() => {
        setRetaking(false);
      });
  };

  function HeaderQuiz(props) {
    return (
      <>
        <Box mb={2}>
          <Container maxWidth="lg">
            {props.loading ? (
              <>
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                <Typography component="h1" className={classes.title}>
                  {quiz?.title}
                </Typography>
                <Typography component="h2" className={classes.category}>
                  {quiz?.category?.title}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
                  mb={2}
                >
                  <Box component="span" mr={1} fontWeight={600}>
                    Disciplina(s):
                  </Box>
                  {quiz?.subjects?.map((item) => (
                    <Chip
                      key={item.id}
                      size="small"
                      style={{ marginRight: 4, marginBottom: 4 }}
                      label={item.title}
                    ></Chip>
                  ))}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Box component="span" mr={1} fontWeight={600}>
                    Assunto(s):
                  </Box>
                  {quiz?.sections?.map((item) => (
                    <Chip
                      key={item.id}
                      size="small"
                      style={{ marginRight: 4, marginBottom: 4 }}
                      label={item.title}
                    ></Chip>
                  ))}
                </Box>
              </>
            )}
          </Container>
        </Box>
      </>
    );
  }

  function ActionsBar({
    pagination,
    answers,
    finishLater,
    finishQuiz,
    retakeQuiz,
    is_finished,
  }) {
    return (
      <Paper elevation={0}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            py={3}
          >
            <Box fontWeight={600} mb={{ xs: 2, sm: 0 }}>
              {pagination &&
                `Você respondeu: ${answers.length} / ${pagination.total}`}
            </Box>
            {!loading && (
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={{ xs: "center", sm: "flex-end" }}
              >
                {!is_finished ? (
                  <>
                    <Box
                      width={{ xs: "100%", sm: "auto" }}
                      mb={{ xs: 1, sm: 0 }}
                      mr={{ sm: 1 }}
                    >
                      <Button
                        variant="outlined"
                        disabled={loading || answering}
                        startIcon={<SaveIcon />}
                        fullWidth
                        onClick={finishLater}
                      >
                        Continuar depois
                      </Button>
                    </Box>
                    <Box width={{ xs: "100%", sm: "auto" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={loading || answering}
                        color="primary"
                        size="small"
                        onClick={finishQuiz}
                      >
                        FINALIZAR
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      width={{ xs: "100%", sm: "auto" }}
                      mb={{ xs: 1, sm: 0 }}
                      mr={{ sm: 1 }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<SettingsBackupRestoreIcon />}
                        fullWidth
                        onClick={retakeQuiz}
                      >
                        Refazer simulado
                      </Button>
                    </Box>
                    <Box
                      width={{ xs: "100%", sm: "auto" }}
                      mb={{ xs: 1, sm: 0 }}
                      mr={{ sm: 1 }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        size="small"
                        onClick={finishLater}
                      >
                        SAIR
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Container>
      </Paper>
    );
  }

  /**
   * Método marca resposta do usuário
   * @param {*} q
   * @param {*} a
   */
  const handleAnswers = (q, a) => {
    if (quiz.finished) {
      return false;
    }

    setAnswers([
      ...answers.filter((item) => item.question_id !== q.id),
      {
        question_id: q.id,
        answer_id: a.id,
      },
    ]);

    setAnswering(true);

    studentApi
      .post(`/practice-exams/${id}/answer`, {
        questionId: q.uuid,
        answerId: a.uuid,
      })
      .then((response) => {})
      .catch((error) => {
        setSnackValues({
          message:
            error.response?.data?.message ??
            "Ops! Falha ao gravar sua resposta. Tente novamente.",
          open: true,
        });
      })
      .finally(() => {
        setAnswering(false);
      });
  };

  const handleChangePagination = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
    fetchQuestions(id, value);
  };

  return (
    <React.Fragment>
      <HeaderQuiz {...quiz} loading={loadingQuiz} />
      {quiz?.finished ? (
        <Box mb={4}>
          <QuizResults corrects={quiz.corrects} wrongs={quiz.wrongs} />
        </Box>
      ) : null}
      <Container>
        <Box component="div" mb={3}>
          <AdsTop />
        </Box>
      </Container>
      <Box mb={4}>
        <ActionsBar
          answers={answers}
          pagination={pagination}
          finishLater={finishLater}
          finishQuiz={finishQuiz}
          retakeQuiz={retakeQuiz}
          is_finished={quiz?.finished}
        />
        <Divider />
      </Box>
      <Box component="div" className={classes.root}>
        {loadingQuiz || loading ? (
          <Box component="div" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Container maxWidth="md">
            {questions.map((q, index) => {
              return (
                <React.Fragment key={index}>
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    mb={4}
                    className={classes.content}
                  >
                    <Box display="flex" flexDirection="column" mb={1}>
                      <Box component="div" mb={1}>
                        <Chip
                          color="primary"
                          label={`${
                            pagination.perPage * page -
                            pagination.perPage +
                            index +
                            1
                          }/${pagination.total}`}
                        />
                      </Box>
                      <Box
                        component="div"
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: q.question }}
                      ></Box>
                    </Box>
                    {quiz.finished ? (
                      <Box
                        my={2}
                        fontWeight={700}
                        fontStyle="italic"
                        color="error.main"
                      >
                        {!answers.find((a) => a.question_id === q.id) &&
                          "Você não respondeu essa questão"}
                      </Box>
                    ) : null}
                    {q.answers.map((item, index) => {
                      return (
                        <Card key={index} className={classes.cardAnswer}>
                          <CardActionArea
                            disabled={answering || quiz.finished}
                            className={
                              quiz.finished ? (
                                answers.find((a) => a.answer_id === item.id) ? (
                                  item.isCorrect ? classes.correctAnswer : classes.wrongAnswer
                                ) : (
                                  item.isCorrect ? classes.correctAnswer : <></>
                                )
                              ) : (
                                answers.find((a) => a.answer_id === item.id) ? classes.checked : classes.unChecked
                              )
                            }
                            onClick={() => handleAnswers(q, item)}
                          >
                            <CardContent
                              className={classes.content}
                              dangerouslySetInnerHTML={{
                                __html: answers.find(
                                  (a) => a.answer_id === item.id
                                )
                                  ? `<div align="right"><i>Sua resposta</i></div> ${item.answer}`
                                  : item.answer,
                              }}
                            ></CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                  </Box>
                  <Box component="div" mb={4}>
                    <AdsIncontent />
                  </Box>
                </React.Fragment>
              );
            })}
            {pagination && pagination.lastPage > 1 && (
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                mb={5}
              >
                <Pagination
                  count={pagination.lastPage}
                  page={page}
                  color="secondary"
                  disabled={loading || answering}
                  onChange={handleChangePagination}
                />
              </Box>
            )}
          </Container>
        )}
      </Box>
      <ActionsBar
        answers={answers}
        pagination={pagination}
        finishLater={finishLater}
        finishQuiz={finishQuiz}
        retakeQuiz={retakeQuiz}
        is_finished={quiz?.finished}
      />
      <FinishingUpConfirmation
        open={openDialog}
        toggleDialog={toggleDialog}
        answers={answers}
        quiz={quiz}
        total={pagination?.total}
        complete={answers.length === pagination?.total}
        confirmFinishQuiz={confirmFinishQuiz}
      />
      <FinishingUpDialog open={finishingUpQuiz} />
      <RetakingTestDialog open={retakingTest} />
    </React.Fragment>
  );
};
