import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(18)
  },
  copy: {
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[600]
  }
}))

export { useStyles }
