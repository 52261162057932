/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useHistory } from 'react-router-dom'

import studentApi from 'app/services/student-api'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Checkbox,
  Select,
  Chip,
  Switch,
  FormControlLabel,
  Grid,
  Snackbar,
  IconButton,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useStyles } from './styles'

const validationSchema = yup.object({
  title: yup.string().required("Título é obrigatório"),
  category_id: yup.string().required("Categoria é obrigatório"),
  is_public: yup.boolean(),
});

export default function QuizForm({setQuestions, setPagination}) {

  const classes = useStyles()
  const history = useHistory()

  const [categories, setCategories] = React.useState([])
  const [subjects, setSubjects] = React.useState([])
  const [sections, setSections] = React.useState([])
  const [limits, setLimits] = React.useState([20,30,40,50]);
  const [subjectsSelected, setSubjectsSelected] = React.useState([])
  const [sectionsSelected, setSectionsSelected] = React.useState([])
  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackValues({open:false});
  };


  const formik = useFormik({
    initialValues: {
      title: '',
      category_id: '',
      subject_id: [],
      section_id: [],
      limit: '',
      is_public: false,
      description: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, actions) => {

      const category = values.category_id;
      const subjects = subjectsSelected;
      const sections = sectionsSelected;

      actions.setSubmitting(true)

      studentApi.post('/practice-exams/create',{
        categoryId: category,
        subjectsIds: subjects.map((s)=>s.uuid),
        modulesIds: sections.map((s)=>s.uuid),
        limit: Number(values.limit || 20),
        title: values.title,
        isPublic: values.is_public,
        description: values.description,
      }).then( (response) => {
        history.push(`/dashboard/quizzes/${response.data.id}`);
      }).catch((error) => {
        setSnackValues({message: error.response?.data?.message ?? "Ops! Falha ao criar quiz. Tente novamente.",open:true});
      }).finally(() => [
        actions.setSubmitting(false)
      ])
    }
  })

  function handleCategoryChange(e) {

    const { value } = e.target
    setSubjectsSelected([])
    setSectionsSelected([])
    setSections([])
    setSubjects([])

    formik.handleChange(e)
    studentApi.get(`practice-exams/subjects`,{params:{categoryId: value}})
      .then((response) => {
        setSubjects(response.data)
      })
      .catch((error) => console.warn(error.data))
  }

  function handleSubjectChange(e,items) {
    setSubjectsSelected(items)
  }

  function handleSectionChange(e,items) {
    setSectionsSelected(items)
  }

  function handleSubjectClose(e) {
    const topics = subjectsSelected.map((s) => s.uuid);
    if(!topics.length) {
      return;
    }
    studentApi.get(`practice-exams/topics`,{params:{subjectsIds: topics}})
    .then((response) => {
      setSections(response.data)
    })
    .catch((error) => console.warn(error.data))
  }

  function handleDeleteSubject(item) {
    setSubjectsSelected( (subjectsSelected) => subjectsSelected.filter((subject) => subject.id !== item.id));
  }

  function handleDeleteSection(item) {
    setSectionsSelected( (sectionsSelected) => sectionsSelected.filter((section) => section.id !== item.id));
  }

  React.useEffect(() => {
    studentApi.get('practice-exams/categories')
      .then((response) => {
        setCategories(response.data)
      })
      .catch((error) => console.warn(error.data))
  }, [])

  React.useEffect(() => {
    setSections( s => sections.filter( (section) => subjectsSelected.find((subject) => section.subject_id === subject.id) ) )
    setSectionsSelected( s => sectionsSelected.filter( (section) => subjectsSelected.find((subject) => section.subject_id === subject.id) ) )
  }, [subjectsSelected])

  return (
    <React.Fragment>
      <form name="form-quiz" onSubmit={formik.handleSubmit}>
        <Grid container style={{marginBottom:21}} spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  label="Dê um nome para o seu simulado"
                  id="title"
                  name="title"
                  variant="outlined"
                  fullWidth={true}
                  required={true}
                  autoComplete="off"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs>
                <FormControl required variant="outlined" fullWidth error={formik.touched.category_id && Boolean(formik.errors.category_id)}>
                  <InputLabel id="category_id">Selecione uma categoria</InputLabel>
                  <Select
                    labelId="category_id"
                    native
                    required
                    name="category_id"
                    label="Selecione uma categoria"
                    value={formik.values.category_id}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                  >
                    <option key={0} value="" disabled></option>
                    {categories.map((category) => (
                      <option key={category.uuid} value={category.uuid}>{category.title} ({category.title})</option>
                    ))}
                  </Select>
                  <FormHelperText>{formik.touched.category_id && formik.errors.category_id}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <Autocomplete
                  limitTags={1}
                  multiple
                  id="subject_id"
                  name="subject_id"
                  options={subjects}
                  value={subjectsSelected}
                  disabled={!formik.values.category_id}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={handleSubjectChange}
                  onClose={handleSubjectClose}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Selecione uma ou mais disciplinas" placeholder="Disciplina(s)" />
                  )}
                />
              </Grid>
              <Grid item xs>
                <Autocomplete
                  limitTags={1}
                  multiple
                  id="sections"
                  options={sections}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={handleSectionChange}
                  value={sectionsSelected}
                  disabled={subjectsSelected.length < 1}
                  groupBy={(option) => option.subject_title}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Selecione um ou mais assuntos" placeholder="Assunto(s)" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  label="Descrição (opcional)"
                  id="description"
                  name="description"
                  variant="outlined"
                  type="text"
                  multiline
                  rows={5}
                  fullWidth={true}
                  required={false}
                  autoComplete="off"
                  value={formik.values.about}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.about && Boolean(formik.errors.about)}
                  helperText="Dica pro: uma boa descrição ajudará outros usuários a encontrar seu quiz."
                />
              </Grid>
              <Grid item xs>
                <FormControl required variant="outlined" fullWidth>
                  <InputLabel id="limit">Quantidade máxima de questões</InputLabel>
                  <Select
                    labelId="limit"
                    native
                    required
                    name="limit"
                    label="Quantidade máxima de questões"
                    value={formik.values.limit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {limits.map((limit) => (
                      <option key={limit} value={limit}>{limit}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mb={2} width>
          <FormControlLabel
            control={
                <Switch
                    value={formik.values.is_public}
                    checked={formik.values.is_public}
                    onChange={formik.handleChange}
                    name="is_public"
                />
            }
            label="Disponibilizar no catálogo de simulados públicos da Kultivi"
            labelPlacement="end"
          />
        </Box>
        <Box mb={2} width>
          {subjectsSelected.length > 0 && (
            <>
              <Box component="div" fontWeight={600} mb={1}>Disciplinas selecionadas:</Box>
              {subjectsSelected.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    className={classes.chip}
                    label={item.title}
                    onDelete={()=>handleDeleteSubject(item)}
                  />
                )
              })}
            </>
          )}
        </Box>
        <Box mb={2} width>
          {sectionsSelected.length > 0 && (
            <>
              <Box component="div" fontWeight={600} mb={1}>Assuntos selecionados:</Box>
              {sectionsSelected.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    className={classes.chip}
                    label={item.title}
                    onDelete={()=>handleDeleteSection(item)}
                  />
                )
              })}
            </>
          )}
        </Box>
        <Box component="div" display="flex" justifyContent="start" mt={4}>
          <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
            {formik.isSubmitting ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              <>
                <Box mr={3}>Criar simulado</Box>
                <ArrowForwardIcon />
              </>
            )}
          </Button>
        </Box>
      </form>
      <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
