import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  IconButton,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useFormik } from "formik";
import * as yup from "yup";

import { useAuth } from "app/contexts/AuthContext";

import systemApi from "app/services/system-api";
import accountApi from "app/services/account-api";

const validationSchema = yup.object({
  firstName: yup.string().trim().required("Nome é obrigatório"),
  surname: yup.string().trim().required("Sobrenome é obrigatório"),
  educationLevelId: yup.number().required("Escolaridade é obrigatório"),
});

export default (props) => {

  const { refreshUserInfo } = useAuth();

  const [snackValues, setSnackValues] = React.useState({
    message: "",
    open: false,
  });

  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [schooling, setSchooling] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingCities, setLoadingCities] = React.useState(false);
  const [isForeign, setIsForeign] = React.useState(false);

  const [formValues, setFormValues] = React.useState({
    firstName: "",
    surname: "",
    stateId: "",
    cityId: "",
    educationLevelId: "",
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackValues({ open: false });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);

      accountApi
        .patch(`/me/profile`, {
          isForeign: !!isForeign,
          firstName: values.firstName,
          surname: values.surname,
          cityId: String(values.cityId) ?? null,
          stateId: String(values.stateId) ?? null,
          educationLevelId: String(values.educationLevelId)
        })
        .then((response) => {
          const fullname = `${values.firstName} ${values.surname}`;
          refreshUserInfo({fullname, firstname: values.firstName, surname: values.surname });
          setSnackValues({
            message: "Seus dados foram atualizados com sucesso.",
            open: true,
          });
        })
        .catch((err) => {
          const {response} = err
          if (response && response.data) {
            const {
              data: { errors },
            } = response;
            setSnackValues({
              message: errors.at(0),
              open: true,
            });
          } else {
            setSnackValues({
              message: "Ops! Falha ao atualizar seus dados. Tente novamente.",
              open: true,
            });
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    async function getData() {
      try {
        setLoadingData(true);

        const [states, levels, profile] = await Promise.all([
          systemApi.get("countries/BR/states"),
          systemApi.get("education-levels"),
          accountApi.get("me/profile"),
        ]);

        const cities = profile.data?.stateId
          ? await systemApi.get(
              `states/${profile.data.stateId}/cities`
            )
          : [];

        setIsForeign(profile.data?.isForeign ?? false);
        setStates(states.data);
        setCities(cities.data ?? []);
        setSchooling(levels.data);

        setFormValues({
          firstName: profile.data?.firstName,
          surname: profile.data?.surname,
          stateId: profile.data?.stateId ?? "",
          cityId: profile.data?.cityId ?? "",
          educationLevelId: profile.data?.educationLevelId ?? "",
        });
        setLoadingData(false);
      } catch (error) {
        setLoadingData(true);
        setSnackValues({
          message: "Ops! Falha ao carregar dados. Tente novamente.",
          open: true,
        });
      }
    }

    getData();
  }, []);

  const handleChangeState = (e) => {
    const target = e.target;

    formik.handleChange(e);

    if (target.value !== "") {
      setLoadingCities(true);
      setCities([]);

      systemApi
        .get(`states/${target.value}/cities`)
        .then((response) => {
          setCities(response.data);
        })
        .finally(() => {
          formik.setFieldValue("cityId", "");
          setLoadingCities(false);
        });
    }
  };

  return (
    <React.Fragment>
      {loadingData ? (
        <Box
          width="100%"
          height={300}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <form name="form-profile" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Primeiro nome"
                id="firstName"
                name="firstName"
                variant="outlined"
                type="text"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Sobrenome"
                id="surname"
                name="surname"
                variant="outlined"
                type="text"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.surname && Boolean(formik.errors.surname)}
                helperText={formik.touched.surname && formik.errors.surname}
              />
            </Grid>
            {!isForeign && (
              <React.Fragment>
                <Grid item xs={12} lg={6}>
                  <FormControl required={!isForeign} variant="outlined" fullWidth>
                    <InputLabel id="stateId">Estado</InputLabel>
                    <Select
                      required
                      native
                      labelId="stateId"
                      name="stateId"
                      label="Estado"
                      value={formik.values.stateId}
                      onChange={handleChangeState}
                      onBlur={formik.handleBlur}
                      disabled={isForeign}
                      displayEmpty
                    >
                      <option key={'state_0'}  aria-label="" value="" disabled></option>
                      {states.map((state) => (
                        <option key={`stateId_${state.id}`} value={state.id}>{state.name} ({state.code})</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl required={!isForeign && !loadingCities} variant="outlined" fullWidth>
                    <InputLabel id="cityId">{loadingCities ? 'Carregando ...' : "Cidade"}</InputLabel>
                    <Select
                      native
                      label={loadingCities ? 'Carregando ...' : "Cidade"}
                      labelId="cityId"
                      name="cityId"
                      value={formik.values.cityId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isForeign || loadingCities || cities?.length < 1}
                      displayEmpty
                    >
                      <option key={'city_0'} aria-label="" value="" disabled></option>
                      {
                        cities?.map((city) => (
                          <option key={`cityId_${city.id}`} value={city.id}>{city.name}</option>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12}>
              <FormControl required variant="outlined" fullWidth>
                <InputLabel id="educationLevelId">Escolaridade</InputLabel>
                <Select
                  native
                  required
                  label="Escolaridade"
                  labelId="educationLevelId"
                  name="educationLevelId"
                  value={formik.values.educationLevelId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option key={'educationLevelId_0'} value="" disabled></option>
                  {schooling.map((level) => (
                    <option key={`educationLevelId_${level.id}`} value={level.id}>
                      {level.level}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" textAlign="right">
                <Button
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    "Salvar"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
