import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Menu,
  MenuItem,
  Divider,
  Avatar,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import { useAuth } from 'app/contexts/AuthContext'

// Styles files
import { useStyles } from './styles'
import { useSubscription } from 'app/contexts/SubscriptionContext';

const AccountMenu = (props) => {
  const classes = useStyles();
  const { subscription } = useSubscription()

  const history = useHistory()
  const { signOut, user } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = (path) => {
    history.push(path);
    handleClose();
  };

  const handleSignout = useCallback(async () => {
    await signOut()
  },[signOut])

  const menuItems = [
    { label: 'Meu perfil', path: '/dashboard/user/profile' },
    { label: 'Minha conta', path: '/dashboard/user/account' },
    { label: 'Meus certificados', path: '/dashboard/user/certificates' }
  ];

  return (
    <>
      <IconButton
        aria-label="account"
        aria-controls="menu-account"
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        title="Dados pessoais"
      >
        <Avatar className={classes.avatar}>{(user?.firstname)?.charAt(0) || '?'}</Avatar>
      </IconButton>
      <Menu
        id="menu-account"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.path} onClick={() => handleMenuItemClick(item.path)}>
            {item.label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleSignout}>Sair</MenuItem>
      </Menu>
    </>
  )
}

export default React.memo(AccountMenu);
