/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { isMobile } from 'react-device-detect';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { Box } from '@material-ui/core';
import { useStyles } from './styles'
import { useScopes } from "app/contexts/ScopesContext";

const MobileApp = React.memo(({_slot}) => {
  const slotId = `kultivicom_app_mrec_1_${_slot}`

  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_app_mrec_1'
      slotId={slotId}
    />
  )
})

const Desktop = React.memo(({_slot}) => {
  const slotId = `kultivicom_incontent_${_slot}`

  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_incontent'
      slotId={slotId}
    />
  )
})

const AdsIncontent = (props) => {
  const classes = useStyles();
  const { slot } = props
  const { scopes } = useScopes();
  const _slot = slot || Math.random().toString(36).substr(2, 6)

  return (
    !scopes?.includes("student_no_ads_display") &&
    <div align="center">
      <Box className={classes.ads}>Publicidade</Box>
      { isMobile ? <MobileApp _slot={_slot} /> : <Desktop _slot={_slot} /> }
    </div>
  );
}

export default React.memo(AdsIncontent)
