import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import coreApi from 'app/services/core-api';
import EbookCard from 'app/components/cards/Ebook/model-1'
import Skeleton from 'app/components/cards/Course/model-3/skeleton'
import {
  Box,
 } from '@material-ui/core';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useStyles } from './styles'

export default (props) => {

    // States
    const [ebooks, setEbooks] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      coreApi.get(`discovery-units?context=ebook&type=2&page[number]=1&page[size]=10`).then(response => {
        setEbooks(response.data.items)
      }).catch(error => {
        console.warn(error)
      }).finally( () => {
        setLoading(false)
      })
    }, [props.category])

    const history = useHistory()
    const classes = useStyles()

    const responsive = {
        large: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        desktop: {
          breakpoint: { max: 1024, min: 768 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 768, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        }
    }

    return (

        <Box component="div">
            <Carousel
                itemClass={classes.carouselItem}
                responsive={responsive}
                arrows={!loading}
                partialVisible
              >
              {loading ?
                [0,1,2,3,4,5].map( (key) => {
                  return <Skeleton key={key} />
                }) :
                ebooks.map((ebook,index) => (
                  <EbookCard isEbookPlus={true} key={index} {...ebook} />
                ))
              }
            </Carousel>
        </Box>

    )
}
