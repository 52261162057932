import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
//import AppleIcon from '@material-ui/icons/Apple';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
//import AppleLogin from 'react-apple-login'

import DialogForgotPassword from "app/components/Modals/Auth/ForgotPassword";

import { useAuth } from "app/contexts/AuthContext";
import { useSnackbar } from "app/contexts/SnackbarContext";
import { signInValidator } from "app/validators/auth";

import { useStyles } from "./styles";
import { useFormik } from "formik";

export default () => {
  const history = useHistory();

  const classes = useStyles();
  const { signIn, socialSignIn } = useAuth();
  const { setSnackValues } = useSnackbar();

  const [socialLoading, setSocialLoading] = useState(false);
  const [signInGoogle, setSignInGoogle] = useState(false);
  const [signInFacebook, setSignInFacebook] = useState(false);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);
  const handleForgotPasswordDialog = (open) => (event) => {
    setOpenForgotPasswordDialog(open);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: signInValidator,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, actions) => {
      try {
        actions.setSubmitting(true);
        const user = await signIn(values);
        localStorage.removeItem('@Kultivi:login_attempts');
        // nao usar "history push" neste componente devido a renderização dos banners ads freestar
        if(user.is_confirmed)
          window.location.href = "/dashboard";
        else
          window.location.href = "/confirm-account";
      } catch (err) {
        const { response } = err;
        if (response && response.data) {
          const {
            data: { errors, message },
          } = response;
          const setErrors = {};
          // if (Array.isArray(errors)) {
          //   for (let index = 0; index < errors.length; index++) {
          //     const error = errors[index];
          //     setErrors[`${error.field}`] = error.message;
          //   }
          // } else

            let feedback = '';
            let feedbackField = '';
            if(message === 'EXCEPTION:AUTHENTICATION_FAILURE') {
              let loginAttempts = { attempts: 0 }
              try {
                loginAttempts = JSON.parse(localStorage.getItem('@Kultivi:login_attempts'));
              } catch (error) {
                //
              }

              const attempts = loginAttempts?.attempts + 1;
              localStorage.setItem('@Kultivi:login_attempts', JSON.stringify({attempts}))
              feedbackField = 'Usuário/senha incorreto(s)'
              if(attempts >= 3) {
                feedback = 'Clique em esqueci minha senha para resetar sua senha';
              }
            }
            else if(message === 'EXCEPTION:ACCOUNT_TEMPORARILY_BLOCKED') {
              feedbackField = 'Sua conta foi bloqueada temporariamente';
              feedback = 'Resete sua senha ou tente novamente em 5 minutos';
            }
            else if(message === 'EXCEPTION:ACCOUNT_BLOCKED') {
              feedbackField = 'Sua conta está bloqueada'
              feedback = 'Se isso foi um erro, contate: contato@kultivi.com';
            } else {
              feedbackField = errors.at(0) || message
              feedback = 'Falha ao acessar conta. Tente novamente';
            }
            setErrors[`username`] = feedbackField;
          actions.setErrors(setErrors);
          setSnackValues({
            message: `Ops! ${feedback}`,
            open: true,
          });
        }

        formik.setSubmitting(false);
      }
    },
  });

  const loginFacebook = async (response) => {
    if (response.error) {
      setSnackValues({
        message:
          "Ops! Não é possível entrar com Facebook. Tente novamente mais tarde.",
        open: true,
      });
    } else {
      try {
        setSignInFacebook(true);
        setSocialLoading(true);
        const payload = {
          provider: "facebook",
          _token: response.accessToken,
        };
        const user = await socialSignIn(payload);
        if(user.is_confirmed)
          window.location.href = "/dashboard";
        else
          window.location.href = "/confirm-account";
      } catch (error) {
        setSignInFacebook(false);
        setSocialLoading(false);
        const { response } = error;
        if (response && response.data) {
          const {
            data: { errors },
          } = response;
          setSnackValues({
            message: errors,
            open: true,
          });
        } else {
          setSnackValues({
            message: "Ops! Algum erro ocorreu. Tente novamente.",
            open: true,
          });
        }
      }
    }
  };

  const loginGoogle = async (response) => {
    if (response.error) {
      console.error(response.error);
      setSnackValues({
        message:
          "Ops! Não é possível entrar com Google. Tente novamente mais tarde.",
        open: true,
      });
    } else {
      try {
        setSignInGoogle(true);
        setSocialLoading(true);
        const payload = {
          provider: "google",
          _token: response.tokenId,
        };
        const user = await socialSignIn(payload);
        if(user.is_confirmed)
          window.location.href = "/dashboard";
        else
          window.location.href = "/confirm-account";
      } catch (error) {
        console.log('error', error)
        setSignInGoogle(false);
        setSocialLoading(false);
        const { response } = error;
        if (response && response.data) {
          const {
            data: { errors },
          } = response;
          setSnackValues({
            message: errors,
            open: true,
          });
        } else {
          setSnackValues({
            message: "Ops! Algum erro ocorreu. Tente novamente.",
            open: true,
          });
        }
      }
    }
  };

  return (
    <>
      <Box component="div" mb={5}>
        <form id="formSignIn" name="formSignIn" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="E-mail ou CPF"
                id="username"
                name="username"
                variant="outlined"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sua senha"
                id="password"
                name="password"
                variant="outlined"
                type="password"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                component="div"
                display="flex"
                justifyContent="flex-end"
                mb={2}
              >
                <Link
                  onClick={handleForgotPasswordDialog(true)}
                  variant="body2"
                  className={classes.forgotPasswordButton}
                >
                  ESQUECEU SUA SENHA?
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box component="div" display="flex" alignItems="flex-start">
                <Button
                  id="loginButton"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    formik.isSubmitting || !formik.isValid || socialLoading
                  }
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    <>
                      <Box mr={3}>ENTRAR</Box>
                      <ArrowForwardIcon />
                    </>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box
        component="div"
        justifyContent="center"
        flexDirection="column"
        className={classes.socialContainer}
        textAlign="center"
        width="100%"
        mx="auto"
        mb={5}
      >
        <Typography className={classes.loginFieldset} component="h2">
          Acesse com
        </Typography>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={4} md={4}>
            <FacebookLogin
              textButton="Facebook"
              appId={process.env.REACT_APP_OAUTH_FACEBOOK_KEY}
              fields="name,email,picture"
              callback={loginFacebook}
              render={(renderProps) => (
                <Button
                  id="loginFacebookAccount"
                  variant="contained"
                  disabled={renderProps.isDisabled || socialLoading}
                  fullWidth
                  className={[
                    classes.socialButtonFacebook,
                    classes.socialButton,
                  ].join(" ")}
                  onClick={renderProps.onClick}
                >
                  {signInFacebook ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <FacebookIcon />
                  )}
                </Button>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <GoogleLogin
              clientId={process.env.REACT_APP_OAUTH_GOOGLE_KEY}
              buttonText="Google"
              tag="button"
              icon={false}
              className={classes.socialButton}
              onSuccess={loginGoogle}
              onFailure={loginGoogle}
              isSignedIn={false}
              render={(renderProps) => (
                <Button
                  id="loginGoogleAccount"
                  variant="contained"
                  disabled={renderProps.disabled || socialLoading}
                  fullWidth
                  className={[
                    classes.socialButtonGoogle,
                    classes.socialButton,
                  ].join(" ")}
                  onClick={renderProps.onClick}
                >
                  {signInGoogle ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Google"
                  )}
                </Button>
              )}
            />
          </Grid>
          {/*<Grid item xs={12} sm={4} md={3}>
            <AppleLogin
              clientId={process.env.REACT_APP_OAUTH_APPLE_KEY}
              redirectURI="https://redirectUrl.com"
              render={renderProps => (
                <Button variant="contained" disabled={renderProps.disabled} fullWidth className={[classes.socialButtonApple, classes.socialButton].join(" ")} onClick={renderProps.onClick}><AppleIcon /></Button>
              )}
            />
          </Grid>*/}
        </Grid>
      </Box>
      <DialogForgotPassword
        open={openForgotPasswordDialog}
        toggleDialog={handleForgotPasswordDialog}
      />
    </>
  );
};
