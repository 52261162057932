Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var green = {
  light: '#b2ebe5',
  main: '#1dbfb0',
  dark: '#0F5B7A',
};

var _default = green;
exports.default = _default;