import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    cover: {
      width: "100%",
      height: 270,
      marginTop: 5,
    },
    category: {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(9),
      letterSpacing: theme.typography.pxToRem(1),
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    content: {
      display: "flex",
      flexDirection: "column",
      minHeight: 140,
    },
    title: {
      fontWeight: 600,
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(14),
    },
    summary: {
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(14),
    },
    info: {
      color: theme.palette.primary['main'],
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
    },
    infoIcon: {
      marginRight: theme.spacing(.5),
      color: theme.palette.grey[500],
    },
    infoContent: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.grey[500],
    },
    infoIconLink: {
      color: theme.palette.primary['main']
    },
    skeleton: {
      boxShadow: '0px 2px 6px rgba(92,84,122,0.2)',
      borderRadius: '10px',
    }
}))

export { useStyles }
