import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { pubfig } from 'scripts/freestar'

import {
  Box,
  CssBaseline,
  Link,
  Typography,
  Grid,
} from "@material-ui/core";

import LoginForm from "app/components/forms/Login";

// Assets import
import Logo from "assets/images/kultivi.png";

// Styles files
import { useStyles, AnimationContainer } from "./styles";

import Statistics from 'app/components/cards/Statistics'

export default function SignIn(props) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const freestar = pubfig();
    document.getElementsByTagName("head")[0].appendChild(freestar);
    return () => {
      document.getElementsByTagName("head")[0].removeChild(freestar);
    }
  })

  const goToSignUp = () => {
    history.push("/signup");
  };

  function Header() {
    return (
      <Box mb={4}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          mb={6}
        >
          <Box component="div" flexGrow={1} className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt="Logo Kulitiv" />
          </Box>
          <Box component="div">
            <Box component="span" mr={1} className={classes.copySignUp}>
              Não tem conta?
            </Box>
            <Link className={classes.ctaSignUp} onClick={() => goToSignUp()}>
              CADASTRE-SE AGORA!
            </Link>
          </Box>
        </Box>
        <Typography component="h2" variant="h2" className={classes.welcomeText}>
          Bem-vindo(a) a plataforma da Kultivi.
        </Typography>
        <Typography component="h1" variant="h1" className={classes.mainTitle}>
          Entre para estudar!
        </Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.leftContent}>
          <CssBaseline>
            <AnimationContainer>
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                mx="auto"
                className={classes.formArea}
              >
                <Header />
                <Box
                  component="div"
                  className={classes.loginFormContainer}
                  textAlign="center"
                  width="100%"
                  mx="auto"
                  mb={6}
                >
                  <LoginForm />
                </Box>
                <Box
                  component="div"
                  display="flex"
                  mt="auto"
                  flexDirection="row"
                  justifyContent={{ xs: "center", lg: "start" }}
                  mb={2}
                >
                  <Typography className={classes.policy}>
                    Conheça nossos{" "}
                    <Link
                      href="https://kultivi.com/politica-de-privacidade"
                      target="_blank"
                    >
                      Termos de Uso & Política de Privacidade
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </AnimationContainer>
          </CssBaseline>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightContent}>
          <Statistics />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
