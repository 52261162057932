import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';


export default (props) => {
  const { id, title } = props;

  return (
    <CardMedia
      component="img"
      alt={title}
      height="160"
      image={`https://cdn-kultivi.s3.amazonaws.com/courses/${id}.webp`}
      title={title}
    />
  );
}
