const dataAccordion = [
  {
    panel: "panel1",
    controls: "panel1bh-content",
    id: "panel1bh-header",
    title: "O que é o Clube de Benefícios?",
    content:
      "O Clube de Benefícios é um plano de sua escolha que libera acesso a ebooks, apostilas, plano de estudos, simulados personalizados, anotações nas vídeo aulas e descontos em lojas parceiras. Além de ajudar a Kultivi a continuar produzindo cursos de qualidade gratuitamente! ",
  },
  {
    panel: "panel2",
    controls: "panel2bh-content",
    id: "panel2bh-header",
    title: "Para que serve o Clube de Benefícios?",
    content:
      "Serve para auxiliar e complementar os estudos dos cursos gratuitos que disponibilizamos na plataforma. Oferece ferramentas e materiais exclusivos para os assinantes.",
  },
  {
    panel: "panel3",
    controls: "panel3bh-content",
    id: "panel3bh-header",
    title: "O Clube de Benefícios é cobrado todo mês?",
    content:
      "Temos diversos planos de assinatura. \"Kultivi+\" e \"Concursos\" é cobrado mensalmente ou anual.",
  },
  {
    panel: "panel5",
    controls: "panel5bh-content",
    id: "panel5bh-header",
    title: "Posso realizar os cursos sem assinar algum plano? ",
    content:
      "Pode! Todos os cursos que oferecemos na plataforma são totalmente gratuitos, sem pegadinhas! Você pode fazer um ou vários ao mesmo tempo. A assinatura dos planos é opcional, caso queira dar um up nos seus estudos.",
  },
  {
    panel: "panel6",
    controls: "panel6bh-content",
    id: "panel6bh-header",
    title:
      "Para receber os certificados dos cursos gratuitos, preciso assinar algum plano?",
    content:
      "Não precisa! Os certificados são digitais e totalmente gratuitos. Para ter acesso, basta finalizar o curso ou cursos que estiver fazendo e acessar o menu do aluno para download.",
  },

  {
    panel: "panel8",
    controls: "panel8bh-content",
    id: "panel8bh-header",
    title:
      "Assinarei o plano anual do Clube de Benefícios. Até quando terei acesso?",
    content:
      "Você terá acesso por 12 meses, até o dia do pagamento no próximo ano. Por exemplo: Assinatura realizada em 20 de junho de 2022 terá acesso até 20 de junho de 2023.",
  },
  {
    panel: "panel9",
    controls: "panel9bh-content",
    id: "panel9bh-header",
    title: "Quais as formas de pagamento?",
    content: "Boleto ou parcelado em até 12x no cartão de crédito.",
  },
  {
    panel: "panel10",
    controls: "panel10bh-content",
    id: "panel10bh-header",
    title: "Quanto tempo após o pagamento para liberar meu acesso?",
    content:
      "Até 72 horas após o pagamento seu acesso aos benefícios será liberado.",
  },
  {
    panel: "panel11",
    controls: "panel11bh-content",
    id: "panel11bh-header",
    title: "Qual a diferença entre os planos?",
    content:
      "O plano Kultivi+ oferece: Tudo que é gratuito, Contribuição com a educação, Material de apoio, Ferramenta de simulados, Biblioteca Kultivi, Caderno virtual e Clube de Benefícios.",
    contentSecondary:
      "O plano Kultivi Concursos oferece: Beneficios do Plano Kultivi+ e acesso ilimitado as apostilas preparatórias para concurso público.",
  },
];

export default dataAccordion;
