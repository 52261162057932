import React, { useState } from 'react'

// FRAMEWORK IMPORTS
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core'

import studentApi from 'app/services/student-api';

import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const { open, toggleDialog, toggleNote, note, course_id, lesson_id } = props;
  const [loading, setLoading] = useState(false)

  const deleteNote = (id) => {
    setLoading(true)
    studentApi.delete(`subscribed-courses/${course_id}/lessons/${lesson_id}/notes/${id}`)
      .then((response) => {
        toggleNote(id)
      })
      .catch((error) => {
        //
      })
      .finally(() => {
        setLoading(false)
      })
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
      onClose={toggleDialog(false)}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>Excluir anotação?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Uma vez que sua anotação foi excluída, não poderemos recuperá-la novamente.
        </DialogContentText>
        <DialogActions>
          <Button onClick={toggleDialog(false)} disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={() => deleteNote(note.id)} className={classes.buttonDelete} disabled={loading}>
            Excluir
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
