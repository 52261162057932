import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    heading: {
        marginRight: "auto"
    },
    secondaryHeading: {
        
    },
    accordionSummary: {
        alignItems: 'center'
    },
    module: {
        background: theme.palette.grey[200],
        color: theme.palette.getContrastText(theme.palette.grey[200]),
    },
    moduleDetails: {
        padding: 0,
        background: theme.palette.grey[900],
        color: theme.palette.getContrastText(theme.palette.grey[900])
    },
    lessonsList: {
        width: "100%",
    },
    lessonItemIcon: {
        color: theme.palette.grey[700],
    },
    lessonTitle: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.pxToRem(15),
    },
    lessonSubtitle: {
        color: theme.palette.grey[200],
        fontSize: theme.typography.pxToRem(12)
    }
}))

export { useStyles }
