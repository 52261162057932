import React from "react";
import { Switch, Route } from "react-router-dom";

import { Box } from "@material-ui/core";

// Components
import AppBarMenu from "app/components/navs/topbar/app-bar"


import Error from "app/components/layouts/500";
import NotFound from "app/components/layouts/404";
import Copyright from "app/components/layouts/Copyright";

// Pages
import Account from "app/pages/Account";
import Certificates from "app/pages/Certificates";
import Discover from "app/pages/Discover";
import DigitalCollections from "app/pages/DigitalCollections";
import DigitalCollectionsPlus from "app/pages/DigitalCollectionsPlus";
import EbookDetails from "app/pages/EbookDetails";
import EbookPlusDetails from "app/pages/EbookPlusDetails";
import Profile from "app/pages/Profile";
import Wellcome from "app/pages/Wellcome";
import MyCourses from "app/pages/MyCourses";
import Pills from "app/pages/Pills";
import Course from "app/pages/Course";
import Quizzes from "app/pages/Quizzes";
import QuizQuestions from "app/pages/QuizQuestions";
import ReferralPage from "app/pages/Referral";

// Styles files
import { useStyles } from "./styles";

const Dashboard = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box component="div" display="flex" className={classes.root}>
        <Box
          component="main"
          display="flex"
          flexDirection="column"
          className={classes.wrap}
        >
          <AppBarMenu />

          <Box mt={2}>
            <Switch>
              <Route exact path="/dashboard/" component={Wellcome} />
              <Route exact path="/dashboard/pills" component={Pills} />
              <Route exact path="/dashboard/pills/:topics+" component={Pills} />
              <Route exact path="/dashboard/enrollments" component={MyCourses} />
              <Route exact path="/dashboard/referrals" component={ReferralPage} />
              <Route path="/dashboard/course/:slug" component={Course} />
              <Route
                path="/dashboard/ebooks/:category?"
                component={DigitalCollections}
              />
              <Route
                exact
                path="/dashboard/aprovare"
                component={DigitalCollectionsPlus}
              />
              <Route exact path="/dashboard/aprovare/ebook/:slug" component={EbookPlusDetails} />
              <Route path="/dashboard/ebook/:slug" component={EbookDetails} />
              <Route exact path="/dashboard/user/profile" component={Profile} />
              <Route exact path="/dashboard/user/account" component={Account} />
              <Route
                exact
                path="/dashboard/user/certificates"
                component={Certificates}
              />
              <Route
                exact
                path="/dashboard/discover/:category?"
                component={Discover}
              />
              <Route exact path="/dashboard/quizzes" component={Quizzes} />
              <Route
                exact
                path="/dashboard/quizzes/:id"
                component={QuizQuestions}
              />
              <Route exact path="/dashboard/error" component={Error} />
              <Route path="/dashboard/404" component={NotFound} />
              <Route exact path="/dashboard/*" component={NotFound} />
            </Switch>
          </Box>
          <Copyright />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
