/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
} from '@material-ui/core'

import accountApi from 'app/services/account-api';
import { useAuth } from 'app/contexts/AuthContext'
import { useSnackbar } from "app/contexts/SnackbarContext";

import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const { open, toggleDialog } = props;
  const { signOut, user } = useAuth()
  const [loading, setLoading] = useState(false)
  const { setSnackValues } = useSnackbar();

  const deleteAccount = useCallback(() => {

    setLoading(true)
    accountApi.delete(`me/delete`)
      .then(async (response) => {
        signOut()
      })
      .catch((error) => {
        let message = error.response.data.message || 'Falha ao deletar sua conta';
        if(message === 'EXCEPTION:ACCOUNT_NOT_FOUND') {
          message = 'Falha ao deletar sua conta. Conta não encontrada';
        }

        if(message === 'EXCEPTION:CANT_DELETE_ACCOUNT_WITH_ACTIVE_SUBSCRIPTION') {
          message = 'Por favor, para deletar sua conta, primeiro você deve cancelar sua(s) assinatura(s)';
        }

        setSnackValues({
          message,
          open: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [signOut])

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
      onClose={() => toggleDialog(false)}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>Isso é um adeus? <span aria-label='' role="img">😢</span></DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user.firstname}, lamentamos em vê-lo ir! Tem certeza que deseja excluir sua conta? Uma vez que seus dados foram excluídos não poderemos recuperá-los novamente, ok?
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => toggleDialog(false)} disabled={loading}>
            Manter minha conta
          </Button>
          <Button onClick={deleteAccount} className={classes.buttonDelete} disabled={loading}>
            Sim, excluir
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
