import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop:"auto",
        backgroundColor: "#FFF",
        boxShadow: `0 0 10px -3px ${theme.palette.grey[200]}`,
        color: theme.palette.grey[400],
        "& *": {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(12),
        },
        "& a": {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
        }
    },
    links: {
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(2)
        }
    }
}))

export { useStyles }