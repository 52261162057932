import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 9999,
    },
    drawer: {
      width: '100%',
      maxHeight: '70%',
      paddingBottom: 60
    },
    list: {
      paddingTop: 10,
      background: theme.palette.grey[800],
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(11),
      color: theme.palette.grey[300],
      textTransform: 'uppercase',
      lineHeight: theme.typography.pxToRem(32),
    },
    listIcon: {
      color: theme.palette.grey[400],
    }
}))

export { useStyles }
