import React, { useState } from 'react'

// FRAMEWORK IMPORTS
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close';

// Forms
import FormForgotPassword from 'app/components/forms/Forgot'

// Lotties animations
import Lottie from 'react-lottie';
import animationData from 'lotties/mail-sent'

import { useStyles } from './styles'

export default (props) => {

  const classes  = useStyles()
  const {open,toggleDialog} = props;

  const [sent,setSent] = useState(false);
  const [email,setEmail] = useState('');

  const handleSuccess = (email) => {
    setSent(true);
    setEmail(email)
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Box component="div" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!sent && "Recupere sua senha!"}
      </DialogTitle>
      {sent ? (
        <DialogContent>
          <Box component="div" display="flex" justifyContent="center" mb={2}>
            <Lottie
              style={{
                maxWidth: 200,
                padding: 0,
                margin: 0,
              }}
              options={lottieOptions}
            />
          </Box>
          <DialogContentText classes={{root:classes.copy}}>
            <Box mb={2}>Dentro de alguns instantes você receberá no e-mail <strong>{email}</strong> as instruções para resetar sua senha!</Box>
            <small>Ah ... não esqueça de verificar também sua caixa de spam ;)</small>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText classes={{root:classes.copy}}>
            Informe o e-mail de cadastro ou o seu CPF e enviaremos as instruções para você recuperar sua senha :)
          </DialogContentText>
          <FormForgotPassword handleSuccess={handleSuccess} />
        </DialogContent>
      )}
    </Dialog>
  )
}
