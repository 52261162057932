import React from 'react'
import {
    Box,
    Grid,
    Button,
    TextField,
    CircularProgress,
    Snackbar,
    IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import accountApi from 'app/services/account-api';

//Contexts imports
import { useFormik } from 'formik'

import { redeemVoucherValidator } from 'app/validators/auth'
import { useAuth } from 'app/contexts/AuthContext';

export default (props) => {

  const {handleSuccess} = props;
  const { signOut } = useAuth();


  const [snackValues, setSnackValues] = React.useState({
      message: '',
      open: false,
  })
  const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setSnackValues({open:false})
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: redeemVoucherValidator,
    enableReinitialize: true,
    validateOnMount:true,
    onSubmit: (values,actions) => {
      actions.setSubmitting(true);
      accountApi.post(`/vouchers/redeem`, values)
      .then(async (response) => {
        handleSuccess()
        const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        await wait(5000);
        signOut()
      })
      .catch((err) => {
          setSnackValues({message:"Ops! Falha ao resgatar seu voucher. Tente novamente.",open:true});
          const { response } = err;
          if (response && response.data) {
            const {
              data: { message },
            } = response;
            const setErrors = {}
            let feedback = '';
            if(message === 'EXCEPTION:VOUCHER_NOT_FOUND') {
              feedback = 'Voucher não localizado'
            }
            else if(message === 'EXCEPTION:VOUCHER_HAS_BEEN_USED') {
              feedback = 'Voucher já utilizado'
            }
            else if(message === 'EXCEPTION:SUBSCRIPTION_NOT_FOUND') {
              feedback = 'Não localizamos sua compra. Por favor, entre em contato com: contato@kultivi.com.'
            } else {
              feedback = 'Verifique o campo digitado'
            }


            setErrors[`code`] = feedback;
            actions.setErrors(setErrors);
          }
      })
      .finally( () => {
          actions.setSubmitting(false);
      })

    }
  })


  return (
    <React.Fragment>
      <form name="form-forgot-password" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="E-mail ou código"
              id="code"
              name="code"
              variant="outlined"
              type="text"
              fullWidth={true}
              required={true}
              disabled={formik.isSubmitting}
              autoComplete="off"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item xs={12}>
            <Box component="div" textAlign="right">
              <Button
                color="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}>
                { formik.isSubmitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : 'Resgatar' }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
