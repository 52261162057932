/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import coreApi from "app/services/core-api";

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Hidden,
  Link,
  Typography,
  Container,
  Paper,
  InputBase,
  Card,
  CardContent,
} from "@material-ui/core";

import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";


import EbookCard from "app/components/cards/Ebook/model-1";
import SkeletonEbook from "app/components/cards/Ebook/model-1/skeleton";

import { AdsTop, AdsIncontent, VideoAdsIncontent } from "app/components/ads/freestar";

import Skeleton from "@material-ui/lab/Skeleton";

import { useFormik } from "formik";
import { useStyles } from "./styles";

// Lotties animations
import Lottie from "react-lottie";
import GhostLottie from "lotties/ghost";
import { useScopes } from "app/contexts/ScopesContext";

export default (props) => {

  const history = useHistory();
  const classes = useStyles();
  const { scopes } = useScopes();


  // Entities
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const [ebooks, setEbooks] = useState([]);
  const [loading, setLoading] = useState(true);

  // Local rules
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [configRestEbooks, setConfigRestEbooks] = useState({
    append: false, // Se false renderiza ebooks do zero; Se true 'carregar mais'
    page: 1,
    size: 12,
  });

  useEffect(() => {
    if (!configRestEbooks.append) {
      setEbooks([]);
    }

    setLoading(true);

    var filters = [];
    /** Monta filtros como querystring */
    for (var prop in configRestEbooks.filters) {
      const el = configRestEbooks.filters[prop];
      if (el) {
        filters.push(`${prop}=${el}`);
      }
    }

    const query = filters.length > 0 ? `&${filters.join("&")}` : "";

    coreApi
      .get(
        `discovery-units?context=ebook&type=2&page[number]=${configRestEbooks.page}&page[size]=${configRestEbooks.size}${query}`
      )
      .then((response) => {
        if (configRestEbooks.append) {
          setEbooks((prev) => [
            ...prev,
            ...response.data.items,
          ]);
        } else {
          setEbooks(response.data.items);
        }
        setIsLastPage(
          response.data.lastPage ===
            response.data.page ||
            response.data.lastPage === 0
        );
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => setLoading(false));
  }, [configRestEbooks]);

  // Atualiza URL e parâmetros para busca na API
  const handleCategory = useCallback(
    (slug) => {
      const link = slug ? `/dashboard/ebooks/${slug}` : `/dashboard/ebooks`;
      history.push(link);
      setConfigRestEbooks({
        ...configRestEbooks,
        page: 1,
        append: false,
        filters: {
          label: slug,
        },
      });
      searchForm.resetForm({search:""})
    },
    [history, configRestEbooks]
  );

  /**
   *
   * Reponsável por indicar ao useEffect de ebooks qual
   * a paginação e se deve manter ebooks adicionando novos
   * ou refazer a lista. */
  const handleMore = () => {
    setConfigRestEbooks({
      ...configRestEbooks,
      page: configRestEbooks.page + 1,
      append: true,
    });
  };

  /**
   *
   * Reponsável por indicar ao useEffect de cursos busca
   * do campo livre. */
  const handleSearch = (search) => {
    const filters = { ...configRestEbooks.filters, search: search };

    setConfigRestEbooks({
      ...configRestEbooks,
      append: false,
      page: 1,
      filters: filters,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen({ ...drawerOpen, [anchor]: open });
  };

  const searchForm = useFormik({
    initialValues: {
      search: "",
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      handleSearch(values.search);
    },
  });

  function CategoriesList() {
    return (
      <React.Fragment>
        <List
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={classes.filterCategory}
            >
              Categorias
            </ListSubheader>
          }
        >
          {loadingCategories
            ? [0, 1, 2, 3, 4, 5].map((item, key) => {
                return <Skeleton height={30} key={key} />;
              })
            : categories.map((category) => {
                const labelId = `checkbox-list-category-${category.id}`;
                return (
                  <ListItem
                    button
                    component="a"
                    key={`cat-${category.id}`}
                    onClick={() => {
                      handleCategory(category.slug);
                      setDrawerOpen(false);
                    }}
                    disabled={
                      configRestEbooks?.filters?.label === category.slug
                    }
                  >
                    <ListItemText id={labelId} primary={`${category.title}`} />
                  </ListItem>
                );
              })}
        </List>
      </React.Fragment>
    );
  }

  function ShowAllCategories() {
    const showingCategory = configRestEbooks.filters?.label;
    return (
      <Box component="div" pt={4} mb={2}>
        <Link
          disabled={!showingCategory}
          component="button"
          className={classes.showAll}
          onClick={() => handleCategory(null)}
        >
          Mostrar todos os eBooks
        </Link>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box component="div" mb={3}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component="div" mb={3}>
                <Typography component="h1" className={classes.exploringTitle}>
                  {'Apostilas digitais para concurseiros'}
                </Typography>
              </Box>
              <Box component="div" mb={4}>
                <Card>
                  <CardContent>
                    <Typography component="p">
                      A Editora Aprovare surgiu no ano de 2011, quando um grupo de professores e concurseiros resolveu se unir com o
                      objetivo de fornecer materiais de estudo, dirigidos e atualizados, para aqueles que buscam aprovação nos
                      principais concursos públicos do país.
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography component="p">
                      Com centenas de milhares de apostilas e livros vendidos, a Editora Aprovare tornou-se referência no mercado de conteúdo didático para concursos no Brasil e agora disponibiliza seu acervo para <strong>apoiadores da Kultivi</strong>.
                    </Typography>
                  </CardContent>
                  {!scopes.includes("student_get_special_ebooks") && (
                    <CardContent>
                      <Button
                        href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=ebooks"
                        target="_blank"
                        color="primary"
                        variant="contained"
                        size='small'
                      >
                        <>
                          <Box mr={1}>COMO APOIAR A KULTIVI</Box>
                          <ArrowForwardIcon />
                        </>
                      </Button>
                    </CardContent>
                  )}
                </Card>
              </Box>
              <Box component="div" display="flex" mb={3}>
                <Paper
                  component="form"
                  className={classes.searchPaper}
                  elevation={0}
                  onSubmitCapture={searchForm.handleSubmit}
                >
                  <InputBase
                    className={classes.inputSearch}
                    placeholder="Buscar"
                    inputProps={{ "aria-label": "buscar" }}
                    id="search"
                    name="search"
                    value={searchForm.values.search}
                    onChange={searchForm.handleChange}
                    type="search"
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={searchForm.handleSubmit}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box>
              <Grid container spacing={2} direction="row" alignItems="stretch">
                {ebooks.length < 1 && !loading ? (
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Lottie
                        style={{
                          maxWidth: 400,
                          padding: 0,
                          margin: 0,
                        }}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: GhostLottie,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                      />
                      <Typography
                        component="span"
                        className={classes.emptyText}
                      >
                        Nenhuma apostila encontrada
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  ebooks.map((ebook, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Hidden smDown>
                          {index > 6 && index % 6 === 0 && (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                          )}
                        </Hidden>
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <EbookCard isEbookPlus={true} key={index} {...ebook} />
                        </Grid>
                        <Hidden smDown>
                          {index > 0 && index === 5 && (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                          )}
                        </Hidden>
                        <Hidden mdUp>
                          {index > 0 && index === 1 ? (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                          ) : (
                            index > 2 &&
                            index % 2 !== 0 && (
                              <Grid item xs={12}>
                                <Box component="div" mb={4}>
                                  <AdsIncontent />
                                </Box>
                              </Grid>
                            )
                          )}
                        </Hidden>
                      </React.Fragment>
                    );
                  })
                )}
                {loading &&
                  [0, 1, 2, 3, 4, 5].map((item, key) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={key}>
                        <SkeletonEbook key={`sk-${key}`} />
                      </Grid>
                    );
                  })}
              </Grid>
              {!loading && !isLastPage && (
                <Box
                  display="flex"
                  component="div"
                  justifyContent="center"
                  my={3}
                >
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={handleMore}
                  >
                    Carregar mais
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(drawerOpen, false)}
      >
        <Box component="div" px={2}>
          <Box
            component="div"
            width="100%"
            display="flex"
            py={1}
            justifyContent="flex-end"
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
