import React from "react";
import { isMobile } from 'react-device-detect';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { Box } from '@material-ui/core';
import { useStyles } from './styles'
import { useScopes } from "app/contexts/ScopesContext";

const MobileApp = React.memo(() => {
  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_app_banner_bottom'
      slotId= 'kultivicom_app_banner_bottom'
    />
  )
})

const Desktop = React.memo(() => {
  return (
    <FreestarAdSlot
      publisher='kultivi-com'
      placementName='kultivicom_incontent'
      slotId='kultivicom_incontent_bottom'
    />
  )
});

const AdsBottom = () => {
  const classes = useStyles();
  const { scopes } = useScopes();

  return (
    !scopes?.includes("student_no_ads_display") &&
    <div align="center">
      <Box className={classes.ads}>Publicidade</Box>
      { isMobile ? <MobileApp /> : <Desktop /> }
    </div>
  );
}

export default React.memo(AdsBottom)
