import React from "react";
import { Box, Container, Typography, Paper, Button } from "@material-ui/core";

import { useStyles } from "./styles";

import UserQuizzes from "app/components/layouts/UserQuizzes";
import QuizForm from "app/components/forms/Quiz";

import { useScopes } from "app/contexts/ScopesContext";

import LockIcon from "@material-ui/icons/Lock";

// Lotties animations
import Lottie from "react-lottie";
import animationData from "lotties/boy-studying-science";

export default function Quizzes() {
  const classes = useStyles();

  const { scopes } = useScopes();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Container>
        <Box mb={4}>
          <Typography component="h1" variant="h1" className={classes.title}>
            Pratique e aprenda!
          </Typography>
          <Typography component="p" className={classes.subtitle}>
            Milhares de exercícios para você aprofundar seu conhecimento!
          </Typography>
        </Box>
        <Box mb={3}>
          {scopes.includes("student_manage_quiz") && <UserQuizzes />}
        </Box>
      </Container>
      <Paper elevation={0} square style={{ height: "100%" }}>
        <Box component="div" pt={6} mb={5}>
          {scopes.includes("student_manage_quiz") ? (
            <Container maxWidth="md">
              <Box
                component="div"
                textAlign="center"
                mb={3}
                fontWeight={600}
                fontSize={15}
              >
                Comece a praticar agora mesmo
              </Box>
              <QuizForm />
            </Container>
          ) : (
            <Container maxWidth="sm">
              <Box component="div" display="flex" justifyContent="center">
                <Lottie
                  style={{
                    maxWidth: 300,
                    padding: 0,
                    margin: 0,
                  }}
                  options={lottieOptions}
                />
              </Box>
              <Paper className={classes.boxKlubi}>
                <Box mb={2}>
                  <LockIcon color="disabled" fontSize="large" />
                </Box>
                <Box className={classes.boxKlubiTitle} mb={2}>
                  Módulo Bloqueado
                </Box>
                <Box mb={3}>
                  <Typography
                    component="p"
                    variant="p"
                    className={classes.boxKlubiCopy}
                  >
                    Tenha acesso a funcionalidades exclusivas, diversos e-books
                    e ainda ganhe desconto em quase 300 das principais lojas e
                    serviços do país. Conheça Kultivi+ agora.
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=quizzes"
                  target="_blank"
                >
                  CONHECER KULTIVI+
                </Button>
              </Paper>
            </Container>
          )}
        </Box>
      </Paper>
    </>
  );
}
