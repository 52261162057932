import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        marginBottom: theme.spacing(2)
    },
    menuItem: {
      zIndex: 10
    },
    hi: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.primary['dark']
    },
    configIcon: {
        color: theme.palette.primary['dark']
    },
    giftIcon: {
        color: theme.palette.secondary['dark']
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        backgroundColor: theme.palette.primary['dark']
    },
    iconButton: {
      '&:hover': {
        color: theme.palette.primary['main']
      }
    },
    iconButtonWhite: {
      color: "#FFF",
    }
}));

export { useStyles }
