import React from 'react'
import {
  Box,
  CssBaseline,
  Typography,
  Snackbar,
  IconButton,
  Grid,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Logo from 'assets/images/kultivi.png'
import Lottie from 'react-lottie';
import animationData from 'lotties/student-computer'
import { useStyles, AnimationContainer } from './styles'
import ResetPassword from 'app/components/forms/ResetPassword'
import Statistics from 'app/components/cards/Statistics'

export default (props) => {
  const { token } = props.computedMatch.params
  const classes = useStyles()
  const [isValidToken, setIsValidToken] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  })

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackValues({ open: false })
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  function Loading() {
    return (
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        style={{ height: '90vh' }}
      >
        <CircularProgress size={30} color="inherit" />
      </Box>
    )
  }

  function Header() {
    return (
      <Box mb={4}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          mb={6}
        >
          <Box component="div" flexGrow={1} className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt="Logo Kulitiv" />
          </Box>
        </Box>
        <Typography component="h1" variant="h1" className={classes.mainTitle}>Resetar senha!</Typography>
      </Box>
    )
  }

  function Form() {
    return (
      <Box
        component="div"
        className={classes.loginFormContainer}
        textAlign="center"
        width="100%"
        mx="auto"
        mb={6}
      >
        {isValidToken
          ? <ResetPassword token={token} />
          : <>
            <Box component="div" display="flex" flexDirection="column" alignItems="center">
              <Typography component="p" variant="p" className={classes.titleNoCertificates}>Token inválido</Typography>
              <Lottie
                style={{
                  maxWidth: 400,
                  padding: 0,
                  margin: 0,
                }}
                options={lottieOptions}
              />
            </Box>
          </>
        }
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.leftContent}>
          <CssBaseline>
            <AnimationContainer>
              <Box component="div" display="flex" flexDirection="column" mx="auto" className={classes.formArea}>
                {loading
                  ? <Loading />
                  : <>
                    <Header />
                    <Form />
                  </>

                }
              </Box>
            </AnimationContainer>
          </CssBaseline>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightContent}>
          <Statistics />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
