import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(18)
  },
  buttonDelete: {
    color: theme.palette.error['main']
  }
}))

export { useStyles }
