import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Divider,
  Paper,
  Chip,
  Container,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";

import studentApi from 'app/services/student-api'
import Skeleton from "@material-ui/lab/Skeleton";

import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

import NoteEditorDialog from "app/components/Modals/Lesson/NoteEditor";
import DeleteNoteDialog from "app/components/Modals/Lesson/DeleteNote";

// Styles files
import { useStyles } from "./styles";
import { useScopes } from "app/contexts/ScopesContext";

export default (props) => {
  const classes = useStyles();
  const { scopes } = useScopes();

  const {
    lesson_id,
    course_id,
    videoApi,
  } = props;

  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState(null);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
  const toggleNotesDialog =
    (open, note = null) =>
    (event) => {
      setNote(note);
      setOpenNotesDialog(open);
    };

  const [openDeleteNoteDialog, setOpenDeleteNoteDialog] = React.useState(false);
  const toggleDeleteNotesDialog = (open, note) => (event) => {
    setNote(note);
    setOpenDeleteNoteDialog(open);
  };

  const toggleNote = (id = null, note) => {
    // delete
    if (id && !note) {
      setOpenDeleteNoteDialog(false);
      setNotes(notes.filter((item) => item.id !== id));
    }
    // update
    else if (id && note) {
      for (const i in notes) {
        if (notes[i].id === id) {
          notes[i].body = note.body;
        }
      }
      setNotes(notes);
      setOpenNotesDialog(false);
    }
    // insert
    else {
      notes.unshift(note);
      setNotes(notes);
      setOpenNotesDialog(false);
    }
  };

  useEffect(() => {
    function getNotes() {
      if (scopes.includes("student_manage_class_notes")) {
        setLoadingNotes(true);
        studentApi
          .get(
            `subscribed-courses/${course_id}/lessons/${lesson_id}/notes`
          )
          .then((response) => {
            setNotes(response.data);
          })
          .catch((error) => {
            console.warn(error);
          })
          .finally(() => {
            setLoadingNotes(false);
          });
      }
    }
    getNotes();
  }, [course_id, lesson_id, scopes]);

  function Header() {
    return (
      <Box mb={3}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography component="h2" className={classes.notesMainTitle}>
            Anotações de Aula
          </Typography>
          <IconButton
            color="primary"
            aria-label="add-note"
            onClick={toggleNotesDialog(true)}
            disabled={!scopes.includes("student_manage_class_notes")}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
        <Divider />
      </Box>
    );
  }

  function PremiumSeller() {
    return (
      <Paper className={classes.noteKlubi} elevation={0}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box className={classes.noteKlubiTitle} mb={2}>
            Melhore a sua experiência na Kultivi
          </Box>
          <Typography component="p" className={classes.noteKlubiCopy}>
            Anote pontos importantes da aula e consulte-os sempre que precisar.
            Este recurso é exclusivo para alunos do Kultivi+.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=notes"
            target="_blank"
          >
            QUERO CONHECER
          </Button>
        </Box>
      </Paper>
    );
  }

  function FirstNote() {
    return (
      <Paper className={classes.noteKlubi} elevation={0}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={2}
        >
          <Box mb={2}>
            <NoteAddIcon fontSize="large" color="disabled" />
          </Box>
          <Box className={classes.noteKlubiTitle} mb={2}>
            Suas anotações aparecerão aqui
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleNotesDialog(true)}
          >
            Criar agora
          </Button>
        </Box>
      </Paper>
    );
  }


  return (
    <>
      <Paper className={classes.extraContent} elevation={0}>
        <Container maxWidth="sm">
          <Header />

          {!scopes.includes("student_manage_class_notes") ? (
            <PremiumSeller />
          ) : (
            loadingNotes ? (
              <>
                <Box mb={2}>
                  <Skeleton animation="wave" variant="rect" height={100} />
                </Box>
                <Skeleton animation="wave" variant="rect" height={100} />
              </>
            ) : notes.length > 0 ? (
              notes.map((note, index) => {
                return (
                  <Paper key={index} className={classes.noteBody} elevation={0}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Typography
                        component="span"
                        className={classes.noteCreatedAt}
                      >
                        {note.createdAt}
                      </Typography>
                    </Box>
                    <Box
                      mb={2}
                      dangerouslySetInnerHTML={{ __html: note.body }}
                    />
                    <Box
                      component="div"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {note.position > 0 && (
                        <Tooltip title="Ir para" arrow>
                          <Chip
                            color="primary"
                            size="small"
                            onClick={ () => {
                              videoApi.setPosition(note.position)
                              window.scrollTo(0,0);
                              videoApi.play()
                            }}
                            label={note.time}
                          />
                        </Tooltip>
                      )}
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        marginLeft="auto"
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={toggleNotesDialog(true, note)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={toggleDeleteNotesDialog(true, note)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                );
              })
            ) : (
              <FirstNote />
            )
          )}
        </Container>
      </Paper>
      <NoteEditorDialog
        open={openNotesDialog}
        toggleDialog={toggleNotesDialog}
        toggleNote={toggleNote}
        position={videoApi?.getCurrentTime() || 0}
        note={note}
        {...props}
      />
      <DeleteNoteDialog
        open={openDeleteNoteDialog}
        toggleDialog={toggleDeleteNotesDialog}
        toggleNote={toggleNote}
        note={note}
        {...props}
      />
    </>
  );
};
