import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  lightOverlay: {
    position: 'absolute',
    width: 65,
    height: 100,
    borderStyle: 'solid',
    borderBottomRightRadius: 90,
    borderTopRightRadius: 90,
    borderColor: 'transparent',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    left: -20,
    top: -25,
    transform: 'rotate(90deg)',
  },
  darkOverlay: (dark) => ({
    position: 'absolute',
    width: 180,
    height: 90,
    borderStyle: 'solid',
    borderWidth: 24,
    borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
    borderColor: `rgba(${dark.join(", ")},.3)`,
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    right: -45,
    bottom: -15,
    transform: 'rotate(-50deg)',
  }),
});

const hexToRgb = (hex) => {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g).map(x => parseInt(x, 16));
};

const calculateDarkColor = (rgb, brightness) => {
  return [
    Math.floor((255 - rgb[0]) * brightness),
    Math.floor((255 - rgb[1]) * brightness),
    Math.floor((255 - rgb[2]) * brightness)
  ];
};

const ColorBox = ({ color }) => {
  const rgb = useMemo(() => hexToRgb(color), [color]);
  const brightness = 0.2;
  const dark = useMemo(() => calculateDarkColor(rgb, brightness), [rgb, brightness]);
  const classes = useStyles(dark);

  return (
    <div className={classes.container} style={{ backgroundColor: color }}>
      <div className={classes.lightOverlay}></div>
      <div className={classes.darkOverlay}></div>
    </div>
  );
};

export default ColorBox;
