import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    left: 5,
    width: '98%',
    height: '95%',
    border: 0,
  },
  videoWrapperContainer: {
    position: "relative",
    width: "100%",
    paddingBottom: "56.25%",
  },
}))

export { useStyles }
