export class CPF {
	value;
  CPF_LENGTH = 11

	constructor(value) {
		this.value = this.normalize(value);
	}

	normalize(value) {
		return value
			.replace(/[^\d]/g, '')
			.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
	}

  isValid() {
    return this.validate();
  }

	validate() {
		if (!this.value) return false;
		const document = this.clean(this.value);
		if (!this.hasMinimumLength(document)) return false;
		if (this.isBlocked(document)) return false;
		const digit1 = this.calculateDigit(document, 10);
		const digit2 = this.calculateDigit(document, 11);
		const calculatedDigit = `${digit1}${digit2}`;
		const actualDigit = document.slice(9);
		return actualDigit === calculatedDigit;
	}

	calculateDigit(cpf, factor) {
		let total = 0;
		for (const digit of cpf) {
			if (factor > 1) {
				total += parseInt(digit) * factor;
				factor -= 1;
			}
		}
		const rest = total % 11;
		return rest < 2 ? 0 : 11 - rest;
	}

	clean(cpf) {
		return cpf.replace(/\D/g, '');
	}

	hasMinimumLength(cpf) {
		return cpf.length === this.CPF_LENGTH;
	}

	isBlocked(cpf) {
		const [firstDigit] = cpf;
		return [...cpf].every((digit) => digit === firstDigit);
	}
}
