import React, { createContext, useState, useContext } from 'react';

import {
  Snackbar,
  IconButton,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

const SnackbarContext = createContext({})

export const SnarckbarProvider = ({ children }) => {

  const [snackValues, setSnackValues] = useState({
    message: '',
    open: false,
  })

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackValues({ open: false })
  }

  return (
    <SnackbarContext.Provider value={{
      setSnackValues,
    }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </SnackbarContext.Provider>
  )
}

export function useSnackbar() {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within an SnackbarProvider')
  }

  return context;
}
