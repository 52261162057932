import React, { createContext, useEffect, useState, useContext } from 'react';

import { useAuth } from 'app/contexts/AuthContext'

const ScopesContext = createContext({})

export const ScopesProvider = ({ children }) => {

  const {user} = useAuth();
  const [scopes,setScopes] = useState([])

  useEffect( () => {

    if(user) {
      setScopes(user.scopes || []);
    }

  }, [user]);

  return (
    <ScopesContext.Provider value={{
      scopes,
    }}>
      {children}
    </ScopesContext.Provider>
  )
}

export function useScopes() {
  const context = useContext(ScopesContext)

  if (!context) {
    throw new Error('useScopes must be used within an ScopesProvider')
  }

  return context;
}
