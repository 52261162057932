import React, { useEffect, memo } from "react";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import { Box, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Connatixel = ({
  setLoadingVideo,
  setVideoDone,
  setVideoApi,
  loadingVideo,
  finishVideo,
  lesson,
}) => {

  const initCnxelHeadScript = () => {

    /* eslint-disable */
    try {
      !(function (n) {
        if (!window.cnx) {
          (window.cnx = {}), (window.cnx.cmd = []);
          var t = n.createElement("iframe");
          (t.display = "none"),
            (t.onload = function () {
              var n = t.contentWindow.document,
                c = n.createElement("script");
              (c.src = "//cd.connatix.com/connatix.player.js?cid=92e9dfb3-b71f-4061-8312-3e07284a749d"),
                c.setAttribute("async", "1"),
                c.setAttribute("type", "text/javascript"),
                n.body.appendChild(c);
            }),
            n.head.appendChild(t);
        }
      })(document);
    } catch (error) {
      console.log("Some Connatix init error:", error);
    }
    /* eslint-enable */
  };

  initCnxelHeadScript();

  return (
    <Box>
      <AdBlockDetectedWrapper>
        <Box my={3}>
          <Alert variant="filled" severity="error">
            Para que o player funcione corretamente, por favor desative o AdBlock!
          </Alert>
        </Box>
      </AdBlockDetectedWrapper>
      <Player
        mediaId={lesson.connatixCode}
        setVideoDone={setVideoDone}
        setLoadingVideo={setLoadingVideo}
        finishVideo={finishVideo}
        setVideoApi={setVideoApi}
      />
      {!loadingVideo &&
        <Alert variant="filled" severity="info" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
          <Link
            href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=link_player"
            target="_blank"
            style={{color: '#FFF', textDecoration: 'underline'}}
          >Assista sem interrupções, ajuste a velocidade e ainda apoie a nossa missão. CLIQUE AQUI</Link>
        </Alert>
      }
    </Box>
  );
};

const Player = ({mediaId, finishVideo, setLoadingVideo, setVideoDone, setVideoApi}) => {

  const playerId = '94bdc624-560e-499a-9c58-b84d41714ec3';
  const renderId = '7529ba5a7a444b63863a3d58080f331a';

  const initCnxelBodyScript = () => {
    /* eslint-disable */
    (new Image()).src = `https://capi.connatix.com/tr/si?token=${playerId}&92e9dfb3-b71f-4061-8312-3e07284a749d`;
    cnx.cmd.push(function () {
      cnx({
        playerId,
        mediaId,
        settings: {
          playbackMode: 1,
          defaultSoundMode: 1,
          nextVideoMode: 2,
          advertising: {
            timeBetweenAds: 300
          },
          customization: {
            responsive: true,
            ratioWidth: 16,
            ratioHeight: 9,
          },
        }
      }).render(renderId, (renderError, playerApi) => {

        if (!renderError) {

          setVideoApi({
              play: () => playerApi.play(),
              setPosition: (time) => playerApi.setVideoPosition(time),
              getCurrentTime: () => playerApi.getVideoCurrentPosition(),
          });

          playerApi.on('isViewable', () => {
            setLoadingVideo(false);
          });

          playerApi.on("videoCompleted100", async () => {
            setVideoDone(true);
            await finishVideo()
          });
        }
      });
    });

    /* eslint-enable */
  };

  useEffect(() => {
    initCnxelBodyScript();
    /* eslint-disable */
  }, []);

  return(
    <div className="cnx" id={'cnx'+playerId}>
      <div id={renderId}></div>
    </div>
  )
}

export default memo(Connatixel);

