import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
    },
    paper: {
        width: theme.overrides.MuiDrawer.paper.width+70,
        background: theme.palette.grey[800],
        overflowX: 'hidden',
    },
    drawerGroup: {
        marginBottom: theme.spacing(1),
    },
    drawerGroupLabel: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.grey[300],
        textTransform: 'uppercase',
        lineHeight: theme.typography.pxToRem(32),
    },
    drawerIcon: {
        color: theme.palette.getContrastText(theme.palette.grey[400]),
    },
    upperMenu: {
        color: theme.palette.getContrastText(theme.palette.grey[800]),
    },
    BtnNavClasses: {
        flexGrow: 1,
        width: "50%",
        borderRadius:0,
        boxSizing: 'border-box',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[600],
        },
        '&:only-child': {
          width: '100%'
        }
    },
    BtnNavClassesNeutral: {
      color: theme.palette.getContrastText(theme.palette.grey[600]),
      backgroundColor: theme.palette.grey[600],
      '&:hover': {
        backgroundColor: theme.palette.grey[700],
      },
    },
    BtnNavClassesFeatured: {
      backgroundColor: theme.palette.common.white,
      borderRadius:0,
      '&:hover': {
        color: "#fff",
        backgroundColor: theme.palette.primary.dark,
      }
    },
    premiumCard: {
        width: "100%",
        borderRadius: 0,
        background: theme.palette.secondary['main'],
        color: theme.palette.getContrastText(theme.palette.secondary['main']),
    },
    premiumCardTitle: {
        marginBottom: theme.spacing(1),
        fontWeight: 800
    },
    premiumCardAction: {
        marginLeft: "auto",
        color: "#FFF",
    }
}))

export { useStyles }
