/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

function InfoSectionKlubi() {
  const classes = useStyles();

  return (
    <Box className={classes.content} component="section">
      <Typography className={classes.description}>
        Sua participação nos planos pagos da Kultivi promove{" "}
        <strong>a democratização do ensino gratuito de qualidade,</strong> que
        vem mudando a vida de <strong>mais de 3 milhões de pessoas</strong> desde
        2017!
      </Typography>
    </Box>
  );
}

export default InfoSectionKlubi;
