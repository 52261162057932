import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotesIcon from '@material-ui/icons/Notes';
import HelpIcon from '@material-ui/icons/Help';
import DoneIcon from '@material-ui/icons/Done';

import { useStyles } from './styles'

export default (props) => {

    const {
      progress,
      lessons,
      title,
      currentClass
    } = props

    const classes = useStyles();

    const id = `panel1a-header-${props.id}`
    const ariaControls = `panel-content-${props.id}`;

    function ClassTypeIcon( props ) {

        if(props.currentClass === props.slug) {
            return <ChevronRightIcon color="secondary" />
        }
        else if(props.seen) {
            return <DoneIcon color="primary" />
        }
        else {
            if(props.type === 1) {
                return <PlayCircleFilledIcon />
            }
            else if(props.type === 2) {
                return <NotesIcon />
            }
            else if(props.type === 3) {
                return <HelpIcon />
            }
        }

    }

    return (
        <Accordion square className={classes.module} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={ariaControls}
                id={id}
            >
                <Typography className={classes.heading} dangerouslySetInnerHTML={{ __html: title}}></Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.moduleDetails}>
                <List component="div" disablePadding className={classes.lessonsList} >
                    {lessons.map( (lesson,index) => {
                        return (
                            <ListItem key={index} button className={[classes.nested,classes.lessonItem].join(" ")} component="a" href={`./${lesson.slug}`}>
                                <ListItemIcon className={classes.lessonItemIcon}>
                                    <ClassTypeIcon seen={progress.includes(lesson.id)} currentClass={props.currentClass}  {...lesson} />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.lessonTitle, secondary: classes.lessonSubtitle }}
                                    primary={<Typography component="span" dangerouslySetInnerHTML={{ __html: lesson.title}}></Typography>}
                                    secondary={lesson.time}
                                />
                            </ListItem>
                        )
                    })}

                </List>
            </AccordionDetails>
        </Accordion>
    );
}
