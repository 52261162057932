import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "290px",
    height: "auto",
    padding: "2rem",
    margin: "0 auto",

    backgroundColor: theme.palette.grey[700],
    borderRadius: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "60%",
      height: "100%",
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  green: {
    color: theme.palette.primary.main,
  },
  yellow: {
    color: "#FEF080",
  },
  orange: {
    color: "#F68E60",
  },
  contentImage: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  thumbnail: {
    margin: "0 auto",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Raleway, sans-serif",
  },
  greenText: {
    color: theme.palette.primary.main,
  },
  yellowText: {
    color: "#FEF080",
  },
  orangeText: {
    color: "#F68E60",
  },
  prices: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bolder",
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "1rem 0",
  },
  previousPrice: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "-0.5rem 0 1rem",
    textDecoration: "line-through",
  },
  button: {
    width: "100%",
    fontFamily: "Nunito Sans, sans-serif",
  },
  buttonGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#26A69A",
    },
  },
  buttonYellow: {
    backgroundColor: "#FEF080",
    color: theme.palette.grey[700],
    "&:hover": {
      backgroundColor: "#FFEB4F",
    },
  },
  buttonOrange: {
    backgroundColor: "#F68E60",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F46425",
    },
  },

  contentNext: {
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    color: theme.palette.grey[800],
    width: "20%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "10px",
    },
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    top: "5px",
    right: "5px",
    width: "12px",
    heigth: "12px",
  },
}));

export { useStyles };
