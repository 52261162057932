var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});

Object.defineProperty(exports, "purple", {
  enumerable: true,
  get: function get() {
    return _purple.default;
  }
});

Object.defineProperty(exports, "green", {
  enumerable: true,
  get: function get() {
    return _green.default;
  }
});

Object.defineProperty(exports, "grey", {
  enumerable: true,
  get: function get() {
    return _grey.default;
  }
});

var _purple = _interopRequireDefault(require("./purple"));
var _green = _interopRequireDefault(require("./green"));
var _grey = _interopRequireDefault(require("./grey"));