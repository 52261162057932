import React, { useRef } from "react";

import Footer from "app/components/Footer";
import NavigationKlubi from "app/components/navs/klubi";
import HeroSectionKlubi from "app/components/sections/HeroKlubi";
import InfoSectionKlubi from "app/components/sections/InfoKlubi";
import PlansSectionKlubi from "app/components/sections/PlansKlubi";
import AccordionSectionKlubi from "app/components/sections/AccordionKlubi";
import FirstPlansSectionKlubi from "app/components/sections/FirstPlansKlubi";

export default () => {
  const faqRef = useRef(null);
  const bestRef = useRef(null);
  const partnersRef = useRef(null);
  const allPlansRef = useRef(null);

  return (
    <React.Fragment>
      <NavigationKlubi
        faqRef={faqRef}
        bestRef={bestRef}
        allPlansRef={allPlansRef}
        partnersRef={partnersRef}
      />
      <HeroSectionKlubi allPlansRef={allPlansRef} />
      <FirstPlansSectionKlubi allPlansRef={allPlansRef} />
      <InfoSectionKlubi />
      <AccordionSectionKlubi faqRef={faqRef} />
      <PlansSectionKlubi bestRef={bestRef} />
      {/* <PartnersSectionKlubi partnersRef={partnersRef} /> */}
      <Footer />
    </React.Fragment>
  );
};
