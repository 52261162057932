import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import coreApi from "app/services/core-api";
import studentApi from "app/services/student-api";

import {
  Container,
  Box,
  Button,
  Typography,
  Chip,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardActions,
  CircularProgress,
  Link,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { useScopes } from "app/contexts/ScopesContext";
import { useSnackbar } from "app/contexts/SnackbarContext";

import RelatedEbooksPlus from "app/components/layouts/RelatedEbooksPlus";
import { AdsTop, AdsIncontent } from "app/components/ads/freestar";
import NotificationKlubi from "app/components/notificationBar/KultiviPlus";

import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();
  const { setSnackValues } = useSnackbar();
  const { scopes } = useScopes();
  const history = useHistory();

  const [ebook, setEbook] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = props.match.params;

  const [downloading, setDownloading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    coreApi
      .get(`/ebooks/${slug}`)
      .then((response) => {
        setEbook(response.data);
      })
      .catch((err) => {
        console.warn(err.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  function _handleDownload() {
    setDownloading(true);
    setSnackValues({
      message: "Aguarde enquanto processamos sua apostila.",
      open: true,
    });
    studentApi
      .get(`digital_collections/plus/${slug}/download`, {
        method: "GET",
      })
      .then((response) => {
        const url = response.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.target = '_blank';
        link.setAttribute("download", `${ebook.slug}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSnackValues({
          message: "Caso sua apostila não baixe automaticamente, verifique o bloqueio de pop-up do seu navegador.",
          open: true,
        });
      })
      .catch((error) => {
        console.error(error);
        setSnackValues({
          message: "OPS! Erro ao baixar o arquivo. Tente novamente mais tarde.",
          open: true,
        });
      })
      .finally(() => {
        setDownloading(false);
      });
  }

  const goTo = (url) => {
    history.push(`${url}`);
  };

  return (
    <>
      <Box component="div" mb={7}>
        <Container>
          {!scopes.includes("student_get_special_ebooks") && (
              <NotificationKlubi
                type="subscriptionRequired"
                message="Este e outros materiais são exclusivos para apoiadores do 'Kultivi Concursos'"
              />
            )}
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} md={8} lg={6}>
              <Box component="div" mb={3}>
                {isLoading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height={30}
                      width={70}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={80}
                      style={{ marginBottom: 18 }}
                    />
                    {[0, 1, 2].map((item, index) => (
                      <Skeleton key={index} animation="wave" variant="text" />
                    ))}
                  </>
                ) : (
                  <>
                    {ebook.category && (
                      <Chip
                        label={ebook.category.title}
                        style={{ backgroundColor: `${ebook.category.color}` }}
                        className={classes.chip}
                      />
                    )}
                    <Typography
                      component="h1"
                      variant="h1"
                      className={classes.ebookTitle}
                    >
                      {ebook.title}
                    </Typography>
                    <Typography component="p">{ebook.summary}</Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={classes.cardEbook}>
                {isLoading ? (
                  <React.Fragment>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      className={classes.ebookMedia}
                    />
                    <Box component="div" width="95%" mx="auto" py={2}>
                      <Skeleton animation="wave" height={70} />
                    </Box>
                  </React.Fragment>
                ) : (
                  <>
                    <CardMedia
                      className={classes.ebookMedia}
                      image={ebook.thumbnail}
                      title={ebook.title}
                    />
                    <CardActions>
                      <Box component="div" width="95%" mx="auto" mb={1}>
                        <Button
                          id="downloadEbook"
                          fullWidth
                          color="primary"
                          variant="contained"
                          onClick={_handleDownload}
                          disabled={
                            !scopes.includes(
                              "student_get_special_ebooks"
                            ) || downloading
                          }
                        >
                          {!downloading ? (
                            "ACESSAR MATERIAL"
                          ) : (
                            <CircularProgress size={30} color="inherit" />
                          )}
                        </Button>
                      </Box>
                    </CardActions>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box component="div" mb={7}>
        <Paper elevation={0} className={classes.ebookContent}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box component="div" mb={3}>
                  <AdsTop />
                </Box>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Box component="div" mb={6}>
                  <Typography
                    component="h2"
                    variant="h2"
                    className={classes.ebookSectionTitle}
                  >
                    Sobre o e-book
                  </Typography>
                  {isLoading ? (
                    [0, 1, 2, 4, 5, 6].map((item, index) => (
                      <Skeleton key={index} animation="wave" variant="text" />
                    ))
                  ) : (
                    <Box
                      component="div"
                      dangerouslySetInnerHTML={{ __html: ebook.description }}
                    />
                  )}
                </Box>
                <Box component="div" mb={4}>
                  <AdsIncontent />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Box>

      <Box component="div" mb={7}>
        <Container>
          {!isLoading && (
            <React.Fragment>
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Typography
                  component="h2"
                  variant="h2"
                  className={[
                    classes.ebookSectionTitle,
                    classes.ebookSectionTitleRelatedCourses,
                  ].join(" ")}
                >
                  {!ebook.category
                    ? "Mais apostilas"
                    : `Mais apostilas sobre ${ebook.category?.title}`}
                </Typography>
                <Link
                  component="button"
                  onClick={() =>
                    goTo(`/dashboard/aprovare`)
                  }
                  className={[
                    classes.seeMore,
                    classes.relatedEbooksAction,
                  ].join(" ")}
                >
                  Ver tudo <ChevronRightIcon />
                </Link>
              </Box>
              <RelatedEbooksPlus />
            </React.Fragment>
          )}
        </Container>
      </Box>
    </>
  );
};
