import React, { useState } from 'react'

// FRAMEWORK IMPORTS
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close';

// Forms
import FormRedeemVoucher from 'app/components/forms/RedeemVoucher'

import Lottie from 'react-lottie';
import animationData from 'lotties/check'

import { useStyles } from './styles'

export default (props) => {

  const classes  = useStyles()
  const {open,toggleDialog} = props;

  const [sent,setSent] = useState(false);

  const handleSuccess = () => {
    setSent(true);
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Box component="div" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialog(false)} disabled={sent}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!sent && "Resgatar Assinatura"}
      </DialogTitle>
      {sent ? (
        <DialogContent>
          <DialogContentText classes={{root:classes.copy}}>
            <Box mb={2} display='flex' justifyContent='center'>
              <Lottie
                style={{
                  maxWidth: 400,
                  padding: 0,
                  margin: 0,
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </Box>
            <Box mb={2}>Voucher resgatado com sucesso. Em até 5 segundos você será redirecionado para a área de login! Caso ocorra algum problema, entre em contato em contato@kultivi.com</Box>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText classes={{root:classes.copy}}>
            Digite o e-mail utilizado na compra ou o código recebido por e-mail para resgatar sua compra.
          </DialogContentText>
          <FormRedeemVoucher handleSuccess={handleSuccess} />
        </DialogContent>
      )}
    </Dialog>
  )
}
