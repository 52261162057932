import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import green from "assets/images/verify-green.svg";
import purple from "assets/images/verify-purple.svg";
import orange from "assets/images/verify-orange.svg";
import remove from "assets/images/Remove.svg";

import { useStyles } from "./styles";
import { useEffect } from "react";
import { listOptionsPlans } from "../data";

function Plan({ plan }) {
  const [token, setToken] = useState(null);

  const handleRedirectToCheckout = (path) => {
    window.open(`${path}?token=${token}`, `_blank`);
  };

  const classes = useStyles();

  const plus = plan.title.includes("+") && classes.green;
  const buttonGreen = plan.title.includes("+") && classes.buttonGreen;
  const pos = plan.title.includes("Pós") && classes.yellow;
  const buttonYellow = plan.title.includes("Pós") && classes.buttonYellow;
  const free = plan.title === "Kultivi" && classes.purple;
  // const academic = plan.title.includes("Academia") && classes.orange;
  // const buttonOrange = plan.title.includes("Academia") && classes.buttonOrange;

  const academic = plan.title.includes("Concursos") && classes.orange;
  const buttonOrange = plan.title.includes("Concursos") && classes.buttonOrange;

  useEffect(() => {
    const responseStorage = localStorage.getItem("@Kultivi:token");

    if (responseStorage) {
      setToken(responseStorage);
    }
  }, [token]);

  return (
    <Box
      component="div"
      className={
        plan.recommended ? classes.containerRecommended : classes.container
      }
    >
      {plan.recommended && (
        <Box className={classes.headingPlan}>
          <Typography variant="body1" className={classes.recommendedTitle}>
            {plan.recommended}
          </Typography>
        </Box>
      )}
      <Box
        component="div"
        className={plan.recommended ? classes.recommended : classes.content}
      >
        <Box className={classes.headTitles}>
          <Typography className={[classes.title, free, plus, pos, academic]}>
            Apoio
          </Typography>
          <Typography className={[classes.title, free, plus, pos, academic]}>
            {plan.title}
          </Typography>
        </Box>

        <Box component="div" className={classes.iconsContainer}>
          {listOptionsPlans.map((option) => {
            const newOptionWithPlan = plan.content.filter((uniqPlan) => {
              return uniqPlan.title === option.title;
            });

            return (
              <>
                {plan.title === "Kultivi" && newOptionWithPlan[0] ? (
                  <img
                    className={classes.icon}
                    src={purple}
                    alt="Icone de verificação colorido"
                  />
                ) : (
                  plan.title === "Kultivi" && (
                    <img
                      className={classes.remove}
                      src={remove}
                      alt="Icone simbolizando que não tem"
                    />
                  )
                )}

                {plan.title.includes("+") && newOptionWithPlan[0] ? (
                  <img
                    className={classes.iconGreen}
                    src={green}
                    alt="Icone de verificação colorido"
                  />
                ) : (
                  plan.title.includes("+") && (
                    <img
                      className={classes.greenRemove}
                      src={remove}
                      alt="Icone simbolizando que não tem"
                    />
                  )
                )}

                {plan.title.includes("Concursos") && newOptionWithPlan[0] ? (
                  <img
                    className={classes.iconOrange}
                    src={orange}
                    alt="Icone de verificação colorido"
                  />
                ) : (
                  plan.title.includes("Concursos") && (
                    <img
                      className={classes.yellowRemove}
                      src={remove}
                      alt="Icone simbolizando que não tem"
                    />
                  )
                )}
              </>
            );
          })}
        </Box>
      </Box>

      <Box
        component="div"
        className={[
          classes.footerPlan,
          plan.recommended && classes.footerRecommended,
        ]}
      >
        {plan.priceOne && plan.priceTwo && plan.cta && (
          <>
            {!plan.path.yearly && (
              <>
                <Typography variant="body1" className={classes.prices}>
                  {plan.priceOne} <Typography variant="body2">ou</Typography>{" "}
                  {plan.priceTwo}
                </Typography>{" "}
                <Button
                  id={plan.id}
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.month)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  {plan.cta}
                </Button>
              </>
            )}

            {plan.path.month && plan.path.yearly && (
              <>
                <Typography variant="body1" className={classes.prices}>
                  {plan.priceOne}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.month)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  Assinar Kultivi+ mensal
                </Button>
                <Typography variant="body1" className={classes.prices}>
                  {plan.priceTwo}
                </Typography>{" "}
                <Typography variant="body1" className={classes.previousPrice}>
                  {plan.priceThree}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleRedirectToCheckout(plan.path.yearly)}
                  className={[
                    classes.button,
                    buttonGreen,
                    buttonYellow,
                    buttonOrange,
                  ]}
                >
                  Assinar Kultivi+ anual
                </Button>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default Plan;
