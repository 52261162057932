import React from "react";
import { Switch } from "react-router-dom";

import Maintenance from "app/pages/Maintenance";

import Dashboard from "app/components/layouts/Dashboard";
import Lesson from "app/components/layouts/Lesson";
import InternalServerError from "app/components/layouts/500";
import NotFound from "app/components/layouts/404";

import SignIn from "app/pages/SignIn";
import SignUp from "app/pages/SignUp";
import RecoveryPassword from "app/pages/RecoveryPassword";
import KultiviPlus from "app/pages/KultiviPlus";
import AccountCodeConfirmation from "app/pages/AccountCodeConfirmation";
import LoaderPage from "app/pages/Loader";

import PrivateRoute from "./guards/PrivateRoute";
import CheckoutRoute from "./guards/CheckoutRoute";
import WaitingConfirmationRoute from "./guards/WaitingConfirmationRoute";
import PublicRoute from "./guards/PublicRoute";

import { useAuth } from 'app/contexts/AuthContext'

export default () => {
  const { isSigned, user, isLoadingStorage } = useAuth()

  return process.env.REACT_APP_MAINTENANCE === "true" ? (
    <Maintenance />
  ) : (
    isLoadingStorage ? <LoaderPage /> :
    (
      <Switch>
        <PublicRoute isAuthenticated={isSigned} isConfirmed={user?.is_confirmed} path="/" exact component={SignIn} />
        <PublicRoute isAuthenticated={isSigned} isConfirmed={user?.is_confirmed} path="/signup" exact component={SignUp} />
        <PublicRoute isAuthenticated={isSigned} isConfirmed={user?.is_confirmed} path="/reset-password/:token" component={RecoveryPassword} />

        <WaitingConfirmationRoute
          isAuthenticated={isSigned}
          isConfirmed={user?.is_confirmed}
          path="/confirm-account"
          exact
          component={AccountCodeConfirmation}
        />
        <CheckoutRoute
          isAuthenticated={isSigned}
          isConfirmed={user?.is_confirmed}
          hasSubscribed={user?.subscription}
          path="/dashboard/planos"
          exact
          component={KultiviPlus}
        />
        <PrivateRoute
          isAuthenticated={isSigned}
          isConfirmed={user?.is_confirmed}
          path="/dashboard/course/:courseSlug/lesson/:lessonSlug"
          component={Lesson}
        />
        <PrivateRoute
          isAuthenticated={isSigned}
          isConfirmed={user?.is_confirmed}
          path="/dashboard"
          component={Dashboard}
        />
        <PublicRoute path="/error" component={InternalServerError} />
        <PublicRoute path="*" component={NotFound} />
      </Switch>
    )
  );
};
