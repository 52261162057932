import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContentText,
  Box,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";

import accountApi from 'app/services/account-api';

import Lottie from "react-lottie";
import checkLottie from "lotties/check";

import { useAuth } from "app/contexts/AuthContext";
import { useSnackbar } from "app/contexts/SnackbarContext";
import { useStyles } from "./styles";
import { formatDate } from "app/utils";

export default (props) => {
  const classes = useStyles();
  const { user, refreshUserInfo } = useAuth();
  const { setSnackValues } = useSnackbar();

  const { open, toggleDialog, subscription, reloadSubscriptions, subscriptions } = props;

  // estados de processamento
  const [suspending, setSuspending] = useState(false);
  const [suspended, setSuspended] = useState(false);

  const suspendSubscription = () => {
    setSuspending(true);
    accountApi
      .post(`subscriptions/${subscription?.subscriptionId}/suspend`)
      .then(async (response) => {
        setSuspended(true);
        const hasActiveSubscriptions = !!subscriptions
          ?.filter(subs => subs.id !== subscription.id)
          ?.find((subs) => subs.status === 'ACTIVE')

        refreshUserInfo({ has_active_subscriptions: hasActiveSubscriptions });
        reloadSubscriptions(true);
      })
      .catch((error) => {
        setSnackValues({
          message:
            error.response.data?.message ?? "Falha ao suspender assinatura",
          open: true,
        });
      })
      .finally(() => {
        setSuspending(false);
      });
  };

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        open={open}
        fullWidth={true}
        onClose={() => (!suspending ? toggleDialog(false) : null)}
      >
        {!suspending ? (
          !suspended ? (
            <>
              <DialogTitle
                id="form-dialog-title"
                className={classes.title}
                disableTypography
              >
                <Box component="div" display="flex" justifyContent="flex-end">
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => toggleDialog(false)}
                    disabled={suspending}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                {!suspended && "Suspender assinatura?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {user.firstname}, com a sua assinatura você ajuda a gente a
                  levar a educação gratuita pra milhões de estudantes no país
                  todo.
                </DialogContentText>
                <DialogContentText>
                  Caso realmente precise cancelar, atente-se às informações a
                  seguir:
                </DialogContentText>
                <List dense={true}>
                  <ListItem>
                    <ListItemIcon>
                      <ErrorOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Após confirmação, mais nenhuma nova cobrança será emitida." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ErrorOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Você ainda poderá usufruir dos benefícios do plano até ${
                        subscription
                          ? formatDate(subscription.dueDate)?.split(' ')?.at(0)
                          : "a data final do período contratado"
                      }.`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ErrorOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Para confirmar, basta clicar em "Sim, suspender".`}
                    />
                  </ListItem>
                </List>
                <DialogActions>
                  <Button
                    onClick={() => toggleDialog(false)}
                    disabled={suspending}
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={suspendSubscription}
                    className={classes.buttonDelete}
                    disabled={suspending || !subscription?.subscriptionId}
                  >
                    Sim, suspender
                  </Button>
                </DialogActions>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogContent>
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  mb={2}
                >
                  <Lottie
                    style={{
                      maxWidth: 300,
                      padding: 0,
                      margin: 0,
                    }}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: checkLottie,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                  <DialogContentText>
                    Atendendo ao seu pedido, suspendemos sua assinatura.
                    Esperamos que você tenha gostado do serviço, e para nós será
                    um prazer ter você como parte do Kultivi+ novamente no
                    futuro.
                  </DialogContentText>
                  <DialogContentText>
                    Não esqueça que você ainda pode continuar realizando os
                    cursos da Kultivi de forma 100% gratuita.
                  </DialogContentText>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => toggleDialog(false)}>Fechar</Button>
              </DialogActions>
            </>
          )
        ) : (
          <DialogContent>
            <Box
              component="div"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={300}
            >
              <Box mb={4}>
                <CircularProgress size={70} />
              </Box>
              <Typography className={classes.suspendingText}>
                Aguarde enquanto suspendemos sua assinatura
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
