import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ads: {
    textAlign: 'center',
    fontSize: 10,
    marginBottom: 10
  }
}))

export { useStyles }
