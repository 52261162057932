import React, {useEffect, useState, useCallback} from 'react'
import { useHistory } from 'react-router-dom'
import coreApi from "app/services/core-api";

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Hidden,
  Link,
  Typography,
  Paper,
  InputBase,
  Container,
} from '@material-ui/core'

// Icons
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

// Cards
import CourseCard from 'app/components/cards/Course/model-3'
import SkeletonCourse from 'app/components/cards/Course/model-3/skeleton'

import Skeleton from '@material-ui/lab/Skeleton';
import {AdsTop, AdsIncontent, VideoAdsIncontent} from "app/components/ads/freestar";

// Lotties animations
import Lottie from 'react-lottie';
import GhostLottie from 'lotties/ghost'

import { useFormik } from 'formik'
import { useStyles } from './styles'

export default (props) => {

  const history = useHistory()
  const classes = useStyles()

  // Entities
  const [categories,setCategories] = useState([])
  const [courses, setCourses] = useState([])

  // Loading states
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [loading, setLoading] = useState(true);

  // Local rules
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)

  const [configRestCourses, setConfigRestCourses] = useState({
    append: false, // Se false renderiza cursos do zero; Se true 'carregar mais'
    page: 1,
    size: 12,
    sort: '',
    filters: {
      label: props.match.params?.category // Caso usuario acesse a URL diretamente com slug, seta estado inicial
    }
  })

  // Carrega categorias
  useEffect(() => {

    coreApi.get(`/categories`).then(response => {
      setCategories(response.data)
    }).catch(error => {
      console.warn(error)
    }).finally( () => {
      setLoadingCategories(false)
    })

  }, [])

  useEffect( () => {

    if(!configRestCourses.append){
      setCourses([])
    }

    setLoading(true)

    var filters = [];
    /** Monta filtros como querystring */
    for(var prop in configRestCourses.filters) {
      const el = configRestCourses.filters[prop];
      if(el) {
        filters.push(`${prop}=${el}`)
      }
    }

    const query = filters.length > 0 ? `&${filters.join("&")}` : ""

    coreApi.get(`discovery-units?context=course&page[number]=${configRestCourses.page}&page[size]=${configRestCourses.size}&sort=${configRestCourses.sort}${query}`)
    .then(response => {

      if(configRestCourses.append) {
        setCourses(prev => [...prev,...response.data.items])
      } else {
        setCourses(response.data.items)
      }
      setIsLastPage(response.data.lastPage === response.data.page || response.data.lastPage === 0);
    })
    .catch(error => {
      console.warn(error)
    })
    .finally( () =>
      setLoading(false)
    );

  },[configRestCourses])


  // Atualiza URL e parâmetros para busca na API
  const handleCategory = useCallback( (slug) => {
    const link = slug ? `/dashboard/discover/${slug}` : `/dashboard/discover`
    history.push(link)
    setConfigRestCourses({
      ...configRestCourses,
      page:1,
      sort: '',
      append: false,
      filters:{
        label: slug,
      }
    })
    searchForm.resetForm({search:""})

  },[history,configRestCourses])

  /**
   *
   * Reponsável por indicar ao useEffect de cursos qual
   * a paginação e se deve manter cursos adicionando novos
   * ou refazer a lista. */
  const handleMore = () => {
    setConfigRestCourses({
      ...configRestCourses,
      page:configRestCourses.page+1,
      append: true,
    })
  }


  /**
   *
   * Reponsável por indicar ao useEffect de cursos busca
   * do campo livre. */
  const handleSearch = (search) => {
    const filters = {...configRestCourses.filters,search:search}

    setConfigRestCourses({
      ...configRestCourses,
      append: false,
      page: 1,
      filters: filters,
    })
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen({ ...drawerOpen, [anchor]: open });
  };

  const searchForm = useFormik({
    initialValues: {
      search: ""
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      handleSearch(values.search)
    }
  })

  function FiltersForm() {

    return (
      <React.Fragment>
        <List subheader={<ListSubheader disableSticky disableGutters className={classes.filterCategory}>Categorias</ListSubheader>}>
          {loadingCategories ? (
            [0,1,2,3,4,5].map( (item,key) => {
              return (
                <Skeleton height={30} key={key} />
              )
            })
          ) : (
            categories.map(category => {
              const labelId = `checkbox-list-category-${category.id}`;
              return (
                <ListItem
                  button
                  component="a"
                  key={`cat-${category.id}`}
                  onClick={ () => {
                    handleCategory(category.slug)
                    setDrawerOpen(false)
                  }}
                  disabled={configRestCourses?.filters?.label === category.slug}
                >
                  <ListItemText id={labelId} primary={`${category.title}`} />
                </ListItem>
              )
            })
          )}
        </List>
      </React.Fragment>
    )
  }

  function ShowAll() {

    const showingCategory = configRestCourses.filters?.label
    return (
      <Box component="div" pt={4} mb={2}>
        <Link
          disabled={!showingCategory}
          component="button"
          className={classes.showAll}
          onClick={() => handleCategory(null)}
        >Mostrar todos os cursos</Link>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Box component="div" mb={3}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Hidden smDown>
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <ShowAll />
                <FiltersForm />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={9} lg={10}>
              <Hidden mdUp>
                <Box component="div" mb={2} textAlign="right">
                  <Button
                    className={classes.buttonFilter}
                    onClick={toggleDrawer(drawerOpen, false)}
                    startIcon={<FilterListIcon />}
                    size="small"
                    variant="contained"
                  >Filtrar</Button>
                </Box>
                <ShowAll />
              </Hidden>
              <Box component="div" mb={3} display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                <Typography component="h1" className={classes.exploringTitle}>
                  {!loadingCategories ?
                    (
                      configRestCourses?.filters?.label && categories ?
                        `Cursos de ${categories.find( (item) => item.slug === configRestCourses.filters.label)?.title}` : "Todos os cursos"
                    ) : '' }
                </Typography>
                {/* <Box component="div" mt={{xs:1,sm:0}}>
                  <Button
                    className={classes.buttonFilter}
                    aria-controls="sort-menu"
                    aria-haspopup="true"
                    size="small"
                    variant="contained"
                    onClick={handleClickSort}
                    startIcon={<SortIcon />}
                  >Ordenar</Button>
                  <Menu
                    keepMounted
                    id="sort-menu"
                    anchorEl={sortEl}
                    open={Boolean(sortEl)}
                    onClose={handleCloseSort}
                  >
                    <MenuItem onClick={()=>handleSort("latest")}>
                      {configRestCourses.sort === "latest" ? (
                        <>
                          <ListItemIcon>
                            <CheckIcon fontSize="small" />
                          </ListItemIcon>
                          Novos
                        </>
                      ) : "Novos"}
                    </MenuItem>
                    <MenuItem onClick={()=>handleSort("popular")}>
                      {configRestCourses.sort === "popular" ? (
                          <>
                            <ListItemIcon>
                              <CheckIcon fontSize="small" />
                            </ListItemIcon>
                            Populares
                          </>
                        ) : "Populares"}
                    </MenuItem>
                    <MenuItem onClick={()=>handleSort("featured")}>
                      {configRestCourses.sort === "featured" ? (
                          <>
                            <ListItemIcon>
                              <CheckIcon fontSize="small" />
                            </ListItemIcon>
                            Destaques
                          </>
                        ) : "Destaques"}
                    </MenuItem>
                  </Menu>
                </Box> */}
              </Box>
              <Box component="div" mb={4}>
                <AdsTop />
              </Box>
              <Box component="div" display="flex" mb={3}>
                <Paper component="form" className={classes.searchPaper} elevation={0} onSubmitCapture={searchForm.handleSubmit}>
                  <InputBase
                    className={classes.inputSearch}
                    placeholder="Buscar cursos"
                    inputProps={{ 'aria-label': 'buscar cursos' }}
                    id="search"
                    name="search"
                    value={searchForm.values.search}
                    onChange={searchForm.handleChange}
                    type="search"
                  />
                  <IconButton className={classes.iconButton} aria-label="search" onClick={searchForm.handleSubmit}>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Box>
              {!loading && courses.length < 1 && (
                <Box component="div" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <Lottie
                    style={{
                      maxWidth: 400,
                      padding: 0,
                      margin: 0,
                    }}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: GhostLottie,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                      }
                    }}
                  />
                  <Typography component="span" className={classes.emptyText}>Nenhum curso encontrado</Typography>
                </Box>
              )}
              <Grid container spacing={2} direction="row" alignItems="stretch">
                {courses.map( (course,index) => {
                  return (
                    <React.Fragment key={index}>
                      <Hidden smDown>
                        {
                          ( index > 6 && index%6 === 0 && (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                          ))
                        }
                      </Hidden>
                      <Grid item xs={12} sm={6} md={6} lg={4} key={course.id}>
                        <CourseCard key={course.id} {...course} showSummary={true} showEnrollments={true}/>
                      </Grid>
                      <Hidden smDown>
                        {index > 0 && index === 5 && (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                        )}
                      </Hidden>
                      <Hidden mdUp>
                        {index > 0 && index === 1 ? (
                            <Grid item xs={12}>
                              <Box component="div" mb={4}>
                                <AdsIncontent />
                              </Box>
                            </Grid>
                        ) : ( index > 2 && index%2 !== 0 && (
                              <Grid item xs={12}>
                                <Box component="div" mb={4}>
                                  <AdsIncontent />
                                </Box>
                              </Grid>
                            )
                        )}
                      </Hidden>
                    </React.Fragment>
                  )
                })}
                {loading && (
                  [0,1,2,3,4,5].map( (item,key) => {
                    return (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={key}>
                        <SkeletonCourse key={`sk-${key}`} />
                      </Grid>
                    )
                  }))
                }
              </Grid>
              { !loading && !isLastPage && (
                <Box display="flex" component="div" justifyContent="center" my={3}>
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={handleMore}
                  >Carregar mais</Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(drawerOpen, false)}>
        <Box component="div" px={2}>
          <Box component="div" width="100%" display="flex" py={1} justifyContent="flex-end">
            <IconButton onClick={() => setDrawerOpen(false)}><CloseIcon /></IconButton>
          </Box>
          <FiltersForm />
        </Box>
      </Drawer>
    </React.Fragment>
  )
}
