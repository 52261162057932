import React, {useState,useRef, useEffect} from 'react';
import coreApi from 'app/services/core-api';

import useOnScreen from 'hooks/useOnScreen'

import EbookCard from 'app/components/cards/Ebook/model-1'
import Skeleton from 'app/components/cards/Ebook/model-1/skeleton'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useStyles } from './styles'

export default (props) => {

    const { feature } = props

    const classes = useStyles();

    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const [ebooks, setEbooks] = useState([])
    const [loadingEbooks, setLoadingEbooks] = useState(true)

    const fetchData = () => {
        const hasEbooks = JSON.parse(sessionStorage.getItem(`@Kultivi:ebooks:${feature}`))
        if (hasEbooks) {
          setEbooks(hasEbooks)
          setLoadingEbooks(false)
          return
        }
        coreApi.get(`discovery-units?type=1&context=ebook&page[number]=1&page[size]=10&feature=${feature}`).then(response => {
          setEbooks(response.data.items)
          sessionStorage.setItem(`@Kultivi:ebooks:${feature}`, JSON.stringify(response.data.items))
        }).catch(error => {
            console.warn(error)
        }).finally(() => {
          setLoadingEbooks(false)
        })
    }

    useEffect( () => {

        function load() {
            if(isVisible) {
                if(ebooks.length > 0) {
                    return false;
                }
                fetchData();
            }
        }

        load()

    },[isVisible])

    const responsive = {
        large: {
            breakpoint: { max: 3000, min: 1441 },
            items: 4,
            partialVisibilityGutter: 12
        },
        desktop: {
            breakpoint: { max: 1440, min: 768 },
            items: 4,
            partialVisibilityGutter: 24
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 2,
            partialVisibilityGutter: 24
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    }

    return (
        <div ref={ref}>
            <Carousel
                itemClass={classes.carouselItem}
                responsive={responsive}
                infinite={false}
                arrows={!loadingEbooks}
                partialVisible
            >
                {loadingEbooks ?
                    [0, 1, 2, 3, 4, 5].map((key) => {
                        return <Skeleton key={key} />
                    }) : ebooks.map((ebook, item) => (
                        <EbookCard key={item} {...ebook} />
                    ))
                }
            </Carousel>
        </div>
    );
}
