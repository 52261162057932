import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(32),
    marginBottom: theme.spacing(1),
    fontWeight: 800
  },
  category: {
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(2),
    fontWeight: 800,
    color: theme.palette.grey[500]
  },
  cardAnswer: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom:0
    }
  },
  content: {
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 400,
      },
    }
  },
  checked: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.getContrastText(theme.palette.grey[600]),
  },
  unChecked: {
    backgroundColor: "#FFF",
    color: theme.palette.getContrastText("#FFF"),
  },
  correctAnswer: {
    backgroundColor: theme.palette.primary['main'],
    color: "#FFF",
  },
  wrongAnswer: {
    backgroundColor: theme.palette.error['main'],
    color: "#FFF",
  },
  cardEvaluation: {
    width: 400,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: "50%",
      maxWidth: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textAlign: "center",
  },
  evaluationEmoji: {
    width: 50,
  },
  evaluationTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: 600,
  },
  evaluationResult: {
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 800,
  },
  evaluationCorrect: {
    color: theme.palette.primary['main']
  },
  evaluationWrong: {
    color: theme.palette.error['main']
  },
  resultsTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(24),
    textAlign: "center",
  }
}))

export { useStyles }
