import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  titleNoCertificates: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(30),
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  sectionTitle: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
    fontWeight: 700
  },
  coursesAvailable: {
    fontWeight: 700,
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2)
  },
  filterCategory: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2)
  },
  drawer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonFilter: {
    backgroundColor: "#FFF",
    boxShadow: '0px 0px 4px rgba(34, 40, 43, 0.16)',
  },
  lockedInfo: {
    '& .title': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(1),
    }
  },

  incompleteBox: {
    width: '100%',
    paddingTop: theme.spacing(2),
    '& .tipBox': {
      '& .tip': {
        fontWeight: 700,
        marginBottom: theme.spacing(.5),
        fontSize: theme.typography.pxToRem(14),
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
      }
    }
  },
  certificate: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& > .thumbnail': {
      display: 'flex',
      flexDirection: 'row',
      width: 200,
      minHeight: 100,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(2),
        flexDirection: 'column',
        height: 150,
        width: '100%',
      },
    },
    '& > .content': {
      display: 'flex',
      flexDirection: 'column',
      '& .title': {
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(18),
      },
      '& .info': {
        color: theme.palette.grey['600'],
        fontSize: theme.typography.pxToRem(12),
      },
    },
  }
}))

export { useStyles }
