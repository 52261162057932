import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    backgroundColor: " #292929",
    padding: "5rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem 1rem",
    },
  },
  title: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(27),
    fontWeight: "bold",
    marginBottom: "3rem",
    textTransform: "uppercase",
    color: "#FEF080",
    fontFamily: "Raleway, sans-serif",
  },
  accordion: {
    marginBottom: "0.1rem",
  },
  active: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(20),
  },
  heading: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(20),
  },
  accordionSummary: {
    borderRadius: "0.5rem",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  accordionDetails: {
    flexDirection: "column",

    "&:expanded": {
      backgroundColor: "red",
    },
  },

  text: {
    fontSize: theme.typography.pxToRem(18),
    margin: "0.5rem",
  },
  step: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentStep: {
    marginBottom: "2rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentTitle: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.grey[800],
  },
  image: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listPaths: {
    marginTop: "1rem",
  },
  listItem: {
    display: "flex",
  },
  titleItem: {
    fontWeight: "bold",
    marginRight: "0.5rem",
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    borderRadius: "0.5rem",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
}))(MuiAccordionDetails);

export { useStyles, AccordionSummary, Accordion, AccordionDetails };
