import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  carouselItem: {
    paddingLeft: theme.spacing(.5),
    paddingRight: theme.spacing(.5),
    paddingBottom: 6,
  },
  link: {
    display: "block",
    width: "100%",
    cursor: "pointer",
  },
  carouselImg: {
    width: "100%"
  },
}))

export { useStyles }
