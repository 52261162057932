import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import accountApi from 'app/services/account-api';
import { useStyles } from "./styles";

import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  Button,
  Tabs,
  Tab,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';

// Lotties animations
import Lottie from "react-lottie";
import GhostLottie from "lotties/ghost";

// Forms
import FormAccount from "app/components/forms/Account";
import FormPassword from "app/components/forms/Password";

import DialogDeleteAccount from "app/components/Modals/Auth/DeleteAccount";
import DialogSuspendSubscription from "app/components/Modals/Subscription/SuspendSubscription";
import DialogInvoices from "app/components/Modals/Subscription/Invoices";
import { formatDate } from "app/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display="flex" flexGrow={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default (props) => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [reloadSubscriptions, setReloadSubscriptions] = useState(false);

  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const handleSubscription = useCallback((subscription) => {
    setSubscription((state) => subscription);
  }, []);

  const [openDialogDeleteAccount, setOpenDialogDeleteAccount] = useState(false);
  const toggleDialogDeleteAccount = (open) => {
    setOpenDialogDeleteAccount((state) => open);
  };

  const [openDialogSuspendSubscription, setOpenDialogSuspendSubscription] = useState(false);
  const toggleDialogSuspendSubscription = (open, subscription) => {
    setSubscription(subscription);
    setOpenDialogSuspendSubscription((state) => open);
  };

  const [openDialogInvoices, setOpenDialogInvoices] = useState(false);
  const toggleDialogInvoices = (open) => {
    setOpenDialogInvoices((state) => open);
  };

  useEffect(() => {
    setLoadingSubscription(true);
    accountApi
      .get(`/subscriptions`)
      .then((response) => {
        setSubscriptions(response.data);
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setLoadingSubscription(false);
      });
  }, [reloadSubscriptions]);

  const subscriptionStatus = (subscription) => {
    const dueDate = formatDate(subscription.dueDate)?.split(' ')?.at(0);

    if (subscription.status === 'ACTIVE') {
			return `Sua próxima data de cobrança é: ${dueDate}`;
		}

		if (subscription.status === 'SUSPENDED') {
      if(subscription.dueDate && new Date(subscription.dueDate) > new Date()) {
        return `Utilize os benefícios do plano até: ${dueDate}`;
      }

      return `Sua assinatura foi cancelada em: ${dueDate}`;
		}

		if (subscription.status === 'CANCELLED') {
			return `Sua assinatura foi cancelada em ${dueDate}`;
		}

		if (subscription.status === 'ERROR') {
			return `Houve um erro ao processar sua assinatura :(`;
		}

    return `Aguardando o pagamento da sua fatura`;
  }

  const subscriptionIcon = (subscription) => {
    if (subscription.status === 'ACTIVE') {
      return <AutorenewIcon color="primary" />
		}

		if (subscription.status === 'SUSPENDED') {
      if(subscription.dueDate && new Date(subscription.dueDate) > new Date()) {
        return <AccessTimeIcon color='disabled' />
      }

      return <CloseIcon color='disabled' />
		}

		if (subscription.status === 'CANCELLED') {
			return <CloseIcon color='disabled' />
		}

		if (subscription.status === 'ERROR') {
			return <ErrorOutlineIcon color='disabled' />
		}

		return <AccessTimeIcon color='disabled' />
  }

  return (
    <React.Fragment>
      <Container>
        <Hidden mdUp>
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={handleChange}
            aria-label="Menu"
            className={classes.tabs}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab className={classes.tabLabel} label="Conta" {...a11yProps(0)} />
            <Tab
              className={classes.tabLabel}
              label="Assinatura e Cobrança"
              {...a11yProps(1)}
            />
            <Tab
              className={classes.tabLabel}
              label="Excluir Conta"
              {...a11yProps(2)}
            />
          </Tabs>
        </Hidden>
        <Box display="flex" flexDirection="row" mb={4}>
          <Hidden smDown>
            <Tabs
              orientation="vertical"
              variant="standard"
              value={value}
              onChange={handleChange}
              aria-label="Menu"
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab
                className={classes.tabLabel}
                label="Conta"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabLabel}
                label="Assinatura e Cobrança"
                {...a11yProps(1)}
              />
              <Tab
                className={classes.tabLabel}
                label="Excluir Conta"
                {...a11yProps(2)}
              />
            </Tabs>
          </Hidden>
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    gutterBottom
                    component="h2"
                    className={classes.cardTitle}
                  >
                    Alterar e-mail
                  </Typography>
                  <FormAccount />
                </CardContent>
                <Divider />
                <CardContent>
                  <Typography
                    gutterBottom
                    component="h2"
                    className={classes.cardTitle}
                  >
                    Alterar senha
                  </Typography>
                  <FormPassword />
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    gutterBottom
                    component="h2"
                    className={classes.cardTitle}
                  >
                    Assinaturas
                  </Typography>
                  {
                    subscriptions.length > 0 ?
                      (
                        <List>
                          {subscriptions.map((subscription, key) => (
                            <ListItem key={`subscription_${key}`}>
                              <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'transparent' }}>
                                  {subscriptionIcon(subscription)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={subscription.product.name || '-'}
                                secondary={
                                  <Box mt={1}>
                                    {subscriptionStatus(subscription)}
                                    <Typography style={{fontSize: 10}}>GERENCIADO POR: {subscription.gateway === 'IUGU' ? 'KULTIVI' : subscription.gateway}</Typography>
                                    <Box mt={1}>
                                      {subscription.gateway === 'IUGU' && subscription.status === 'ACTIVE' && (
                                        <Button variant='text' size="small" onClick={() => toggleDialogSuspendSubscription(true, subscription)}>SUSPENDER ASSINATURA</Button>
                                      )}
                                      {subscription.gateway === 'KIWIFY' && (
                                        <Button color='primary' variant='text' size="small" onClick={() => window.open(subscription.managementLink, '_blank')}>GERENCIAR ASSINATURA</Button>
                                      )}
                                      {subscription.gateway === 'STRIPE' && (
                                        <Button variant='text' size="small" onClick={() => window.open(subscription.managementLink, '_blank')}>GERENCIAR ASSINATURA</Button>
                                      )}
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <>
                          <Box
                            component="div"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Lottie
                              style={{
                                maxWidth: 250,
                                padding: 0,
                                margin: 0,
                              }}
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: GhostLottie,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                            />
                            <Typography
                              component="h3"
                              className={classes.klubiTitleStatus}
                            >
                              Nenhuma assinatura disponível
                            </Typography>
                          </Box>
                        </>
                      )
                  }
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tabPanel}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Card className={classes.card}>
                <CardContent>
                  <Typography
                    gutterBottom
                    component="h2"
                    className={classes.cardTitle}
                  >
                    Deletar conta
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    className={classes.cardSubtitle}
                  >
                    Antes de deletar sua conta, analise o seguinte:
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <ErrorOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="Se você excluir sua conta, todas as suas informações pessoais, incluindo inscrições, progressos de curso e certificados, serão excluídos. Você perderá imediatamente o acesso a ela e não poderá fazer login." />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ErrorOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="Esta ação é irreversível - uma vez que seus dados foram excluídos, não podemos recuperá-los novamente." />
                    </ListItem>
                  </List>
                  <Box component="div" mt={2}>
                    <Button
                      color="primary"
                      onClick={() => {
                        toggleDialogDeleteAccount(true);
                      }}
                    >
                      Excluir conta
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
        </Box>
      </Container>
      <DialogDeleteAccount
        open={openDialogDeleteAccount}
        toggleDialog={toggleDialogDeleteAccount}
      />
      <DialogSuspendSubscription
        open={openDialogSuspendSubscription}
        toggleDialog={toggleDialogSuspendSubscription}
        subscription={subscription}
        subscriptions={subscriptions}
        reloadSubscriptions={setReloadSubscriptions}
      />
      <DialogInvoices
        open={openDialogInvoices}
        toggleDialog={toggleDialogInvoices}
      />
    </React.Fragment>
  );
};
