/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendPulsePopUp } from 'scripts/send-pulse'
import useQuery from './useQuery';

export default function UseLocationChange () {
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    if (query.has("ref")) {
      localStorage.setItem("@Kultivi:ref", query.get("ref"));
    }
  },[location]);


  useEffect(() => {
    let scopes = [];

    try {
      const user = JSON.parse(localStorage.getItem("@Kultivi:user"));
      scopes = user?.scopes || [];
    } catch (error) {
      //
    }

    if(!scopes.includes("student_no_ads_display")) {
      const popUp = sendPulsePopUp();
      document.getElementsByTagName("head")[0].appendChild(popUp);
      return () => {
        document.getElementsByTagName("head")[0].removeChild(popUp);
      }
    }

  }, [location])

  return null;
}
