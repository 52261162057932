import React from "react";
import YouTube from "react-youtube";

// Styles files
import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();

  const {
    lesson,
    finishVideo,
    setLoadingVideo,
    setVideoDone,
    setVideoApi,
  } = props;

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
      controls: 2,
      color: "white",
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  };

  function _onReady(event) {
    setLoadingVideo(false);
    setVideoApi({
      play: () => event.target.playVideo(),
      setPosition: (time) => event.target.seekTo(time),
      getCurrentTime: () => event.target.getCurrentTime(),
    });
  }

  function _onEnd(event) {
    setVideoDone(true);
    finishVideo();
  }

  function _onStateChange(event) {
    //
  }

  return (
    <React.Fragment>
      <YouTube
        videoId={lesson.videoCode}
        opts={opts}
        containerClassName={`${classes.videoWrapperContainer}`}
        className={`${classes.videoWrapper}`}
        onReady={_onReady}
        onStateChange={_onStateChange}
        onEnd={_onEnd}
      />
    </React.Fragment>
  );
};
