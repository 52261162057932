import styled, { keyframes } from 'styled-components'

import BackgroundImg from 'assets/images/signup_bg.png';

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  leftContent: {
    backgroundColor: "#FFF",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4)
    }
  },
  formArea: {
    maxWidth: "70%",
    minHeight: "100vh",
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      maxWidth: "80%",
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  copySignUp: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400
  },
  ctaSignUp: {
    fontWeight: 700
  },
  rightContent: {
    backgroundColor: theme.palette.secondary['dark'],
    background: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      marginBottom: theme.spacing(2)
    },
  },
  logo: {
    width: 130,
    maxWidth: 130,
    [theme.breakpoints.only('xs')]: {
      width: "100%",
      maxWidth: 154
    },
  }
}))

export { useStyles }

// Use Styled Components
const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div`
  animation: ${apearFromLeft} 1s
`;
