import React, { createContext, useEffect, useState, useContext } from 'react';

import { useAuth } from 'app/contexts/AuthContext'

const SubscriptionContext = createContext({})

export const SubscriptionProvider = ({ children }) => {

  const {user} = useAuth();
  /**
   * Quando TRUE, não deixa redirecionar o usuário pra página de conversão após o checkout completo.
   * Utilizado em routes/guards/CheckoutRoute */
  const [suspendCheckoutGuardVerification, setSuspendCheckoutGuardVerification] = useState(false)
  const [subscription, setSubscription] = useState(null)

  useEffect( () => {

    if(user) {
      setSubscription(user.subscription);
    }

  }, [user]);

  return (
    <SubscriptionContext.Provider value={{
      subscription,
      suspendCheckoutGuardVerification,
      setSuspendCheckoutGuardVerification,
    }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export function useSubscription() {
  const context = useContext(SubscriptionContext)

  if (!context) {
    throw new Error('useSubscription must be used within an SubscriptionProvier')
  }

  return context;
}
