import React, {useEffect, useState, useRef} from 'react'
import coreApi from 'app/services/core-api';
import useOnScreen from 'hooks/useOnScreen'

import {
  Box,
} from '@material-ui/core';

import CourseCard from 'app/components/cards/Course/model-3'
import Skeleton from 'app/components/cards/Course/model-3/skeleton'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Styles files
import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = () => {
    coreApi.get(`discovery-units?context=course&label=${props.category}&page[number]=1&page[size]=10`)
    .then(response => {
      setCourses(response.data.items)
    }).catch(error => {
      console.warn(error)
    }).finally( () => {
      setLoading(false)
    })
  }

  useEffect( () => {

    function load() {
      if(isVisible) {
          if(courses.length > 0) {
              return false;
          }
          fetchData();
      }
    }
    load()

  },[isVisible])

  const responsive = {
      large: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      }
  }

  return (
    <Box ref={ref} component="div">
      <Carousel
        itemClass={classes.carouselItem}
        responsive={responsive}
        arrows={!loading}
        partialVisible
      >
        {loading ?
          [0,1,2,3,4,5].map( (key) => {
            return <Skeleton key={key} />
          }) :
          courses.map((course) => (
            <CourseCard key={course.id} {...course} showSummary={false} />
          ))
        }
      </Carousel>
    </Box>
  )
}
