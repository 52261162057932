import React, { useEffect, useState, useCallback } from 'react'

// Material UI Imports
import {
  Box,
  CssBaseline,
  Typography,
  Grid,
  Link,
  CircularProgress,
} from '@material-ui/core'

// Assets import
import Logo from 'assets/images/kultivi.png'

// Styles files
import { useStyles, AnimationContainer } from './styles'
import { useAuth } from 'app/contexts/AuthContext'
import { useSnackbar } from "app/contexts/SnackbarContext";

import ConfirmAccount from "app/components/forms/ConfirmAccount";
import DialogChangeUsername from "app/components/Modals/Auth/ChangeUsername";

import accountApi from "app/services/account-api";
import { getUtcTimestamp } from "helpers/date";

import Lottie from 'react-lottie';
import animationData from 'lotties/check'

const LIMIT = 90;

export default (props) => {

  const classes = useStyles()
  const { user, signOut, refreshUserInfo } = useAuth()
  const { setSnackValues } = useSnackbar();

  const [countdown, setCountdown] = useState(90);
  const [canResendMail, setCanResendMail] = useState(false);
  const [sending, setSending] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [accountConfirmed, setAccountConfirmed] = useState(false);
  const [attemps, setAttemps] = useState(0);
  const [email, setEmail] = useState(null)

  const [openChangeUsernameDialog, setOpenChangeUsernameDialog] =
  useState(false);
const handleChangeUsernameDialog = (open) => (event) => {
  setOpenChangeUsernameDialog(open);
};

  useEffect(() => {
    if(!user.email_confirmation_sent_at) {
      setCanResendMail(true)
      return;
    }
    const utcTimestamp = getUtcTimestamp();
    const sentAt = getUtcTimestamp(user.email_confirmation_sent_at);
    const diff = Math.floor((utcTimestamp - sentAt) / 1000);
    if(diff > LIMIT) {
      setCanResendMail(true)
      return;
    }
    setCountdown(LIMIT - diff)

    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mailSent, user]);

  useEffect(() => {
    if (countdown === 0 && !canResendMail) {
      setCanResendMail(true)
    }
  }, [countdown, canResendMail]);

  useEffect(() => {
    if(accountConfirmed) {
      setTimeout(() => {
        refreshUserInfo({is_confirmed: true})
      }, 1500)
    }
  }, [accountConfirmed, refreshUserInfo])

  const handleSignout = useCallback(async () => {
    await signOut();
  }, [signOut]);

  const handleResendMail = useCallback(async () => {
    setSending(true);
    accountApi.post('/me/resend-email-confirmation')
    .then((result) => {
      refreshUserInfo({email_confirmation_sent_at: new Date(new Date().toUTCString())})
      setCanResendMail(false)
      setMailSent(true)
      setSnackValues({
        message: "E-mail encaminhado com sucesso!",
        open: true,
      });
    })
    .catch((err) => {
      const {response} = err
      setMailSent(false)
      setCanResendMail(true)
      setSnackValues({
        message: response?.data?.errors?.at(0) || "Ops! Falha ao reenviar e-mail. Tente novamente.",
        open: true,
      });
    })
    .finally(() => {
      setSending(false)
    })
  }, [refreshUserInfo, setSnackValues]);

  function Header() {
    return (
      <Box className={classes.header}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          mb={2}
        >
          <Box component="div" flexGrow={1} className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt="Logo Kultivi" />
          </Box>
        </Box>
      </Box>
    )
  }

  function Bottom() {
    return (
      <Box className={classes.bottomContent}>
        <Box mb={3}>
          <Typography component="p" style={{fontSize: '13px'}}>
            Agradecemos sua paciência e colaboração. Se você tiver alguma dúvida ou precisar de ajuda adicional, estamos aqui para te auxiliar. Não hesite em entrar em contato através do e-mail: suporte@kultivi.com
          </Typography>
        </Box>
        <Box><Typography>Para sair,  <Link onClick={handleSignout} className={classes.signOut}>clique aqui!</Link></Typography></Box>
      </Box>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.leftContent}>
          <CssBaseline>
            <AnimationContainer>
              <Box component="div" display="flex" flexDirection="column" mx="auto" className={classes.content}>
                <Header />
                {
                  accountConfirmed ?
                    (
                      <>
                        <Lottie
                          style={{
                            maxWidth: 400,
                            padding: 0,
                            margin: 0,
                          }}
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice"
                            }
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Box mb={3}>
                          <Box mb={2}>
                            <Typography component="p">Olá, {user.firstname}! <span role="img" aria-label=''>👋</span> </Typography>
                          </Box>
                          <Box mb={3}>
                            <Typography component="p">
                              Enviamos um código <u>ultrassecreto</u> para o email <strong>{user.email}</strong>. Você precisa digitá-lo apenas uma vez <span role="img">😊</span>
                            </Typography>
                          </Box>
                          <Box mx="auto" textAlign='center' className={classes.formBlock}>
                            {sending ? <CircularProgress /> : <ConfirmAccount setAccountConfirmed={setAccountConfirmed} setAttemps={setAttemps} />}
                          </Box>
                        </Box>
                        <Box mb={2}>
                          {
                            !sending &&
                            <Box mb={2}>
                              <Typography component="div" style={{fontSize: 14}}>
                                {">"} Caso não o tenha recebido, verifique na sua caixa de spam ou{" "}
                                <Typography component="span" style={{fontSize: 14}}>
                                  {
                                    !canResendMail && countdown > 0 ?
                                      <>aguarde {countdown} segundos para solicitar novamente.</> :
                                      <>
                                        <Link
                                          underline='always'
                                          color='secondary'
                                          onClick={handleResendMail}
                                          className={classes.resendMail}
                                        >clique aqui</Link> que faremos o reenvio para você.
                                      </>
                                  }
                                </Typography>
                              </Typography>
                            </Box>
                          }
                        </Box>
                        <Box mb={6}>
                          <Typography component="div" style={{fontSize: 14}}>
                            {">"} Caso o seu e-mail esteja incorreto,{" "}
                            <Typography component="span" style={{fontSize: 14}}>
                              <Link
                                underline='always'
                                color='secondary'
                                onClick={handleChangeUsernameDialog(true)}
                                className={classes.resendMail}
                              >clique aqui</Link>
                            </Typography>
                          </Typography>
                        </Box>
                        <Bottom />
                      </>
                    )
                }
              </Box>
            </AnimationContainer>
          </CssBaseline>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightContent}></Grid>
      </Grid>
      <DialogChangeUsername
        open={openChangeUsernameDialog}
        toggleDialog={handleChangeUsernameDialog}
        email={user.email}
      />
    </>
  )
}
