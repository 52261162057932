import facebook from "assets/images/facebook.svg";
import instagram from "assets/images/instagram.svg";
import email from "assets/images/email.svg";

const socialIcons = [
  {
    icon: facebook,
    alt: "Icone do facebook",
    path: "https://www.facebook.com/kultivieduk",
  },
  {
    icon: instagram,
    alt: "Icone do Instagram",
    path: "https://www.instagram.com/kultiviedu/",
  },
  {
    icon: email,
    alt: "Icone do Email",
    path: "mailto:atendimento.klubi@kultivi.com",
  },
];

export default socialIcons;
