import React from 'react'

import {
  Box,
  Hidden,
} from '@material-ui/core'

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { useStyles } from './styles'

const responsive = {
  large: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
}

export default (props) => {
  const { campaigns = []} = props

  const classes = useStyles()

  const goTo = ({link,target}) => {
    if(!link) {
      return;
    }

    window.open(`${link}`, target || '_blank');
  }

  return (

      <Carousel
        itemClass={classes.carouselItem}
        responsive={responsive}
        autoPlay={campaigns.length > 1}
        autoPlaySpeed={3000}
        infinite={campaigns.length > 1}
        focusOnSelect={true}
        arrows={campaigns.length > 1}
      >
        {campaigns.map((campaign, index) => (

          <Box component="div" display="flex" key={index}>
            <Hidden xsDown>
              <div
                key={campaign.id}
                onClick={() => goTo(campaign)}
                className={classes.link}
              >
                <img
                  className={classes.carouselImg}
                  src={campaign.photo}
                  alt="Banner de campanha"
                />
              </div>
            </Hidden>
            <Hidden smUp>
              <div
                key={campaign.id}
                onClick={() => goTo(campaign)}
                className={classes.link}
              >
                <img
                  className={classes.carouselImg}
                  src={campaign.photoMobile}
                  alt="Banner de campanha"
                />
              </div>
            </Hidden>
          </Box>
        ))}
      </Carousel>

  )
}
