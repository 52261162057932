import React from "react";

import { Paper, Box, Button } from "@material-ui/core";

import { useStyles, AnimationContainer } from "./styles";

export default ({ type, message }) => {
  const classes = useStyles();

  function PaymentPending({ message }) {
    return (
      <Paper
        elevation={0}
        className={[classes.root, classes.secondary].join(" ")}
      >
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          flexDirection={{ sm: "row" }}
          justifyContent={{ sm: "space-between" }}
          alignItems="center"
        >
          {message ?? "Olá, seu boleto ainda está pendente."}
        </Box>
      </Paper>
    );
  }

  function ProcessingPayment({ message }) {
    return (
      <Paper
        elevation={0}
        className={[classes.root, classes.secondary].join(" ")}
      >
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          flexDirection={{ sm: "row" }}
          justifyContent={{ sm: "space-between" }}
          alignItems="center"
        >
          {message ?? "Olá, ainda estamos processando seu pagamento"}
        </Box>
      </Paper>
    );
  }

  function SubscriptionRequiredBar({ message }) {
    return (
      <Paper
        elevation={0}
        className={[classes.root, classes.secondary].join(" ")}
      >
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          flexDirection={{ sm: "row" }}
          justifyContent={{ sm: "space-between" }}
          alignItems="center"
        >
          {message ??
            "Acelere com os benefícios e ferramentas exclusivas do Kultivi+"}
          <Button
            href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=notification-bar"
            target="_blank"
          >
            SAIBA MAIS
          </Button>
        </Box>
      </Paper>
    );
  }

  return (
    <AnimationContainer>
      {type === "subscriptionRequired" && (
        <SubscriptionRequiredBar message={message} />
      )}
      {type === "processingPayment" && <ProcessingPayment message={message} />}
      {type === "paymentPending" && <PaymentPending message={message} />}
    </AnimationContainer>
  );
};
