import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom:'6px',
    },
    title: {
        fontWeight: 800,
        color: "#FFF",
        fontSize: theme.typography.pxToRem(14),
    },
    icon: {
      color: "#FFF",
      fontSize: theme.typography.pxToRem(18),
    },
    content: {
        minHeight: 400,
        display: "flex",
        flexDirection: "column"
    },
}))

export { useStyles }
