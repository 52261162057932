import React,{useState} from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'

import studentApi from 'app/services/student-api'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';

import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()
  const {open,toggleDialog,position,toggleNote,note,course_id,lesson_id} = props;
  const [editorContent, setEditorContent] = useState("")
  const [loading, setLoading] = useState(false);

  const editorConfiguration = {
    toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
    heading: {
      options: [
        { model: 'paragraph', title: 'Parágrafo', class: 'ck-heading_paragraph' },
        { model: 'heading3', view: 'h3', title: 'Título', class: 'ck-heading_heading3' },
      ]
    },
    highlight: true,
    language: 'pt-br',
  };

  const submitNote = () => {

    const content = editorContent;
    if(note?.id) {
      const payload = {body: content};
      update(payload,note.id);
    } else {
      const payload = {
        body: content,
        position: parseInt(position)
      }
      store(payload);
    }

  };

  const update = (payload,id) => {

    var endpoint  = `subscribed-courses/${course_id}/lessons/${lesson_id}/notes/${id}`;
    setLoading(true);

    studentApi.patch(`${endpoint}`,payload)
    .then((response) => {
      toggleNote(id,response.data);
    })
    .catch((error) => {
      console.warn(error)
      setLoading(false);
    }).finally(() => {
      //
    })

  }

  const store = (payload) => {

    const endpoint  = `subscribed-courses/${course_id}/lessons/${lesson_id}/notes`;
    setLoading(true);

    studentApi.post(endpoint,payload)
    .then((response) => {
      toggleNote(null,response.data);
    })
    .catch((error) => {
      console.warn(error)
      setLoading(false);
    }).finally(() => {
      //
    })

  }

  return (
    <Dialog
      aria-labelledby="editor-note"
      open={open}
      fullWidth={true}
      onExited={ () => {
        setLoading(false);
        setEditorContent("");
      }}
    >
      <DialogTitle id="editor-note" className={classes.title} disableTypography>
        {!note ? 'Criar anotação' : 'Editar anotação'}
      </DialogTitle>
      <DialogContent>
        <CKEditor
          className={classes.ckEditor}
          editor={ ClassicEditor }
          config={ editorConfiguration }
          data={note ? note.body : ''}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
            editor.editing.view.change(writer => {
              writer.setStyle(
                "min-height",
                "100px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={ ( event, editor ) => {
            const data = editor.getData();
            setEditorContent(data);

          }}
          onBlur={( event, editor ) => {
            //
          }}
          onFocus={( event, editor ) => {
            //
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog(false)} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={submitNote} color="primary" disabled={loading}>
          {!loading ? 'Salvar' : <CircularProgress size={30} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
