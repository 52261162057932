import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  description: {
    width: "50%",
    textAlign: "center",
    padding: "5rem 0",
    color: "#0F5B7A",
    fontSize: "1.3rem",
    [theme.breakpoints.down("md")]: {
      width: "65%",
      padding: "5rem 0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export { useStyles };
