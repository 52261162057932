import React from 'react'

// FRAMEWORK IMPORTS
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@material-ui/core'

export default (props) => {

  const { open } = props;


  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogContent>
        
        <Box component="div" display="flex" flexDirection="column" alignItems="center" mb={2} py={3}>
          <Box mb={3}><CircularProgress /></Box>
          <DialogContentText>Aguarde! Estamos gerando seu simulado.</DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
