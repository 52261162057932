import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import socialIcons from "./data";

function Footer() {
  const classes = useStyles();
  const redirectForSocial = (path) => (window.location.href = path);

  return (
    <Box component="footer" className={classes.content}>
      <Typography variant="body1" className={classes.text}>
        Copyright© 2022 Kultivi - Produção e Edição de Conteúdo Ltda. Todos os
        direitos reservados. Feito com ❤ pelo time Kultivi
      </Typography>

      <Box className={classes.img}>
        {socialIcons.map((social, index) => (
          <img
            key={`Comunicação-${index}`}
            src={social.icon}
            alt={social.alt}
            onClick={() => redirectForSocial(social.path)}
            className={classes.image}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Footer;
