import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 900,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    }
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    }
  },
  faq: {
    width: '100%',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 800,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    '& h4': {
      marginBottom: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 700,

    },
    '& p': {
      marginBottom: theme.spacing(1.5),
    }
  },
  howItWorks: {
    width: '100%',
    maxWidth: 700,
    '& p': {
      marginBottom: theme.spacing(1.5),
    }
  },
  referralBox: {
    width: '100%',
    maxWidth: 700
  },
  referralBoxTitle: {
    color: theme.palette.grey[700],
    fontWeight: 800,
  },
  referralInfo: {
    color: theme.palette.grey[600]
  },
}))

export { useStyles }
