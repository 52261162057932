import React from 'react';
import { useHistory } from 'react-router-dom'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Chip,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import DoneIcon from '@material-ui/icons/Done';
import NotesIcon from '@material-ui/icons/Notes';
import HelpIcon from '@material-ui/icons/Help';

import { useStyles } from './styles'

export default (props) => {

    const classes = useStyles();
    const history = useHistory();

    const goToClass = (course,lesson) => {

        if(!course || !lesson) {
            return false;
        }
        history.push(`/dashboard/course/${course}/lesson/${lesson}`)
    }


    const id = `panel1a-header-${props.id}`
    const ariaControls = `panel-content-${props.id}`;
    const classesSeen = props.progress?.completedLessonIds ?? [];
    const enableLink = props.enableLink;

    function ClassTypeIcon( props ) {
        if(props?.seen)
          return <DoneIcon color="primary" />
        if(props.type === 1)
            return <PlayCircleFilledIcon />
        if(props.type === 2)
            return <NotesIcon />
        if(props.type === 3)
            return <HelpIcon />
    }

    return (
        <Accordion square className={classes.module} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={ariaControls}
                id={id}
                classes={{content: classes.accordionSummary}}
            >
                <Typography className={classes.heading} dangerouslySetInnerHTML={{ __html: props.title}}></Typography>
                <Box className={classes.secondaryHeading}><Chip label={props.time} /></Box>
            </AccordionSummary>
            <AccordionDetails className={classes.moduleDetails}>
                <List component="div" disablePadding className={classes.lessonsList} >
                    {props.lessons.map( (lesson,index) => {
                        return (
                            <ListItem disabled={!enableLink} key={index} button className={[classes.nested,classes.lessonItem].join(" ")} onClick={()=>goToClass(props.courseSlug,lesson.slug)}>
                                <ListItemIcon className={classes.lessonItemIcon}>
                                    <ClassTypeIcon seen={classesSeen.includes(lesson.id) ?? false}  {...lesson} />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.lessonTitle, secondary: classes.lessonSubtitle }}
                                    primary={<Typography component="span" dangerouslySetInnerHTML={{ __html: lesson.title}}></Typography>}
                                    secondary={lesson.time}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </AccordionDetails>
        </Accordion>
    );
}
