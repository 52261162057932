import React, {useState} from 'react'

import { useStyles } from './styles'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import {
  TextField,
  CircularProgress,
} from '@material-ui/core'

import { useSnackbar } from "app/contexts/SnackbarContext";
import accountApi from 'app/services/account-api';
import { confirmAccountByCodeValidator } from 'app/validators/auth'


const ConfirmAccount = (props) => {

  const { setSnackValues } = useSnackbar();
  const classes = useStyles()
  const [code, setCode] = useState('');
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState([]);

  const {
    setAccountConfirmed,
    setAttemps
  } = props


  const handleOnInput = async (e) => {
    setErrors([])
    const target = e.target
    const number = target.value.replace(/[^0-9]/g, '')
    setCode(number)
    try {
      const { code } = await confirmAccountByCodeValidator.validate({code: number})
      setSending(true)
      accountApi.post('/me/confirm', {token: code})
      .then((result) => {
        setAccountConfirmed(true)
      })
      .catch((err) => {
        setAttemps((value) => value + 1)
        setAccountConfirmed(false)
        const { response } = err
          setSnackValues({
            message: response?.data?.errors?.at(0) || 'Código inválido. Tente novamente',
            open: true,
          });
          setCode('')
      })
      .finally(() => setSending(false))

    } catch (error) {
      setErrors(error.errors)
    }
  }


  return (
    <>
      {
        sending ?
          <CircularProgress /> :
          <form name="form-confirm-account">
              <TextField
                autoFocus={true}
                label="Digite seu código de ativação"
                id="code"
                name="code"
                type="text"
                variant="outlined"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={code}
                onInput={handleOnInput}
                className={classes.input}
                disabled={sending}
                error={errors.length > 0}
                helperText={errors.join(', ')}
                inputProps={{
                  maxLength: 4
                }}
              />
          </form>
      }
    </>
  )

}

export default ConfirmAccount
