import accountApi from "app/services/account-api";

export async function signIn({ username, password }) {
  const normalize = username.trim().toLowerCase();
  const response = await accountApi.post("/sign-in", { username: normalize, password });
  return response.data;
}

export async function socialSignIn({ _token, provider }) {
  const response = await accountApi.post(`/sign-in/${provider}`, {
    token: _token,
  });

  return response.data;
}

export async function signUp({
  firstname,
  surname,
  email,
  document,
  password,
  isForeign,
  indicatedBy,
  captcha,
}) {
  const response = await accountApi.post("sign-up", {
    firstname,
    surname,
    email,
    document,
    password,
    isForeign,
    indicatedBy,
    captcha,
  });
  return response.data;
}

export async function socialSignUp({ _token, provider, email }) {
  const response = await accountApi.post(`sign-up/${provider}`, {
    token: _token,
    provider,
    email
  });
  return response.data;
}
