//
import React, { useEffect, useState, useCallback } from 'react'
import { jsPDF } from "jspdf";

import { useHistory } from 'react-router-dom'
import studentApi from 'app/services/student-api'
import { useAuth } from 'app/contexts/AuthContext'
import PersonalInformations from 'app/components/Modals/Profile/PersonalInformations'
import { useStyles } from './styles'

import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Avatar,
  Container,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Link,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import Skeleton from '@material-ui/lab/Skeleton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import accountApi from "app/services/account-api";

import { formatDate } from 'app/utils'

import kultiviLogo from "assets/images/kultivi.png";
import { signaturePydd } from 'helpers/signature-pydd';



function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function CardPersonalInfo() {

  const classes = useStyles()
  const { user } = useAuth()
  const [open, setOpen] = React.useState(false);

  const toggleDialog = (open) => (event) => {
    setOpen(open);
  };

  return (
    <>
      <Card className={classes.cardUser}>
        <CardContent>
          <Box component="div" display="flex" flexDirection="column" alignItems="center">
            <Avatar className={classes.userAvatar}>{user.firstname && ((user.firstname).charAt(0)).toUpperCase()}</Avatar>
            <Box component="div" display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body2" component="span" className={classes.userName}>{user.fullname}</Typography>
              <IconButton
                aria-label="edit-name"
                onClick={toggleDialog(true)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <Box component="div" display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography variant="body2" component="span" className={classes.userSince}>Aluno desde: {formatDate(user.signup_at)}</Typography>
        </Box>
      </Card>
      <PersonalInformations open={open} toggleDialog={toggleDialog} />
    </>
  )
}


export default (props) => {

  const history = useHistory()
  const classes = useStyles()
  const [enrollments, setEnrollments] = useState([])
  const [courseId, setCourseId] = useState(null)

  const {user} = useAuth()

  const [loadingEnrollments, setLoadingEnrollments] = useState(true)

  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackValues({ open: false });
  };

  const [invites, setInvites] = useState([]);
  const [loadingInvites, setLoadingInvites] = useState(true);

  async function getInvites() {
    setLoadingInvites(true);
    try {
      const response = await accountApi.get('referrals/invites');
      setInvites(response.data);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingInvites(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoadingInvites(true);
      try {
        await getInvites();
      } finally {
        setLoadingInvites(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    studentApi.get(`subscribed-courses`).then(response => {
      setEnrollments(response.data)
    }).catch(error => {
      console.warn(error)
    }).finally(() => {
      setLoadingEnrollments(false)
    })

  }, [])

  const generatePdf = useCallback((enroll) => {
    const doc = new jsPDF();

    const today = new Date();
    const formattedDate = today.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    const img = new Image();
    img.src = kultiviLogo;
    img.onload = () => {
      doc.addImage(img, "PNG", 10, 10, 30, 8);

      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Comprovante de Matrícula", 105, 40, null, null, "center");


      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      const mainText = [
        `Declaramos, para os devidos fins, que o aluno ${user.fullname} está oficialmente`,
        `matriculado no curso "${enroll.title}".`
      ];
      let mainTextYPosition = 65;
      const mainTextLineSpacing = 8;

      mainText.forEach(line => {
        doc.text(line, 20, mainTextYPosition, { maxWidth: 170 }, "center");
        mainTextYPosition += mainTextLineSpacing;
      });

      doc.setFontSize(10);
      const additionalText = [
        "Este curso faz parte do compromisso da Kultivi em oferecer educação de qualidade e acessível para todos",
        "os brasileiros. Com milhões de alunos em nossa plataforma, continuamos a democratizar o acesso ao",
        "conhecimento."
      ];
      let yPosition = 100;
      const lineSpacing = 6;

      additionalText.forEach(line => {
        doc.text(line, 20, yPosition, { maxWidth: 170 });
        yPosition += lineSpacing;
      });

      doc.setFontSize(9);
      doc.text("Kultivi - A educação ao alcance de todos.", 105, 130, null, null, "center");
      doc.text(formattedDate, 105, 140, null, null, "center");

      doc.addImage(signaturePydd(), "PNG", 86, 150, 40, 25);

      doc.save(`comprovante-de-matricula-${enroll.title}.pdf`);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * ######## ENROLLMENTS CARD ######## */
  function CardEnrollments() {

    const [dialogUnsubscribeCourseOpen, setDialogUnsubscribeCourseOpen] = React.useState(false)
    const toggleDialogUnsubscribeCourseOpen = (open, course_id = null) => (event) => {
      setCourseId(course_id)
      setDialogUnsubscribeCourseOpen(open);
    };

    const deleteCourse = React.useCallback(() => {

      setDialogUnsubscribeCourseOpen(false)

      studentApi.delete(`subscribed-courses/${courseId}/unsubscribe`)
        .then((response) => {
          setSnackValues({ message: "Curso removido com sucesso.", open: true });
          setEnrollments(enrollments.filter((item) => item.id !== courseId))
          setCourseId(null)
        }).catch((error) => {
          console.warn(error.data)
        })

    }, [])

    const _goToDiscover = useCallback(() => {
      history.push('/dashboard/discover')
    }, [])

    return (
      <React.Fragment>
        <Card className={classes.cardAbout}>
          <CardHeader
            title="Meus cursos"
            className={classes.title}
            disableTypography
          ></CardHeader>
          <CardContent>
            {loadingEnrollments ?
              [0, 1, 2, 3, 4, 5].map((key) => {
                return <Skeleton height={45} key={key} />
              }) : (
                enrollments.length > 0 ? (
                  <List className={classes.enrollments}>
                    {enrollments.map((enroll, index) => {
                      return (
                        <React.Fragment key={enroll.id}>
                          <ListItem>
                            <ListItemAvatar>
                              <CircularProgressWithLabel value={enroll.progress || enroll.percentage} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={enroll.title}
                              secondary={
                                <Box display='flex' flexDirection='column'>
                                  {enroll.category.title}
                                  <Link onClick={() => generatePdf(enroll)} style={{cursor: 'pointer'}}>Emitir comprovante de matrícula</Link>
                                </Box>
                              }>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="delete" onClick={toggleDialogUnsubscribeCourseOpen(true, enroll.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          {index < (enrollments.length - 1) ? <Divider component="li" /> : ''}
                        </React.Fragment>
                      )
                    })}
                  </List>
                ) : (
                  <Box component="div" display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6" component="p" className={classes.noCoursesTitle}>
                      Você ainda não se matriculou em nenhum curso
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.noCoursesSubtitle}>
                      Aproveite e comece a estudar agora mesmo! <span role="img" aria-label="smile">😃</span>
                    </Typography>
                    <Button size="large" color="secondary" variant="contained" onClick={_goToDiscover}>Explorar cursos</Button>
                  </Box>)
              )
            }
          </CardContent>
        </Card>
        <Dialog
          open={dialogUnsubscribeCourseOpen}
          aria-labelledby="unsubscribe-dialog-title"
          aria-describedby="unsubscribe-dialog-description"
        >
          <DialogTitle id="unsubscribe-dialog-title">{"Confirma exclusão de curso?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="unsubscribe-dialog-description">
              Ao desmatricular-se de um curso, todo o seu progresso, histórico de questões e certificado vinculados à ele serão excluídos. Esta ação é irreversível - uma vez que seus dados foram excluídos, não podemos recuperá-los novamente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogUnsubscribeCourseOpen(false)}>Cancelar</Button>
            <Button onClick={deleteCourse} color="primary">Excluir</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }



  function CardInterests() {

    return (
      <Card className={classes.cardAbout}>
        <CardHeader
          title="Meus interesses e competências"
          className={classes.title}
          disableTypography
          action={
            <IconButton aria-label="edit-about">
              <EditIcon fontSize="small" />
            </IconButton>
          }
        ></CardHeader>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            O que você gostaria de aprender? Quais são suas principais competências?
          </Typography>
        </CardContent>
      </Card>
    )

  }

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} lg={4}>
            <Box component="div" mb={3} width="100%">
              <CardPersonalInfo />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box component="div" mb={3} width="100%">
              <CardEnrollments />
            </Box>
            <Box component="div" mb={3} width="100%">
              <Card className={classes.cardAbout}>
                <CardHeader
                  title="Minhas indicações"
                  className={classes.title}
                  disableTypography
                ></CardHeader>
                <CardContent>
                  {
                    !loadingInvites && invites.length > 0 &&
                      (
                        <>
                          {/* <Typography component='p' variant='body'>Para ativar seus certificados após as indicações, é necessário refazer seu login.</Typography> */}
                          <List>
                            {invites.map((invite, key) => (
                              <ListItem key={`invite_${key}`}>
                                <ListItemAvatar>
                                  <Avatar style={{ backgroundColor: 'transparent' }}>
                                    {invite.isConfirmed ? <DoneAllIcon color='primary' /> :  <AccessTimeIcon color='disabled' />}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={invite.name} secondary={invite.isConfirmed ? 'Ativo' : 'Aguardando confirmação de cadastro'}></ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </>
                      )
                  }
                  {
                    !loadingInvites && !invites.length && (
                      <Box component="div" display="flex" flexDirection="column" alignItems="center">
                        <Box mb={3}>
                          <Typography variant="h6" className={classes.noInvitesTitle}>
                            {/* Convide Seus Amigos e Desbloqueie Certificados Gratuitos! */}
                            Convide Seus Amigos
                          </Typography>
                          <Box className={classes.noInvitesText}>
                            <Typography component="body2">
                              {/* Você está a apenas alguns passos de conquistar certificados gratuitos para os cursos que você ama na Kultivi! */}
                              Compartilhe seu link exclusivo com amigos e ajude a expandir nossa comunidade de aprendizes.
                              {/* Quanto mais amigos você indicar, mais rápido você desbloqueia seus certificados. */}
                            </Typography>
                          </Box>
                        </Box>
                        <Button size="large" color="primary" variant="contained" onClick={() => history.push('/dashboard/referrals')}>Convidar agora</Button>
                      </Box>
                    )
                  }
                  {
                    loadingInvites && (
                      [0, 1, 2, 3].map((key) => {
                        return <Skeleton height={45} key={`skl_invites_${key}`} />
                      })
                    )
                  }
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
