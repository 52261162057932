import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "auto",
  },
  headTitles: {
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-1rem",
    },
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
  },
  recommendedTitle: {
    color: "#FFFFFF",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textAlign: "center",
    width: "60%",
    margin: "0 auto",
    textTransform: "uppercase",
    fontFamily: "Nunito Sans, sans-serif",
  },
  headingPlan: {
    marginTop: "-1rem",
    backgroundColor: "#26A678",
    padding: "0.2rem",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "1rem ",
  },

  recommended: {
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.grey[500],
    borderRight: "2px dashed #FEF080",
    borderLeft: "2px dashed #FEF080",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      width: "100%",
      borderBottom: "2px dashed #FEF080",
      borderBottomRightRadius: "1rem",
      borderBottomLeftRadius: "1rem",
    },
  },
  icon: {
    "&:first-child": {
      marginTop: "0.4rem",
    },
    margin: "0.812rem",
  },

  iconGreen: {
    "&:first-child": {
      marginTop: "0.4rem",
    },
    margin: "0.812rem",
  },

  iconYellow: {
    "&:first-child": {
      marginTop: "0.4rem",
    },
    margin: "0.812rem",
  },

  iconOrange: {
    "&:first-child": {
      marginTop: "0.45rem",
    },
    margin: "0.812rem",
    "&:last-child": {},
  },

  containerRecommended: {
    marginTop: "-2rem",
    width: "250px",
  },
  purple: {
    color: theme.palette.secondary.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  yellow: {
    color: "#FEF080",
  },
  orange: {
    color: "#F68E60",
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerPlan: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem 1rem 4rem 1rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  remove: {
    margin: "0.59rem",
  },
  greenRemove: {
    margin: "0.59rem",
    "&:last-child": {},
  },
  yellowRemove: {
    margin: "0.59rem",
  },
  prices: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bolder",
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "1rem 0",
  },
  previousPrice: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    margin: "-0.5rem 0 1rem",
    textDecoration: "line-through",
  },
  footerRecommended: {
    backgroundColor: theme.palette.grey[500],
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    borderRight: "2px dashed #FEF080",
    borderBottom: "2px dashed #FEF080",
    borderLeft: "2px dashed #FEF080",
  },
  button: {
    fontFamily: "Nunito Sans, sans-serif",
  },
  buttonGreen: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "3.5rem",
    width: "13rem",
    marginBottom: "2rem",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#26A69A",
    },
  },
  buttonYellow: {
    width: "13rem",

    backgroundColor: "#FEF080",
    color: theme.palette.grey[700],
    "&:hover": {
      backgroundColor: "#FFEB4F",
    },
  },
  buttonOrange: {
    width: "14rem",
    backgroundColor: "#F68E60",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F46425",
    },
  },
}));

export { useStyles };
