import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

import {
  Card,
  CardContent,
 } from '@material-ui/core';

 import { useStyles } from './styles'

const SkeletonCourse = () => {

  const classes = useStyles();

  return (
    <>      
      <Card className={classes.root}>
        <Skeleton animation="wave" variant="rect" height={150} />
          <CardContent className={classes.content}>
            <>
              <Skeleton animation="wave" height={30} width="40%" />
              <Skeleton animation="wave" height={30} width="80%" />
              <Skeleton animation="wave" height={70} width="100%" />
            </>
          </CardContent>
      </Card>
    </>
  )
}

export default SkeletonCourse
