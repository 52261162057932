import elo from "assets/images/elo-v2.svg";
import visa from "assets/images/visa-v2.svg";
import boleto from "assets/images/boleto.png";
import america from "assets/images/amex-v2.svg";
import mastercard from "assets/images/mastercard-v2.svg";

const dataPlans = [
  {
    title: "Kultivi",
    content: [
      {
        title: "• Cursos online 100% gratuitos",
        description:
          "Acesso ilimitado a cursos completos, de qualidade, nas áreas de Idiomas, Carreira e Negócios, Enem, Concursos, Oab, saúde.",
      },
      {
        title: "• Material de apoio",
        description:
          "Download do material utilizado pelo professor durante as aulas, para criar suas próprias anotações e acompanhar o conteúdo.",
      },
      {
        title: "• Certificados de conclusão",
        description:
          "Certificado digital para impressão disponível após finalizar qualquer curso: para dar aquele up no currículo e deixar o LinkedIn mais atrativo.",
      },
      {
        title: "• Lives",
        description:
          "Assista todas as lives e aulas ao vivo dos melhores professores, com conteúdo excepcional.",
      },
      {
        title: "• Blog",
        description:
          "Navegue pelo conteúdo de todas as áreas, artigos com dicas, modelos para baixar e muito mais para você evoluir nos estudos.",
      },
    ],
  },

  {
    title: "Kultivi+",
    priceOne: "R$47/ano",
    priceTwo: "R$9.70/mês",
    priceThree: "Antes: R$89,00",
    id: "kultivi_mais_1",
    cta: "Assinar Plano Kultivi+",
    path: {
      month: `${process.env.REACT_APP_CHECKOUT_URL}/${process.env.REACT_APP_IUGU_PRODUCT_MONTHLY}`,
    },
    content: [
      {
        title: "• Cursos online 100% gratuitos",
        description:
          "Acesso ilimitado a cursos completos, de qualidade, nas áreas de Idiomas, Carreira e Negócios, Enem, Concursos, Oab, saúde.",
      },
      {
        title: "• Material de apoio",
        description:
          "Download do material utilizado pelo professor durante as aulas, para criar suas próprias anotações e acompanhar o conteúdo.",
      },
      {
        title: "• Certificados de conclusão",
        description:
          "Certificado digital para impressão disponível após finalizar qualquer curso: para dar aquele up no currículo e deixar o LinkedIn mais atrativo.",
      },
      {
        title: "• Lives",
        description:
          "Assista todas as lives e aulas ao vivo dos melhores professores, com conteúdo excepcional.",
      },
      {
        title: "• Blog",
        description:
          "Navegue pelo conteúdo de todas as áreas, artigos com dicas, modelos para baixar e muito mais para você evoluir nos estudos.",
      },
      {
        title: "• Klubi (Clube de descontos)",
        description:
          "Acesso a descontos em mais de 300 lojas físicas e virtuais em beleza, entretenimento, gastronomia, eletrônicos, moda e muito mais!",
        link: "http://klubi.kultivi.com/",
      },
      {
        title: "• Contribui com a educação",
        description:
          "Nós vamos continuar mudando o mundo através da educação. E você está participando desse movimento conosco: contribuindo para o crescimento da Kultivi e para novos cursos no site e aplicativo!.",
      },
      {
        title: "• Ferramenta de simulados",
        description:
          "Exclusiva para criar e responder simulados. Ideal para quem quer testar os conhecimentos, se preparar para as provas e acompanhar seu progresso!",
      },
      {
        title: "• Biblioteca Kultivi",
        description:
          "Exclusivo para assinantes que querem acessar materiais complementares como planos de estudos, e-books de vocabulário, dicas, apostilas de Direito e muitos outros recursos.",
      },
      {
        title: "• Caderno virtual",
        description:
          "Anote tudo o que for importante durante a aula, com a marcação dos minutos do vídeo!",
      }
    ],
  },

  {
    title: "Kultivi Concursos",
    priceOne: "R$99.90/ano",
    priceTwo: "R$29.90/mês",
    id: "kultivi_concursos_1",
    cta: "Assinar Plano Kultivi Concursos",
    path: {
      month: `${process.env.REACT_APP_CHECKOUT_URL}/${process.env.REACT_APP_IUGU_PRODUCT_CONCURSOS}`,
    },
    content: [
      {
        title: "• Cursos online 100% gratuitos",
        info: {
          title: "Cursos online 100% gratuitos",
          description:
            "Acesso ilimitado a cursos completos, de qualidade, nas áreas de Idiomas, Carreira e Negócios, Enem, Concursos, Oab, saúde.",
        },
      },
      {
        title: "• Material de apoio",
        info: {
          title: "Material de apoio",
          description:
            "Download do material utilizado pelo professor durante as aulas, para criar suas próprias anotações e acompanhar o conteúdo.",
        },
      },
      {
        title: "• Certificados de conclusão",
        info: {
          title: "Certificados de Conclusão",
          description:
            "Certificado digital para impressão disponível após finalizar qualquer curso: para dar aquele up no currículo e deixar o LinkedIn mais atrativo.",
        },
      },
      {
        title: "• Lives",
        info: {
          title: "Lives",
          description:
            "Assista todas as lives e aulas ao vivo dos melhores professores, com conteúdo excepcional.",
        },
      },
      {
        title: "• Blog",
        info: {
          title: "Blog",
          description:
            "Navegue pelo conteúdo de todas as áreas, artigos com dicas, modelos para baixar e muito mais para você evoluir nos estudos.",
        },
      },
      {
        title: "• Klubi (Clube de descontos)",
        description:
          "Acesso a descontos em mais de 300 lojas físicas e virtuais em beleza, entretenimento, gastronomia, eletrônicos, moda e muito mais!",
        link: "http://klubi.kultivi.com/",
      },
      {
        title: "• Contribui com a educação",
        info: {
          title: "Contribui com a educação",
          description:
            "Nós vamos continuar mudando o mundo através da educação. E você está participando desse movimento conosco: contribuindo para o crescimento da Kultivi e para novos cursos no site e aplicativo!.",
        },
      },
      {
        title: "• Ferramenta de simulados",
        info: {
          title: "Ferramenta de simulados",
          description:
            "Exclusiva para criar e responder simulados. Ideal para quem quer testar os conhecimentos, se preparar para as provas e acompanhar seu progresso!",
        },
      },
      {
        title: "• Biblioteca Kultivi",
        info: {
          title: "Biblioteca Kultivi",
          description:
            "Exclusivo para assinantes que querem acessar materiais complementares como planos de estudos, e-books de vocabulário, dicas, apostilas de Direito e muitos outros recursos.",
        },
      },
      {
        title: "• Caderno virtual",
        info: {
          title: "Caderno virtual",
          description:
            "Anote tudo o que for importante durante a aula, com a marcação dos minutos do vídeo!",
        },
      },
      {
        title: "• Apostilas preparatórias para concursos públicos",
        description:
          "Materiais de estudo, dirigidos e atualizados, para aqueles que buscam aprovação nos principais concursos públicos do país",
        link: "https://www.aprovare.com/",
      },
    ],
  }
];

const listOptionsPlans = [
  {
    title: "• Cursos online 100% gratuitos",
    description:
      "Acesso ilimitado a cursos completos, de qualidade, nas áreas de Idiomas, Carreira e Negócios, Enem, Concursos, Oab, saúde.",
  },
  {
    title: "• Material de apoio",
    description:
      "Download do material utilizado pelo professor durante as aulas, para criar suas próprias anotações e acompanhar o conteúdo.",
  },
  {
    title: "• Certificados de conclusão",
    description:
      "Certificado digital para impressão disponível após finalizar qualquer curso: para dar aquele up no currículo e deixar o LinkedIn mais atrativo.",
  },
  {
    title: "• Lives",
    description:
      "Assista todas as lives e aulas ao vivo dos melhores professores, com conteúdo excepcional.",
  },
  {
    title: "• Blog",
    description:
      "Navegue pelo conteúdo de todas as áreas, artigos com dicas, modelos para baixar e muito mais para você evoluir nos estudos.",
  },
  {
    title: "• Contribui com a educação",
    description:
      "Nós vamos continuar mudando o mundo através da educação. E você está participando desse movimento conosco: contribuindo para o crescimento da Kultivi e para novos cursos no site e aplicativo!.",
  },
  {
    title: "• Klubi (Clube de descontos)",
    description:
      "Acesso a descontos em mais de 300 lojas físicas e virtuais em beleza, entretenimento, gastronomia, eletrônicos, moda e muito mais!",
    // link: "http://klubi.kultivi.com/",
  },
  {
    title: "• Ferramenta de simulados",
    description:
      "Exclusiva para criar e responder simulados. Ideal para quem quer testar os conhecimentos, se preparar para as provas e acompanhar seu progresso!",
  },
  {
    title: "• Biblioteca Kultivi",
    description:
      "Exclusivo para assinantes que querem acessar materiais complementares como planos de estudos, e-books de vocabulário, dicas, apostilas de Direito e muitos outros recursos.",
  },
  {
    title: "• Caderno virtual",
    description:
      "Anote tudo o que for importante durante a aula, com a marcação dos minutos do vídeo!",
  },
  {
    title: "• Apostilas preparatórias para concursos públicos",
    description:
      "Materiais de estudo, dirigidos e atualizados, para aqueles que buscam aprovação nos principais concursos públicos do país",
    link: "https://www.aprovare.com/",
  },
];

const paymentsImages = [mastercard, visa, elo, america, boleto];

export { dataPlans, listOptionsPlans, paymentsImages };
