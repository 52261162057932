import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import api from "app/services/api";
import systemApi from "app/services/system-api";
import coreApi from "app/services/core-api";
import studentApi from "app/services/student-api";
import accountApi from "app/services/account-api";
import * as auth from "app/services/auth";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Manter enquanto todos hrefs não forem transformados para history.push
  const [isLoadingStorage, setIsLoadingStorage] = useState(true);

  useEffect(() => {
    const storagedUser = localStorage.getItem("@Kultivi:user");
    const storagedToken = localStorage.getItem("@Kultivi:token");
    if (storagedUser && storagedToken) {
      api.defaults.headers.authorization = `Bearer ${storagedToken}`;
      systemApi.defaults.headers.authorization = `Bearer ${storagedToken}`;
      coreApi.defaults.headers.authorization = `Bearer ${storagedToken}`;
      studentApi.defaults.headers.authorization = `Bearer ${storagedToken}`;
      accountApi.defaults.headers.authorization = `Bearer ${storagedToken}`;
      setUser(JSON.parse(storagedUser));
    }
    setIsLoadingStorage(false);
  }, []);

  async function signIn({ username, password }) {
    const response = await auth.signIn({ username, password });
    const { token, user } = response;

    api.defaults.headers.authorization = `Bearer ${token.token}`;
    systemApi.defaults.headers.authorization = `Bearer ${token.token}`;
    coreApi.defaults.headers.authorization = `Bearer ${token.token}`;
    studentApi.defaults.headers.authorization = `Bearer ${token.token}`;
    accountApi.defaults.headers.authorization = `Bearer ${token.token}`;

    localStorage.setItem("@Kultivi:token", token.token);
    localStorage.setItem("@Kultivi:refresh-token", token.refreshToken);
    localStorage.setItem("@Kultivi:user", JSON.stringify(user));

    return user;
  }

  async function socialSignIn({ _token, provider }) {
    const response = await auth.socialSignIn({ _token, provider });
    const { token, user } = response;

    api.defaults.headers.authorization = `Bearer ${token.token}`;
    systemApi.defaults.headers.authorization = `Bearer ${token.token}`;
    coreApi.defaults.headers.authorization = `Bearer ${token.token}`;
    studentApi.defaults.headers.authorization = `Bearer ${token.token}`;
    accountApi.defaults.headers.authorization = `Bearer ${token.token}`;

    localStorage.setItem("@Kultivi:token", token.token);
    localStorage.setItem("@Kultivi:refresh-token", token.refreshToken);
    localStorage.setItem("@Kultivi:user", JSON.stringify(user));

    return user;
  }

  async function signUp({ fullname, email, document, password, isForeign, indicatedBy, captcha }) {
    const parts = fullname.trim().split(/\s+/);
    const firstname = parts.at(0);
    const surname = parts.slice(1).join(' ');
    const _document = isForeign ? null : document;

    const response = await auth.signUp({
      firstname,
      surname,
      email,
      document: _document,
      password,
      isForeign,
      indicatedBy,
      captcha,
    });
    return response;
  }

  async function socialSignUp({ _token, provider, email }) {
    const response = await auth.socialSignUp({_token, provider, email});
    api.defaults.headers.authorization = `Bearer ${response.token.token}`;
    systemApi.defaults.headers.authorization = `Bearer ${response.token.token}`;
    coreApi.defaults.headers.authorization = `Bearer ${response.token.token}`;
    studentApi.defaults.headers.authorization = `Bearer ${response.token.token}`;
    accountApi.defaults.headers.authorization = `Bearer ${response.token.token}`;

    localStorage.setItem("@Kultivi:token", response.token.token);
    localStorage.setItem("@Kultivi:user", JSON.stringify(response.user));
    // setUser(response.user);

    return;
  }

  async function signOut() {
    localStorage.removeItem("@Kultivi:token");
    localStorage.removeItem("@Kultivi:refresh-token");
    localStorage.removeItem("@Kultivi:user");
    api.defaults.headers.authorization = null;
    systemApi.defaults.headers.authorization = null;
    coreApi.defaults.headers.authorization = null;
    studentApi.defaults.headers.authorization = null;
    accountApi.defaults.headers.authorization = null;
    setUser(null);

    return;
  }

  const refreshUserInfo = useCallback(
    (...payload) => {
      const obj = Object.assign({}, ...payload.map((object) => object));
      localStorage.setItem(
        "@Kultivi:user",
        JSON.stringify({ ...user, ...obj })
      );
      setUser((prev) => ({ ...prev, ...obj }));
    },
    [user]
  );

  return (
    <AuthContext.Provider
      value={{
        isSigned: !!user,
        user,
        signIn,
        signUp,
        signOut,
        socialSignIn,
        socialSignUp,
        refreshUserInfo,
        isLoadingStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvier");
  }

  return context;
}
