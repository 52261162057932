
import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles'

import {
    Box,
    Card,
    CardContent,
    Container,
    IconButton,
    Link,
    TextField,
    Typography,
} from '@material-ui/core'
import ContentCopyIcon from '@material-ui/icons/FileCopyOutlined';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useSnackbar } from "app/contexts/SnackbarContext";
import { useAuth } from 'app/contexts/AuthContext';

export default (props) => {

  const classes = useStyles()
  const history = useHistory()

  const { user } = useAuth();
  const { setSnackValues } = useSnackbar();

  const inviteText = 'Estou fazendo cursos gratuitos incríveis na Kultivi e quero te convidar para se juntar a mim! Vamos?';
  const [refLink, setRefLink] = useState(null);

  const copyLink = () => {
    navigator.clipboard.writeText(refLink)
      .then(() => {
        setSnackValues({
          message: `Link copiado com sucesso!`,
          open: true,
        });
      })
      .catch(() => {
        setSnackValues({
          message: `Houve uma falha ao copiar o link!`,
          open: true,
        });
      });
  };

  useEffect(() => {
    setRefLink(`https://app.kultivi.com/signup?ref=${user.uuid}`)
  }, [user])

  return (
    <>
      <Container>
        <Box component="div" px={3} mb={12} pt={2}>
          <Box mb={3}>
          <Link
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              component="button"
              onClick={() => history.push('/dashboard')}
            >
              <ChevronLeftIcon /> Ir para página inicial
          </Link>
          </Box>
          <Box>
            <Typography
              component="h1"
              className={classes.title}
            >
              Kultivi Amigos
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography
              component="h1"
              className={classes.subtitle}
            >
              {/* Conquiste certificados gratuitos e ajude nossa comunidade a crescer! */}
              Ajude nossa comunidade a crescer!
            </Typography>
          </Box>
          <Box mb={3} className={classes.howItWorks}>
            <Typography component='p' variant='body2'>1 - Envie seu link de indicação para amigos, familiares, colegas de trabalho ou qualquer pessoa interessada em aprender algo novo.</Typography>
            {/* <Typography component='p' variant='body2'>2 - Quando três ou mais novos alunos se inscreverem e <strong>permanecerem ativos</strong> na plataforma usando o seu link, você ganha acesso gratuito aos nossos certificados de conclusão.</Typography> */}
            <Typography component='p' variant='body2'>2 - Verifique o status de suas indicações na sua <Link style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => history.push('/dashboard/user/profile')}>página de perfil</Link> e veja quantas pessoas já se inscreveram usando o seu link.</Typography>
          </Box>
          <Box mb={4} className={classes.referralBox}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Typography component="h3" className={classes.referralBoxTitle}>
                    Compartilhe o seu link de indicação
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }} mb={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={refLink}
                    onClick={copyLink}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton onClick={copyLink} color='primary'>
                          <ContentCopyIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
                <Box display='flex'>
                  <IconButton
                    color="primary"
                    href={`https://wa.me/?text=${encodeURIComponent(inviteText)} ${encodeURIComponent(refLink)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src='https://cdn-kultivi.s3.amazonaws.com/static/platform/socials/whatsapp.svg' alt='Whatsapp' width={33} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(refLink)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src='https://cdn-kultivi.s3.amazonaws.com/static/platform/socials/facebook.svg' alt='Facebook' width={40} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(inviteText)}&url=${encodeURIComponent(refLink)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src='https://cdn-kultivi.s3.amazonaws.com/static/platform/socials/twitter.svg' alt='Twitter' width={40} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    href={`https://linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(refLink)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src='https://cdn-kultivi.s3.amazonaws.com/static/platform/socials/linkedin.svg' alt='LinkedIn' width={40} />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box mb={2} className={classes.faq}>
            <Box mb={2}>
              <Typography component='h4' variant='h4'>Por que participar?</Typography>
              {/* <Typography component='p'>Ao completar a missão, você desbloqueia a emissão de certificados gratuitos para todos os cursos que você concluir com sucesso.</Typography> */}
              <Typography component='p'>Ao indicar novos estudantes, você está ajudando a disseminar o conhecimento e a fortalecer nossa missão de levar educação de qualidade e gratuita para todos.</Typography>
            </Box>
            <Box mb={2}>
              <Typography component='h4' variant='h4'>Dicas para o sucesso</Typography>
              <Typography component='p'>Publique seu link nas suas redes sociais com uma mensagem personalizada incentivando as pessoas a se inscreverem.</Typography>
              <Typography component='p'>Envie seu link diretamente para amigos e conhecidos que você acredita que possam se beneficiar dos nossos cursos.</Typography>
              <Typography component='p'>Forme grupos de estudo e convide novos membros a se juntarem através do seu link de indicação.</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}
