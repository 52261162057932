import React from 'react';
import ExploreIcon from '@material-ui/icons/Explore';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EcoIcon from '@material-ui/icons/Eco';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SchoolIcon from '@material-ui/icons/School';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const sectionEstudar = [
  {
    title: 'MEUS CURSOS',
    url: '/dashboard/enrollments',
    target: '_self',
    disabled: false,
    icon: <SchoolIcon />,
    description: 'Sua lista de cursos em andamento e concluídos'
  },
  {
    title: 'TODOS OS CURSOS',
    url: '/dashboard/discover',
    target: '_self',
    disabled: false,
    icon: <ExploreIcon />,
    description: 'Explore todos os cursos disponíveis'
  },
  {
    title: 'BLOG',
    url: 'https://kultivi.com/blog',
    target: '_blank',
    disabled: false,
    icon: <RssFeedIcon />,
    description: 'Novidades e muito conteúdo exclusivo no blog',
    divider: true,
    featured: false,
  },
]

const sectionAprofundar = [
  {
    title: 'EXERCÍCIOS E SIMULADOS',
    url: '/dashboard/quizzes',
    target: '_self',
    disabled: false,
    icon: <FlashOnIcon />,
    description: 'Desafios ilimitados para praticar sempre que quiser'
  },
  {
    title: 'E-BOOKS',
    url: '/dashboard/ebooks',
    target: '_self',
    disabled: false,
    icon: <MenuBookIcon />,
    description: 'Materiais exclusivos para auxiliar seus estudos'
  },
  {
    title: 'APOSTILAS DIGITAIS',
    url: '/dashboard/aprovare',
    target: '_self',
    disabled: false,
    icon: <NewReleasesIcon />,
    description: 'Materiais completos para concurseiros',
    divider: false,
    featured: false,
  },
  {
    title: 'PÍLULAS DE CONHECIMENTO',
    url: '/dashboard/pills',
    target: '_self',
    disabled: false,
    icon: <AmpStoriesIcon />,
    description: 'Pequenos conteúdos para capacitar e reafirmar informações',
    divider: false,
    featured: false,
  },
  {
    title: 'KULTIVI+',
    url: 'https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-aprofundar',
    target: '_blank',
    disabled: false,
    icon: <EcoIcon />,
    description: 'Apoie a nossa missão e acelere o seu aprendizado com ferramentas exclusivas',
    divider: false,
    featured: true
  }
]

const sectionPlus = [
  {
    title: 'PLANOS DE ASSINATURAS',
    url: 'https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-apoie',
    target: '_blank',
    disabled: false,
    icon: <EcoIcon />,
    description: 'Apoie a nossa missão e acelere o seu aprendizado com ferramentas exclusivas'
  },
  // {
  //   title: 'CLUBINHO DE DESCONTO',
  //   url: '/dashboard/klubi',
  //   target: '_self',
  //   disabled: false,
  //   icon: <LocalOfferIcon />,
  //   description: 'Convênios exclusivos para os alunos Kultivi em diversos estabelecimentos'
  // },
  {
    title: 'CARTEIRINHA DE ESTUDANTE',
    url: 'https://lp.fesn.org.br/aluno-kultivi',
    target: '_blank',
    disabled: false,
    icon: <CreditCardIcon />,
    description: 'Aluno Kultivi paga meia entrada em cinemas, teatros e shows!'
  },
  {
    title: 'INDIQUE',
    url: '/dashboard/referrals',
    target: '_self',
    disabled: false,
    icon: <GroupAddIcon />,
    description: 'Indique a Kultivi e ganhe benefícios'
  }
]

export {
  sectionEstudar,
  sectionAprofundar,
  sectionPlus,
}
