import React from "react";

import {
  Hidden,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


// Components
import ModuleAccordion from "app/components/accordions/Module/model-1";

// Styles files
import { useStyles } from "./styles";

const BottomMobileNavigation = (props) => {
  const classes = useStyles();

  const { course, lesson, curriculum, progress, lessonSlug } = props


  const getAttachment = (link) => {
    window.open(link);
  };

  const [option, setOption] = React.useState(0);
  const handleChangeOption = (event, newValue) => {
    setOption(newValue);
  };

  const handleClose = () => {
    setOption(0);
  };

  return (
    <React.Fragment>
      <Hidden mdUp implementation="css">
        <Paper className={classes.root}>
          <BottomNavigation
            showLabels
            value={option}
            onChange={handleChangeOption}
          >
            <BottomNavigationAction label="Curso" value="course" icon={<ExitToAppIcon />} disabled={!course} href={'../.'} />
            <BottomNavigationAction label="Aulas" value="aulas" icon={<DynamicFeedIcon />} disabled={!course} />
            <BottomNavigationAction label="Materiais" value="materiais" icon={<AttachFileIcon />} disabled={!lesson} />
            <BottomNavigationAction label="Próxima" value="proxima" icon={<NavigateNextIcon />} href={`../lesson/${lesson?.next?.slug}`} disabled={!lesson.next} />
          </BottomNavigation>
        </Paper>
        <Drawer
          anchor="bottom"
          open={option === 'aulas'}
          onClose={handleClose}
          classes={{paper: classes.drawer}}
        >
          {option === 'aulas' && curriculum?.map((module, index) => {
            return (
              <ModuleAccordion
                key={index}
                {...module}
                progress={progress}
                currentClass={lessonSlug}
              />
            )})
          }
        </Drawer>
        <Drawer
          anchor="bottom"
          open={option === 'materiais'}
          onClose={handleClose}
          classes={{paper: classes.drawer}}
        >
          {
            lesson?.attachments?.length < 1 ? (
              <ListItem
                className={classes.list}
              >
                <ListItemText primary={`Nenhum material disponível`} />
              </ListItem>
            ) : (
              lesson?.attachments?.map((item, index) => {
                return (
                  <ListItem
                    className={classes.list}
                    key={index}
                    button
                    onClick={() => getAttachment(item.link)}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <GetAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Material de apoio ${++index}`} />
                  </ListItem>
                );
              })
            )
          }
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default BottomMobileNavigation
