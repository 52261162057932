import React from "react";

import {
  Box,
  Typography,
  Button,
  Container,
  CircularProgress,
} from "@material-ui/core";

// Styles files
import { useStyles } from "./styles";
import { AdsTop, AdsBillboardLesson } from "app/components/ads/freestar";
import Campaigns from "app/components/carousels/Campaigns/ads";
import { useScopes } from "app/contexts/ScopesContext";

export default (props) => {
  const classes = useStyles();
  const { scopes } = useScopes();

  const {lesson, ads, isLoadingAds} = props

  return (
    <React.Fragment>
      <Box component="div" className={classes.root}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          className={classes.header}
          pt={4}
          pb={2}
          mb={5}
        >
          <Container>
            <Typography component="h2" className={classes.subtitle}>
              {lesson.module?.title}
            </Typography>
            <Typography component="h1" className={classes.title}>
              {lesson.title}
            </Typography>
          </Container>
        </Box>

        <Box component="div" mb={10}>
          <Container>
            <Box
              component="div"
              mb={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb={4}>
                {
                  !isLoadingAds && !scopes.includes('student_no_ads_display') ?
                    ads.length > 0 ?
                      <Campaigns campaigns={ads} /> :
                      <AdsTop />
                  : ''
                }
              </Box>
              <Box component="div" dangerouslySetInnerHTML={{ __html: lesson.text }} />
            </Box>

            <Box component="div" display="flex" justifyContent="center">
              <Box mb={2}><AdsBillboardLesson /></Box>
              {lesson.finished ? (
                lesson.next ? (
                  <Button
                    variant="contained"
                    href={`./${lesson.next.slug}`}
                    color="secondary"
                  >
                    Próxima aula
                  </Button>
                ) : (
                  <Button variant="contained" href={`./../`} color="secondary">
                    Retornar ao curso
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  disabled={props.isSubmitting}
                  color="primary"
                  onClick={() => props._finishLesson()}
                >
                  {!props.isSubmitting ? (
                    "Finalizar aula"
                  ) : (
                    <CircularProgress size={30} color="inherit" />
                  )}
                </Button>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};
