import React, {useEffect} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pubfig } from 'scripts/freestar'

import {
  Box,
  CssBaseline,
  Link,
  Typography,
  Snackbar,
  IconButton,
  Grid,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import SignupForm from "app/components/forms/Signup";

// Assets import
import Logo from "assets/images/kultivi.png";

// Styles files
import { useStyles, AnimationContainer } from "./styles";
import Statistics from 'app/components/cards/Statistics'


export default (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [snackValues, setSnackValues] = React.useState({
    message: "",
    open: false,
  });

  useEffect(() => {
    const freestar = pubfig();
    document.getElementsByTagName("head")[0].appendChild(freestar);
    return () => {
      document.getElementsByTagName("head")[0].removeChild(freestar);
    }

  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackValues({ open: false });
  };

  const goToSignIn = () => {
    history.push("/");
  };

  function Header() {
    return (
      <Box mb={4}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          flexWrap="wrap"
          alignItems="center"
          width="100%"
          mb={6}
        >
          <Box component="div" flexGrow={1} className={classes.logoContainer}>
            <img src={Logo} className={classes.logo} alt="Logo Kulitiv" />
          </Box>
          <Box component="div">
            <Box component="span" mr={1} className={classes.copySignUp}>
              Já tem conta?
            </Box>
            <Link
              component="a"
              className={classes.ctaSignIn}
              onClick={() => goToSignIn()}
            >
              CLIQUE AQUI!
            </Link>
          </Box>
        </Box>
        <Typography component="h1" variant="h1" className={classes.mainTitle}>
          Cadastre-se e comece a estudar gratuitamente!
        </Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.leftContent}>
          <CssBaseline>
            <AnimationContainer>
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                mx="auto"
                className={classes.formArea}
              >
                <Header />
                <Box
                  component="div"
                  className={classes.loginFormContainer}
                  textAlign="center"
                  width="100%"
                  mx="auto"
                  mb={6}
                >
                  <SignupForm />
                </Box>
              </Box>
            </AnimationContainer>
          </CssBaseline>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightContent}>
          <Statistics />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
