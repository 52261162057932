import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

function CardPlan({ plan }) {
  const classes = useStyles();

  const plus = plan.title.includes("+") && classes.green;
  const pos = plan.title.includes("Pós") && classes.yellow;
  const academic = plan.title.includes("Concursos") && classes.orange;
  const posButton = plan.title.includes("Pós") && classes.buttonYellow;
  const freeButton = plan.title.includes("+") && classes.buttonGreen;
  const academicButton =
    plan.title.includes("Concursos") && classes.buttonOrange;
  const [token, setToken] = useState(null);

  const handleRedirectToCheckout = (path) => {
    window.open(`${path}?token=${token}`, `_blank`);
  };

  useEffect(() => {
    const responseStorage = localStorage.getItem("@Kultivi:token");

    if (responseStorage) {
      setToken(responseStorage);
    }
  }, [token]);

  return (
    <Box className={classes.content} component="div">
      <Box component="div" className={classes.contentImage}>
        <Typography className={[classes.title, plus, pos, academic]}>
          {plan.title}
        </Typography>
      </Box>

      {plan.path.month && plan.path.yearly && (
        <>
          <Typography variant="body1" className={classes.prices}>
            {plan.priceOne}
          </Typography>

          <Button
            variant="contained"
            onClick={() => handleRedirectToCheckout(plan.path.month)}
            className={[classes.button, freeButton]}
          >
            Assinar Kultivi+ mensal
          </Button>

          <Typography variant="body1" className={classes.prices}>
            {plan.priceTwo}
          </Typography>
          <Typography variant="body1" className={classes.previousPrice}>
            {plan.priceThree}
          </Typography>

          <Button
            variant="contained"
            onClick={() => handleRedirectToCheckout(plan.path.yearly)}
            className={[classes.button, freeButton]}
          >
            Assinar Kultivi+ anual
          </Button>
        </>
      )}
      <>
        {!plan.path.yearly && (
          <>
            <Typography variant="body1" className={classes.prices}>
              {plan.priceOne} <Typography variant="body2">ou</Typography>{" "}
              {plan.priceTwo}
            </Typography>
            <Button
              variant="contained"
              id={plan.id}
              className={[
                classes.button,
                posButton,
                freeButton,
                academicButton,
              ]}
              onClick={() => handleRedirectToCheckout(plan.path.month)}
            >
              {plan.cta}
            </Button>
          </>
        )}
      </>
    </Box>
  );
}

export default CardPlan;
