import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  coursesAvailable: {
    fontWeight: 700,
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2)
  },
  filterCategory: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2)
  },
  exploringTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(21),
  },
  drawer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonFilter: {
    backgroundColor: "#FFF",
    boxShadow: '0px 0px 4px rgba(34, 40, 43, 0.16)',
  },
  showAll: {
    fontWeight: 800
  },
  adsenseHorizontal: {
    width: 728,
    height: 90,
  },
  searchPaper: {
    width: "100%",
    padding: '2px 8px',
    paddingLeft: '16px',
    display: "flex",
    boxShadow: '0px 2px 6px rgba(92,84,122,0.2)',
  },
  inputSearch: {
    flex: 1
  },
  emptyText: {
    fontSize: theme.typography.pxToRem(21),
    color: theme.palette.grey[700],
    fontWeight: 900
  }
}))

export { useStyles }
