import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  links: {
    color: theme.palette.grey[900],
    cursor: "pointer",
  },
}));

export { useStyles };
