import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  discoveryTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(45),
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  discoverySubtitle: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 800,
    marginRight: theme.spacing(1),
  },
  seeMore: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}))

export { useStyles }