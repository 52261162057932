import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
    },
    maxSubjects: {
        fontSize: theme.typography.pxToRem(12),
    }
}))

export { useStyles }