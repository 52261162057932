import * as yup from "yup";
import { CPF } from './cpf';

const MIN_PASSWORD = 6;

export const signInValidator = yup.object({
  username: yup.string("Digite o e-mail ou CPF").required("Campo obrigatório"),
  password: yup
    .string("Digite a sua senha")
    .required("Campo obrigatório")
    .min(MIN_PASSWORD, `Deve conter no mínimo ${MIN_PASSWORD} caracteres`),
});

export const signUpValidator = yup.object({
  fullname: yup
    .string()
    .trim()
    .required("Campo obrigatório")
    .matches(/\s/,"Insira o seu nome e sobrenome"),
  email: yup
    .string()
    .required("Campo obrigatório")
    .email("Digite um email válido"),
  document: yup.string().when("isForeign", {
      is: false,
      then: yup.string().required("Campo obrigatório")
    }),
  password: yup
    .string()
    .required("Campo obrigatório")
    .min(MIN_PASSWORD, `Deve conter no mínimo ${MIN_PASSWORD} caracteres`),
  isForeign: yup.boolean(),
});

export const recoveryPasswordValidator = yup.object({
  username: yup
    .string()
    .required("Campo obrigatório"),
});

export const redeemVoucherValidator = yup.object({
  code: yup
    .string()
    .required("Campo obrigatório"),
});

export const resetPasswordValidator = yup.object({
  password: yup
    .string("Digite a nova senha")
    .required("Campo obrigatório")
    .min(MIN_PASSWORD, `Deve conter no mínimo ${MIN_PASSWORD} caracteres`),
  password_confirmation: yup
    .string("Confirme a nova senha")
    .required("Campo obrigatório")
    .oneOf(
      [yup.ref("password"), null],
      "Nova senha e confirmação de senha não coincidem"
    )
    .min(MIN_PASSWORD, `Deve conter no mínimo ${MIN_PASSWORD} caracteres`),
});

export const confirmAccountValidator = yup.object({
  is_foreign: yup.boolean(),
  firstname: yup.string().required("Campo obrigatório"),
  surname: yup.string().required("Campo obrigatório"),
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
  document: yup.string().when("is_foreign", {
    is: false,
    then: yup.string().required("Campo obrigatório"),
  }),
  education_level_id: yup.string().required("Campo obrigatório"),
  state_id: yup.string().when("is_foreign", {
    is: false,
    then: yup.string().required("UF é obrigatório"),
  }),
  city_id: yup.string().when("is_foreign", {
    is: false,
    then: yup.string().required("Cidade é obrigatório"),
  }),
});

export const confirmAccountByCodeValidator = yup.object({
  code: yup
    .number()
    .typeError('Deve ser um número')
    .integer('Deve ser um número inteiro')
    .min(1000, 'Código incorreto')
    .max(9999, 'Código incorreto')
    .required("Campo obrigatório"),
});

export const changeUsernameValidator = yup.object({
  email: yup
    .string()
    .required("Campo obrigatório")
    .email("Digite um email válido"),
  emailConfirmation: yup
    .string()
    .required("Campo obrigatório")
    .email("Digite um email válido")
    .oneOf(
      [yup.ref("email"), null],
      "Nova e-mail e confirmação de e-mail não coincidem"
    ),
  password: yup
    .string("Digite a sua senha")
    .required("Campo obrigatório")
    .min(MIN_PASSWORD, `Deve conter no mínimo ${MIN_PASSWORD} caracteres`),
});
