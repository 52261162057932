import React from "react";

import KlubiLayout from "app/components/layouts/KultiviPlus/v2";

export default (props) => {
  return (
    <React.Fragment>
      <KlubiLayout />
    </React.Fragment>
  );
};
