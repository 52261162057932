import React from 'react';
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Box
} from '@material-ui/core';

import {
  ToolBarDesktop,
  ToolBarMobile
} from "app/components/navs/topbar"
import useStyles from './styles';

const AppBarMenu = () => {
  const classes = useStyles();
  const history = useHistory();

  const Logo = () => {
    return (
      <Box
        onClick={() => history.push('/dashboard')}
      >
        <img
          src="https://cdn-kultivi.s3.amazonaws.com/static/platform/logo-k.jpg"
          className={classes.brand}
          alt="Logo Kultivi"
        />
      </Box>
    )
  }

  const gotToPage = (url, target) => {
    if (!url) return;
    if (target === "_blank") {
      window.open(`${url}`, "_blank");
    } else {
      history.push(`${url}`);
    }
  }

  return (
      <AppBar
        position="sticky"
        className={classes.appBar}
      >
        <ToolBarDesktop Logo={Logo} gotToPage={gotToPage} />
        <ToolBarMobile Logo={Logo} gotToPage={gotToPage} />
      </AppBar>
  );
};

export default AppBarMenu;
