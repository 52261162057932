import React, { useState } from "react";

import {
  Box,
  LinearProgress,
  Typography,
  Button,
  Container,
  Hidden,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import studentApi from "app/services/student-api";

import NotesComponent from "app/components/Notes";
import { AdsBillboardLesson, AdsBottom } from "app/components/ads/freestar";
import Campaigns from "app/components/carousels/Campaigns/ads";
import Rating from "app/components/Rating";
import { YTPlayer, CNXPlayer } from "app/components/layouts/Player";

import { useSnackbar } from "app/contexts/SnackbarContext";
import { useScopes } from "app/contexts/ScopesContext";

import { useStyles } from "./styles";

export default (props) => {
  const classes = useStyles();

  const { setSnackValues } = useSnackbar();
  const { scopes } = useScopes();


  const [loadingVideo, setLoadingVideo] = useState(true);
  const [videoDone, setVideoDone] = useState(false);
  const [videoApi, setVideoApi] = useState(null);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    course,
    lesson,
    ads,
    isLoadingAds,
  } = props;

  const finishVideo = () => {
    if (lesson.finished) return;
    setIsSubmitting(true);
    studentApi
      .post(`/subscribed-courses/${course.id}/finish-lesson`, {
        module: 'video',
        lessonId: lesson.id,
      })
      .then((response) => {})
      .catch((err) => {
        setSnackValues({
          message: "Ops! Houve uma falha ao finalizar aula. Tente novamente",
          open: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        window.scrollTo(0, 0);
      });
  };


  const getAttachment = (link) => {
    window.open(link);
  };

  return (
    <React.Fragment>
      {loadingVideo && <LinearProgress color="secondary" />}
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        className={[classes.root, videoDone ? classes.videoDone : ""].join(" ")}
        alignItems="center"
        justifyContent="center"
        py={1}
        px={1}
      >
        {!videoDone ? (
          <>
            <Box component="div" className={classes.videoAreaSize} mb={2}>
              {
                !scopes?.includes("student_no_ads_display") && lesson.connatixCode ?
                  <CNXPlayer
                    setLoadingVideo={setLoadingVideo}
                    setVideoApi={setVideoApi}
                    setVideoDone={setVideoDone}
                    finishVideo={finishVideo}
                    lesson={lesson}
                    course={course}
                    loadingVideo={loadingVideo}
                  /> :
                  <YTPlayer
                    setLoadingVideo={setLoadingVideo}
                    setVideoApi={setVideoApi}
                    setVideoDone={setVideoDone}
                    finishVideo={finishVideo}
                    lesson={lesson}
                    course={course}
                  />
                }
            </Box>
          </>
        ) : (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.nextClassCopy}
            my="auto"
          >
            <Box mb={3}>
              <Rating
                course_id={course.id}
                lesson_id={lesson?.id}
                copy={
                  !lesson?.next && course.status === 1
                    ? "Avalie este curso"
                    : "O que você está achando do curso?"
                }
              />
            </Box>
            <Box component="div" mb={3} textAlign="center">
              <Typography
                component="h5"
                variant="h5"
                dangerouslySetInnerHTML={{
                  __html: `Você concluiu a aula "${lesson.title}"`,
                }}
              ></Typography>
              {lesson?.next ? (
                <>
                  <Box component="div" mb={3}>
                    <Typography
                      component="h6"
                      variant="h6"
                      dangerouslySetInnerHTML={{
                        __html: `A seguir: "${lesson?.next.title}"`,
                      }}
                    ></Typography>
                  </Box>
                  {
                    !isSubmitting &&
                    <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    href={`./${lesson?.next.slug}`}
                    >
                      Reproduzir próxima aula
                    </Button>
                  }
                </>
              ) : (
                <>
                  <Box component="div" mb={3}>
                    {course.status === 2 && (
                      <Typography component="h6" variant="h6">
                        Este curso está em gravação e em breve
                        disponibilizaremos mais aulas
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    href={`/dashboard/course/${course.slug}`}
                  >
                    Retornar ao curso
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box component="div" mb={6} paddingTop={2}>
        <Box mb={4} maxWidth={900} mx='auto'>
          {
            !isLoadingAds && !scopes.includes('student_no_ads_display') ?
              ads.length > 0 ?
                <Campaigns campaigns={ads} /> :
                <AdsBillboardLesson />
            : ''
          }
        </Box>
        <Container maxWidth="md">
          <Hidden mdDown implementation="css">
            <Box component="div" pt={3} mb={2}>
              <Typography
                component="h6"
                variant="h6"
                style={{ color: "#0F5B7A", fontWeight: 700 }}
              >
                Materiais de apoio
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row'>
              {lesson?.attachments.length > 0 ? (
                lesson.attachments.map((attach, index) => (
                  <Box mr={1}>
                    <Button
                      color="secondary"
                      key={`lesson_source_${index}`}
                      variant="contained"
                      onClick={() => getAttachment(attach.link)}
                      size="small"
                      endIcon={<GetAppIcon />}
                    >
                      {`Baixar material de apoio`}
                    </Button>
                  </Box>
                ))
              ) : (
                <>Nenhum material de apoio disponível 😕</>
              )}
            </Box>
          </Hidden>
        </Container>
      </Box>
      <NotesComponent
        lesson_id={lesson.id}
        course_id={course.id}
        videoApi={videoApi}
      />
      <Box my={3}>
        <AdsBottom />
      </Box>
    </React.Fragment>
  );
};
