import React, {useEffect, useState} from 'react'

// API file
import coreApi from 'app/services/core-api'
import studentApi from 'app/services/student-api'
import systemApi from 'app/services/system-api'

import CourseLayout from 'app/components/layouts/Course/v1'

export default (props) => {

  const [content, setContent] = useState({})
  const [progress, setProgress] = useState({})
  const [curriculum, setCurriculum] = useState([])
  const [instructors, setInstructors] = useState([])
  const [ads, setAds] = useState([])
  const [isLoadingContent, setIsLoadingContent] = useState(true)
  const [isLoadingCurriculum, setIsLoadingCurriculum] = useState(true)
  const [isLoadingInstructors, setIsLoadingInstructors] = useState(true)
  const [isLoadingProgress, setIsLoadingProgress] = useState(true)
  const [isLoadingAds, setIsLoadingAds] = useState(true)

  const { slug } = props.match.params

  useEffect(() => {

    setIsLoadingCurriculum(true);
    setIsLoadingInstructors(true);
    setIsLoadingContent(true);
    setIsLoadingProgress(true);
    setIsLoadingAds(true);

    const fetchResult = async () => {

      const [course, ads] = await Promise.allSettled([
        coreApi.get(`/courses/${slug}?components=content,curriculum,instructors`),
        systemApi.get(`vendor?context=course&courseId=${slug}`)
      ])

      setContent(course.value?.data.content)
      setCurriculum(course.value?.data.curriculum);
      setInstructors(course.value?.data.instructors);
      setIsLoadingContent(false);
      setIsLoadingInstructors(false);

      setAds(ads.value?.data || [])
      setIsLoadingAds(false);

      let progress = [];
      if(course.status === 'fulfilled') {
        progress = await studentApi.get(`/subscribed-courses/${course.value.data.content.id}/status`)
      }

      setProgress(progress.data);
      setIsLoadingCurriculum(false);
      setIsLoadingProgress(false);
    }

    fetchResult().catch(console.error);
  },[slug])

  return (
    <CourseLayout
      isLoadingContent={isLoadingContent}
      isLoadingCurriculum={isLoadingCurriculum}
      isLoadingInstructors={isLoadingInstructors}
      isLoadingProgress={isLoadingProgress}
      isLoadingAds={isLoadingAds}
      content={content}
      curriculum={curriculum}
      instructors={instructors}
      progress={progress}
      ads={ads}
    />
  )
}
