import React from "react";
import { Drawer, List, ListItem } from "@material-ui/core";

import { useStyles } from "./styles";

function NavigationMobile({
  open,
  faqRef,
  bestRef,
  partnersRef,
  allPlansRef,
  handleBackClick,
  handleChangeDrawer,
}) {
  const classes = useStyles();

  return (
    <Drawer anchor="left" open={open} onClose={() => handleChangeDrawer()}>
      <List>
        {/* <ListItem
          onClick={() => handleBackClick(bestRef)}
          className={classes.links}
        >
          Melhor plano
        </ListItem> */}

        <ListItem
          onClick={() => handleBackClick(allPlansRef)}
          className={classes.links}
        >
          Todos os planos
        </ListItem>

        <ListItem
          onClick={() => handleBackClick(faqRef)}
          className={classes.links}
        >
          Perguntas frequentes
        </ListItem>

        {/* <ListItem
          onClick={() => handleBackClick(partnersRef)}
          className={classes.links}
        >
          Parceria
        </ListItem> */}
      </List>
    </Drawer>
  );
}

export default NavigationMobile;
