/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { Box, Container, Typography } from "@material-ui/core";

import CardPlan from "./CardPlan";

import plans from "./data";
import { useStyles } from "./styles";

function PlansSectionKlubi({ bestRef }) {
  const classes = useStyles();

  return (
    <Box component="section" className={classes.content} ref={bestRef}>
      <Container>
        <div>
          <Typography variant="h4" className={classes.title}>
            Tem um plano perfeito para a sua vida e para o seu bolso!
          </Typography>
          <Typography variant="h4" className={classes.title}>
            Qual você vai escolher?
          </Typography>

          <Box component="div" className={classes.contentPlans}>
            {plans.map((plan, index) => (
              <CardPlan
                key={`${plan.title}-${index}`}
                plan={plan}
                index={index}
              />
            ))}
          </Box>
        </div>
      </Container>
    </Box>
  );
}

export default PlansSectionKlubi;
