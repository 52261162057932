
import React, {useEffect, useState, useMemo} from 'react'
import { useHistory } from 'react-router-dom'
import { useStyles } from './styles'
import {
    Box,
    Typography,
    Grid,
    Container,
    Tabs,
    Tab,
    Card,
    CardContent,
    Button,
} from '@material-ui/core'
import { BillboardBannerApoie } from "app/components/banners/billboard";
import CourseCard from 'app/components/cards/Course/model-3'
import Skeleton from 'app/components/cards/Course/model-3/skeleton'
import { AdsTop } from "app/components/ads/freestar";
import studentApi from 'app/services/student-api';


export default (props) => {

  const classes = useStyles();
  const history = useHistory();
  const [coursesInProgress, setCoursesInProgress] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabId, setTabId] = React.useState(0);

  const _goToDiscover = () => {
    history.push('/dashboard/discover')
  };

  const handleChange = (event, newValue) => {
    setTabId(newValue);
  };

  useEffect(() => {
    studentApi.get(`/subscribed-courses`).then(response => {
      const courses = response.data;
      setCompletedCourses(courses?.filter((course) => course.percentage >= 100) || [])
      setCoursesInProgress(courses?.filter((course) => course.percentage < 100) || [])
    }).catch(error => {
        console.warn(error)
    }).finally( () => {
        setLoading(false)
    })
  }, [])

  function Loader() {
    return (
      <Grid container spacing={2} direction="row" alignItems="stretch">
        {[0,1,2,3,4,5,6,7].map( (item,key) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={3} key={key}>
                <Skeleton key={`sk-${key}`} />
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  function TabContent({index, courses}) {
    const courseCards = useMemo(() => {
      return courses.map((course, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={6} md={6} lg={3} key={course.id}>
            <CourseCard key={course.id} {...course} showSummary={false} showProgress={true} />
          </Grid>
        </React.Fragment>
      ));
    }, [courses]);

    return (
      <Box hidden={index !== tabId}>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          {courseCards}
        </Grid>
      </Box>
    );
  }

  function FallbackNoCourses() {
    return (
      <Box className={classes.noCoursesFallback} mx="auto">
        <Card className={classes.noCoursesCard}>
          <CardContent>
            <Typography variant="h2" component="h2" className={classes.noCoursesTitle}>
              Você não tem cursos em andamento
            </Typography>
            <Typography variant="body2" component="p" className={classes.noCoursesSubtitle}>
              Aproveite e comece a estudar agora mesmo! <span role="img" aria-label="smile">😃</span>
            </Typography>
            <Button size="large" color="secondary" variant="contained" onClick={_goToDiscover}>Explorar cursos</Button>
          </CardContent>
        </Card>
      </Box>
    )
  }



  return (
    <React.Fragment>
      <Box component="div" mb={3}>
        <Container maxWidth="xl">
          <Typography component="h1" variant="h1" className={classes.title}>Meus cursos</Typography>
          <Box mb={2}>
            <BillboardBannerApoie fallbackBanner={<AdsTop/>} />
          </Box>
          <Box mb={4}>
            <Tabs
              value={tabId}
              onChange={handleChange}
              textColor="secondary"
              aria-label="Courses status"
            >
              <Tab
                label="Em andamento"
                disabled={loading  || coursesInProgress.length === 0}
              />
              <Tab label="Concluídos" disabled={loading || completedCourses.length === 0} />
            </Tabs>
          </Box>
          {loading && <Loader />}
          <TabContent index={0} courses={coursesInProgress} />
          <TabContent index={1} courses={completedCourses} />
          {
            !loading &&
            coursesInProgress.length === 0 &&
            <FallbackNoCourses />
          }
        </Container>
      </Box>
    </React.Fragment>
  )
}
