/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
} from "@material-ui/core";

import coreApi from "app/services/core-api";
import systemApi from "app/services/system-api";
import studentApi from "app/services/student-api";

import { useSnackbar } from "app/contexts/SnackbarContext";

import DrawerLesson from "app/components/navs/drawers/lesson";
import { BottomMobileNavigation } from "app/components/navs/bottom";
import QuizzLayout from "app/components/layouts/Quizz";
import LessonTextLayout from "app/components/layouts/LessonText";
import PlayerLayout from "app/components/layouts/Player/Player";

import { lessonType } from './lesson-type'
import { useStyles } from "./styles";

const Lesson = (props) => {
  const classes = useStyles();

  const { setSnackValues } = useSnackbar();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [lesson, setLesson] = React.useState({});
  const [course, setCourse] = React.useState({});
  const [curriculum, setCurriculum] = React.useState([]);
  const [ads, setAds] = React.useState([]);
  const [progress, setProgress] = React.useState([]);
  const [isLoadingCurriculum, setIsLoadingCurriculum] = React.useState(true);
  const [isLoadingProgress, setIsLoadingProgress] = React.useState(true);
  const [isLoadingLesson, setIsLoadingLesson] = React.useState(true);
  const [isLoadingAds, setisLoadingAds] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { courseSlug, lessonSlug } = props.computedMatch.params;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  useEffect(() => {

    setIsLoadingCurriculum(true);
    setIsLoadingProgress(true);

    const fetchResult = async () => {

      const [course, ads] = await Promise.allSettled([
        coreApi.get(`/courses/${courseSlug}?components=content,curriculum`),
        systemApi.get(`vendor?context=lesson&courseId=${courseSlug}&lessonId=${lessonSlug}`)
      ])

      const {
          content,
          curriculum
      } = course.value?.data
      setCourse(content)
      setAds(ads.value?.data || [])
      setisLoadingAds(false)

      let i = 0;
      let matchLesson;
      do {
        const module = curriculum[i];
        matchLesson = module.lessons.find((_lesson) => _lesson.slug === lessonSlug)
        i++;
      } while(i < curriculum.length && !matchLesson)

      const [progress, lesson] = await Promise.allSettled([
        studentApi.get(`/subscribed-courses/${content.id}/progress`),
        studentApi.get(`/subscribed-courses/${content.id}/lesson/${matchLesson.id}`),
      ])
      setProgress(progress.value?.data);
      setCurriculum(curriculum);
      setLesson(lesson.value?.data);
      setIsLoadingCurriculum(false);
      setIsLoadingProgress(false);
      setIsLoadingLesson(false);
    }
    fetchResult().catch(console.error);

  },[courseSlug, lessonSlug])

  const finishText = () => {
    if (lesson.finished) return;
    setIsSubmitting(true);
    studentApi
      .post(`/subscribed-courses/${course.id}/finish-lesson`, {
        module: 'text',
        lessonId: lesson.id,
      })
      .then((response) => {
        setSnackValues({
          message: "Oba! Aula concluída com sucesso.",
          open: true,
        });
        lesson.finished = true;
        setLesson({ ...lesson });
      })
      .catch((err) => {
        setSnackValues({
          message: "Ops! Houve uma falha ao finalizar aula. Tente novamente",
          open: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const finishQuizz = (answers) => {
    if (lesson.finished) return;
    setIsSubmitting(true);
    studentApi
      .post(`/subscribed-courses/${course.id}/finish-lesson`, {
        lessonId: lesson.id,
        module: "quiz",
        quiz: answers.map((answer) => {
          return {
            answerId: Number(answer.answer_id),
            questionId: Number(answer.question_id)
          }
        }),
      })
      .then((response) => {
        lesson.finished = true;
        lesson.evaluation = response.data.evaluation;
        setLesson({ ...lesson });
      })
      .catch((err) => {
        setSnackValues({
          message:
            "Ops! Houve uma falha ao finalizar avaliação. Tente novamente",
          open: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        window.scrollTo(0, 0);
      });
  };

  return (
    <React.Fragment>
      <Box component="div" display="flex" className={classes.root}>
        <Box
          component="main"
          display="flex"
          flexDirection="column"
          className={classes.wrap}
        >
          {isLoadingLesson ? (
            <Box
              component="div"
              alignItems="center"
              display="flex"
              className={classes.loading}
              height="100%"
              justifyContent="center"
            >
              <CircularProgress size={80} />
            </Box>
          ) :
          lesson.type === lessonType.VIDEO ? (
            <PlayerLayout
              lesson={lesson}
              course={course}
              ads={ads}
              isLoadingAds={isLoadingAds}
            />
          ) :
          lesson.type === lessonType.TEXT ? (
            <LessonTextLayout
              _finishLesson={finishText}
              lesson={lesson}
              course={course}
              ads={ads}
              isSubmitting={isSubmitting}
              isLoadingAds={isLoadingAds}
            />
          ) :
            <QuizzLayout
              _finishQuizz={finishQuizz}
              lesson={lesson}
              course={course}
              ads={ads}
              isSubmitting={isSubmitting}
              isLoadingAds={isLoadingAds}
            />
          }
        </Box>

        <DrawerLesson
          toggleDrawer={toggleDrawer}
          open={openDrawer}
          lesson={lesson}
          course={course}
          curriculum={curriculum}
          progress={progress}
          lessonSlug={lessonSlug}
          isLoadingCurriculum={isLoadingCurriculum}
          isLoadingProgress={isLoadingProgress}
        />
      </Box>
      <BottomMobileNavigation
        course={course}
        lesson={lesson}
        lessonSlug={lessonSlug}
        curriculum={curriculum}
        progress={progress}
      />
    </React.Fragment>
  );
};

export default Lesson;
