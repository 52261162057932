import styled, { keyframes } from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

import BackgroundImg from 'assets/images/signin_bg.png';

const useStyles = makeStyles((theme) => ({
  leftContent: {
    backgroundColor: "#FFF",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4)
    }
  },
  content: {
    maxWidth: "70%",
    minHeight: "100vh",
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      maxWidth: "80%",
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  rightContent: {
    background: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.primary['main'],
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      marginBottom: theme.spacing(2)
    },
  },
  logo: {
    width: 130,
    maxWidth: 130,
    [theme.breakpoints.only('xs')]: {
        width: "100%",
        maxWidth: 154
    },
  },
  card: {
    width:"100%",
    maxWidth: 400,
    padding: 0,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    }
  },
  cardLabel: {
    width: 110,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[600],
    fontWeight: 700,
    fontFamily: 'Raleway, sans-serif',
  },
  cardNumber: {
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.grey[800],
    fontWeight: 900,
  },
  cardBorder: {
    height: 10,
    width: "50%",
    borderBottom: `15px solid ${theme.palette.secondary['main']}`
  },
  borderLeft: {
    marginRight: theme.spacing(1),
    borderRadius: "0 16px 0 0"
  },
  borderRight: {
    marginLeft: theme.spacing(1),
    borderRadius: "16px 0 0"
  },
  welcomeText: {
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(2),
  },
  thanks: {
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(3),
  },
}))

export { useStyles }

// Use Styled Components
const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div `
  animation: ${apearFromLeft} 1s
`;
