import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect';

import {
  Container,
  Box,
  IconButton,
  Button,
  Typography,
  Chip,
  Paper,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  LinearProgress,
  ListItemIcon,
  Snackbar,
  CircularProgress,
  Link,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import VideocamIcon from '@material-ui/icons/Videocam';
import SchoolIcon from '@material-ui/icons/School';
import GroupIcon from '@material-ui/icons/Group';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Module from 'app/components/accordions/Module/model-2'
import RelatedCourses from 'app/components/carousels/RelatedCourses'

import {AdsTop, AdsBottom, AdsIncontent, AdsSideBar} from "app/components/ads/freestar";

import PresentationVideo from 'app/components/Modals/PresentationVideo'

import Campaigns from "app/components/carousels/Campaigns/ads";


import { useStyles } from './styles'

import studentApi from 'app/services/student-api'

import Thumbnail from 'app/components/cards/Course/thumbnail/Image';
import MediaColor from 'app/components/cards/Course/thumbnail/MediaColor';

export default (props) => {

  const classes = useStyles()

  const {
    isLoadingContent,
    isLoadingCurriculum,
    isLoadingInstructors,
    isLoadingProgress,
    isLoadingAds,
    content,
    curriculum,
    instructors,
    progress,
    ads,
  } = props;
  const curriculumSection = useRef(null);
  const [isEnrolling, setIsEnrolling] = React.useState(false);
  const [firstEnroll, setFirstEnroll] = React.useState(false);
  const [courseUrl, setCourseUrl] = React.useState('');
  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  });
  const [openPresentationVideoDialog, setOpenPresentationVideoDialog] = React.useState(false);
  const handlePresetatonVideoDialog = (open) => (event) => {
    setOpenPresentationVideoDialog(open);
  };

  const presentationVideo = content.presentationVideo || null
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackValues({ open: false });
  };

  const continueCourse = (url) => {
      window.location.href = (`/dashboard/course/${url}`);
  }


  const enrollMe = () => {

    setIsEnrolling(true);

    studentApi.post(`enroll`, { courseId: content.id })
      .then(response => {
        setFirstEnroll(true)
        if(content?.sellerUrl)
          window.open(content.sellerUrl, '_blank');
      }).catch((err) => {
        setSnackValues({ message: "Houve uma falha ao realizar sua matrícula. Tente novamente.", open: true });
        console.warn(err.data)
      }).finally(() => {
        setIsEnrolling(false);
      })
  }

  function ProducerSocialLink(props) {

    switch (props.type) {
      case 1:
        return <FacebookIcon fontSize="inherit" />
      case 2:
        return <InstagramIcon fontSize="inherit" />
      case 3:
        return <YouTubeIcon fontSize="inherit" />
      case 4:
        return <TwitterIcon fontSize="inherit" />
      case 5:
        return <LinkedInIcon fontSize="inherit" />
      case 6:
        return <LanguageIcon fontSize="inherit" />

      default:
        return null;
    }
  }

  const _handlePresentationVideo = () => {
    if (!presentationVideo) return
    setOpenPresentationVideoDialog(true)
  }

  function Producer(producer) {

    return (
      <Card className={classes.instructorCard} elevation={0}>
        <CardHeader
          className={classes.instructorCardHeader}
          disableTypography
          avatar={
            <Avatar aria-label="recipe" alt={producer.displayName} className={classes.instructorAvatar} src={producer.thumbnail}>A</Avatar>
          }
          title={<Typography className={classes.instructorName}>{producer.displayName}</Typography>}
          subheader={
            <>
              <Typography className={classes.instructorJobTitle} component="span">{producer.jobTitle}</Typography>
              <Box component="div" display="flex" flexDirection="row">
                {producer.links.map((link, index) => {
                  return (
                    <IconButton size="small" className={classes.instructorSocialButton} href={link.link} key={index} target="_blank"><ProducerSocialLink {...link} /></IconButton>
                  )
                })}
              </Box>
            </>
          }
        />
        <CardContent className={classes.instructorCardContent}>
          <Box mb={2} lineHeight={2} className={classes.instructorDescription} dangerouslySetInnerHTML={{ __html: producer.description.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
        </CardContent>
      </Card>
    )

  }

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Box component="div" mb={7}>
        <Container>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12} md={8} lg={6}>
              <Box component="div" mb={3}>
                {isLoadingContent ?
                  (
                    <React.Fragment>
                      <Skeleton animation="wave" variant="rect" height={30} width={70} />
                      <Skeleton animation="wave" variant="text" height={80} style={{ marginBottom: 18 }} />
                      {[0, 1, 2].map((item, index) => <Skeleton key={index} animation="wave" variant="text" />)}
                    </React.Fragment>) :
                  <React.Fragment>
                    <Chip label={content.category.title} style={{ backgroundColor: `${content.category?.color}` }} className={classes.chip} />
                    <Typography component="h1" variant="h1" className={classes.courseTitle}>{content.title}</Typography>
                    <Typography component="p">{content.summary}</Typography>
                  </React.Fragment>
                }
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              {isMobile && <Box component="div" mb={4}><AdsTop /></Box>}
              <Card>
                {
                  isLoadingContent ?
                    <Skeleton animation="wave" variant="rect" height={160} /> :
                    content.presentationVideo ? (
                      <CardActionArea
                        onClick={_handlePresentationVideo}
                      >
                        <Box component="div" style={{ color: "#FFF", backgroundColor: 'rgba(0,0,0,.5)' }} position="absolute" zIndex={2} height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                          <PlayArrowIcon style={{ fontSize: 50 }} className={classes.courseMediaPreviewIcon} />
                          <Typography style={{ fontWeight: 'bold' }} component="span">Ver prévia do curso</Typography>
                        </Box>
                        {
                          content.imageUploadedAt ?
                            <Thumbnail title={content.title} id={content.uuid} /> :
                            <MediaColor color={content.category.color} />
                        }
                      </CardActionArea>
                    ) : (
                      content.imageUploadedAt ?
                        <Thumbnail title={content.title} id={content.uuid} /> :
                        <MediaColor color={content.category.color} />
                    )
                }
                {
                  isLoadingProgress ? (
                    <React.Fragment>
                      <Box component="div" width="95%" mx="auto" py={2}>
                        <Skeleton animation="wave" variant="text" />
                        <Skeleton animation="wave" variant="text" width="80%" />
                        <Skeleton animation="wave" height={70} />
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {progress?.certificate ? (
                        <React.Fragment>
                          <CardContent>
                            <Box display="flex" alignItems="center">
                              <Box mr={1} flexGrow={1}>
                                <LinearProgress variant="determinate" value={100} />
                              </Box>
                              <Box>
                                <Typography variant="body2" color="textSecondary">100%</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Box component="div" width="95%" mx="auto" mb={1}>
                              <Button fullWidth color="primary" variant="contained" className={classes.ctaCourse} disabled={progress.certificate.status !== 3} href="/dashboard/user/certificates">
                                {(progress.certificate.status === 1 || progress.certificate.status === 2) && 'Gerando certificado'}
                                {progress.certificate.status === 3 && 'Obter certificado'}
                                {progress.certificate.status === 4 && 'Erro ao gerar certificado'}
                              </Button>
                            </Box>
                          </CardActions>
                        </React.Fragment>
                      ) : progress?.isValidStudent ? (
                        <React.Fragment>
                          <CardContent>
                            <Box display="flex" alignItems="center">
                              <Box mr={1} flexGrow={1}>
                                <LinearProgress variant="determinate" value={Math.trunc(progress.completedLessonIds.length*100/content.totalClasses)} />
                              </Box>
                              <Box>
                                <Typography variant="body2" color="textSecondary">{Math.trunc(progress.completedLessonIds.length*100/content.totalClasses)}%</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Box component="div" width="95%" mx="auto" mb={1}>
                              <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={() => scrollDown(curriculumSection)}
                                // onClick={() => continueCourse(`${content.slug}/lesson/${progress.nextLesson}`)}
                                className={classes.ctaCourse}>CONTINUAR CURSO</Button>
                            </Box>
                          </CardActions>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <CardContent>
                            <Typography component="p">Matricule-se para iniciar o curso e estudar gratuitamente.</Typography>
                          </CardContent>
                          <CardActions>
                            <Box component="div" width="95%" mx="auto" mb={1}>
                              {
                                firstEnroll ?
                                  <Button color="primary" fullWidth variant="contained" onClick={() => continueCourse(`${content.slug}/lesson/${curriculum.at(0)?.lessons?.at(0)?.slug}`)} className={classes.ctaCourse}>COMEÇAR CURSO</Button>
                                :
                                  <Button id="enrollCourse" fullWidth color="secondary" variant="contained" className={classes.ctaCourse} disabled={isEnrolling} onClick={enrollMe}>{isEnrolling ? <CircularProgress size={30} /> : 'MATRICULAR-SE'}</Button>
                              }
                            </Box>
                          </CardActions>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )
                }
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box component="div" mb={7}>
        <Paper elevation={0} className={classes.courseContent}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box component="div" my={3}>
                  {
                    isMobile ? <AdsIncontent /> : <AdsTop />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>

                <Box component="div" mb={6}>
                  <Typography component="h2" variant="h2" className={classes.courseSectionTitle}>Sobre o curso</Typography>
                  {isLoadingContent ?
                    [0, 1, 2, 4, 5, 6].map((item, index) => <Skeleton key={index} animation="wave" variant="text" />) :
                    <Box component="div" dangerouslySetInnerHTML={{ __html: content.description }} />
                  }
                </Box>

                <Box ref={curriculumSection} component="div" mb={6}>
                  {
                    isLoadingAds ?
                    <Skeleton variant="rect" width='100%' height={190} /> :
                    ads.length > 0 ?
                      <Campaigns campaigns={ads} /> : <AdsIncontent />
                  }
                </Box>

                <Box component="div" mb={6}>
                  <Typography component="h2" variant="h2" className={classes.courseSectionTitle}>Conteúdo do curso</Typography>
                  {isLoadingCurriculum && isLoadingProgress ?
                    [0, 1, 2, 4].map((item, index) => <Skeleton key={index} animation="wave" height={60} />) :
                    curriculum.map((module, index) => <Module key={index} enableLink={progress.isValidStudent ?? false} courseSlug={content.slug} progress={progress} {...module} />)
                  }
                </Box>

                <Box component="div" mb={6}>
                  <Typography component="h2" variant="h2" className={classes.courseSectionTitle}>Para quem é indicado</Typography>
                  <List disablePadding>
                    {isLoadingContent ?
                      <React.Fragment>
                        {[0, 1, 2].map((item, index) =>
                          <ListItem disableGutters key={index}>
                            <ListItemIcon>
                              <ArrowForwardIosIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Skeleton animation="wave" variant="text" width={300} />}
                            />
                          </ListItem>
                        )}
                      </React.Fragment> : (
                        content.targets?.length > 0 ? content.targets.map((item, index) => {
                          return (
                            <ListItem key={index} disableGutters>
                              <ListItemIcon>
                                <ArrowForwardIosIcon fontSize="small" color="primary" />
                              </ListItemIcon>
                              <ListItemText
                                primary={item.target}
                              />
                            </ListItem>)
                        }) : (
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <ArrowForwardIosIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary="Todo tipo de público"
                            />
                          </ListItem>
                        )
                      )
                    }
                  </List>
                </Box>

                {!isLoadingInstructors && instructors.length > 0 ?
                  (
                    <Box component="div" mb={6}>
                      <Typography component="h2" variant="h2" className={classes.courseSectionTitle}>{instructors.length > 1 ? 'Instrutores' : 'Instrutor'}</Typography>
                      {instructors.map((producer, index) => {
                        return <Producer {...producer} key={index} />
                      })}
                    </Box>
                  ) : ''}

              </Grid>

              <Grid item xs={12} md={4}>
                <Box component="div" mb={6}>
                  <Box mb={3}>
                    <List disablePadding>
                      {isLoadingContent ?
                        [0, 1, 2, 4, 5].map((item, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <Skeleton variant="circle" width={40} height={40} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={<Skeleton animation="wave" variant="text" />}
                                secondary={<Skeleton animation="wave" variant="text" />}
                              />
                            </ListItem>
                          )
                        }) :
                        (
                          <React.Fragment>
                            {
                              content.totalEnrollments > 10 &&
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <GroupIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={`${content.totalEnrollments} alunos`}
                                  secondary='Também estão fazendo este curso.'
                                />
                              </ListItem>
                            }
                            {content.status === 2 ?
                              <React.Fragment>
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar>
                                      <VideocamIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="Curso em gravação"
                                    secondary='Estamos realizando a gravação deste curso, mas você já pode ir assistindo as aulas.'
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar>
                                      <SchoolIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="Certificado indisponível"
                                    secondary='O certificado estará disponível assim que todas as aulas forem gravadas.'
                                  />
                                </ListItem>
                              </React.Fragment> :
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <SchoolIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Certificado disponível"
                                  secondary='Ganhe o certificado após a conclusão.'
                                />
                              </ListItem>
                            }
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <VideoLibraryIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${content.totalClasses} ${content.totalClasses === 1 ? 'aula' : 'aulas'}`}
                                secondary={`${content.totalClasses === 1 ? 'Disponível' : 'Disponíveis'} ${content.status === 1 ? 'para você estudar' : 'até o momento'}`}
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <EventAvailableIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary="Prazo flexível"
                                secondary='Estude no seu tempo e no seu ritmo.'
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <AllInclusiveIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary="Acesso ilimitado e vitalício"
                                secondary='Comece imediatamente e aprenda em seu próprio cronograma.'
                              />
                            </ListItem>
                          </React.Fragment>
                        )}
                    </List>
                  </Box>
                  {!isMobile && <Box><AdsSideBar /></Box>}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <AdsBottom />
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Box>

      <Box component="div" mb={7}>
        <Container>
          {!isLoadingContent && (
            <React.Fragment>
              <Box component="div" display="flex" flexDirection="row" alignItems="center" mb={4}>
                <Typography component="h2" variant="h2" className={[classes.courseSectionTitle, classes.courseSectionTitleRelatedCourses].join(" ")}>Mais cursos de {content.category.title}</Typography>
                <Link href={`/dashboard/discover/${content.category.slug}`} className={[classes.seeMore,classes.relatedCoursesAction].join(" ")}>
                  Ver tudo <ChevronRightIcon />
                </Link>
              </Box>
              <RelatedCourses category={content.category?.slug} />
            </React.Fragment>
          )}
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackValues.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message={snackValues.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <PresentationVideo
        open={openPresentationVideoDialog}
        toggleDialog={handlePresetatonVideoDialog}
        presentationVideo={presentationVideo}
      />
    </>
  );
}
