import plusTitle from "assets/images/title-plus.png";
import posTitle from "assets/images/title-pos.png";
import academicTitle from "assets/images/title-academic.png";

const plans = [
  {
    title: "Pacote Kultivi+",
    thumbnail: plusTitle,
    priceOne: "R$47/ano",
    priceTwo: "R$9.70/mês",
    priceThree: "Antes: R$89,00",
    id: "kultivi_mais_2",
    cta: "Assinar Plano Kultivi+",
    path: {
      month: `${process.env.REACT_APP_CHECKOUT_URL}/${process.env.REACT_APP_IUGU_PRODUCT_MONTHLY}`,
    },
  },
  {
    title: "Pacote Kultivi Concursos",
    priceOne: "R$99,90/ano",
    priceTwo: "R$29.90/mês",
    thumbnail: academicTitle,
    id: "kultivi_academia_2",
    cta: "Assinar Plano Kultivi Concursos",
    path: {
      month: `${process.env.REACT_APP_CHECKOUT_URL}/${process.env.REACT_APP_IUGU_PRODUCT_CONCURSOS}`,
    },
  },
];

export default plans;
