import React, {useEffect, useState} from 'react'

import studentApi from 'app/services/student-api'
import QuizCard from 'app/components/cards/Quiz'
import Skeleton from 'app/components/cards/Quiz/skeleton'
import {
  Box,
  Typography,
 } from '@material-ui/core';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useStyles } from './styles'

export default (props) => {

  const classes = useStyles()

  // States
  const [quizzes, setQuizzes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    studentApi.get(`practice-exams`).then(response => {
      setQuizzes(response.data)
    }).catch(error => {
      console.warn(error)
    }).finally( () => {
      setLoading(false)
    })
  }, [])

  const responsive = {
      large: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      }
  }

  return (

      <Box component="div">
          {!loading && quizzes.length > 0 && <Typography component="h2" className={classes.title}>Meus Simulados</Typography>}
          <Carousel
              itemClass={classes.carouselItem}
              responsive={responsive}
              arrows={!loading}
              partialVisible
            >
            {loading ?
              [0,1,2,3,4].map( (key) => {
                return <Skeleton key={key} />
              }) :
              quizzes.map((quiz) => (
                <QuizCard key={quiz.id} {...quiz} />
              ))
            }
          </Carousel>
      </Box>

  )
}
