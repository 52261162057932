import React from 'react'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'

const WaitingConfirmationRoute = ({
  component: Component,
  isAuthenticated = false,
  isConfirmed  = false,
  ...rest
}) => {
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {

        if(isAuthenticated && !isConfirmed)
          return <Component {...rest} />

        const redirectTo = !isAuthenticated ? '/' : '/dashboard'

        return <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location }
            }}
          />
      }}
    />
  )
}

export default WaitingConfirmationRoute;
