import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles'

import kultiviThemeLight from 'core/themes/theme1/Light'

import App from './App'

import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG
}

TagManager.initialize(tagManagerArgs)
ReactPixel.init(process.env.REACT_APP_FB_PIXEL);
ReactPixel.pageView();


const root = document.getElementById('root')
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={kultiviThemeLight}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  root
);
