Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var purple = {
  light: '#dfd4f7',
  main: '#7b42f0',
  dark: '#2e0d73',
};

var _default = purple;
exports.default = _default;