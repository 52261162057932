import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "4rem",
    width: "100%",
    background: "#0F5B7A",
  },
  navigation: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  listLinks: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  links: {
    cursor: "pointer",
    width: "auto",
    color: "#FFFFFF",
    fontSize: theme.typography.pxToRem(16),
  },
  iconMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },

  titleIcon: {
    fontSize: theme.typography.pxToRem(14),
    color: "#FFFFFF",
  },
}));

export { useStyles };
