import React, {useState, useCallback} from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  IconButton,
  TablePagination,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useSnackbar } from 'app/contexts/SnackbarContext'

import api from 'app/services/api';

import { useStyles } from './styles'

export default (props) => {

  const {
    open,
    toggleDialog,
  } = props;

  const { setSnackValues } = useSnackbar();
  const classes  = useStyles();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const getInvoices = useCallback(() => {
    api.get(`users/me/invoices`)
    .then( async (response) => {
      const {invoices} = response.data;
      setRows(invoices);
    })
    .catch((error) => {
      setSnackValues({message:error.response.data?.message ?? "Falha ao consultar faturas. Tente novamente.",open:true})
    })
    .finally(() => {
      setLoading(false)
    })
  })

  const openInvoice = (url) => {
    window.open(`${url}`, '_blank');
  }

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        open={open}
        maxWidth='md'
        fullWidth={true}
        TransitionProps={{
          onEntering: () => {
            setLoading(true);
          },
          onEntered: () => {
            getInvoices()
          }
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
          <Box component="div" display="flex" justifyContent="flex-end">
            <IconButton aria-label="close" className={classes.closeButton} onClick={()=>toggleDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          Faturas
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box component="div" display="flex" alignItems="center" flexDirection="column" mb={2}>
              <Box pt={4} mb={5}><CircularProgress size={80} color="primary" /></Box>
            </Box>
          ) : (
            rows.length > 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Criada em</TableCell>
                        <TableCell align="right">Vencimento</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : rows
                        ).map((row) => (
                          <TableRow hover key={row.id}>
                            <TableCell component="th" scope="row">{row.created_at}</TableCell>
                            <TableCell align="right">{row.due_date}</TableCell>
                            <TableCell align="right">{row.total}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="close" className={classes.closeButton} onClick={()=> openInvoice(row.secure_url)}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Faturas por página"
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais do que ${to}`}` }
                />
              </>
            ) : (
              <Box mb={3}><Typography>Nenhuma fatura disponível</Typography></Box>
            )
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
