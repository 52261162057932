import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(22),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
        textAlign: 'center'
    }
  },
  noCoursesFallback: {
    maxWidth: 600,
    textAlign: 'center'
  },
  noCoursesCard: {
    padding: theme.spacing(3)
  },
  noCoursesTitle: {
    fontWeight: 800,
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(22),
    marginBottom: theme.spacing(2),
  },
  noCoursesSubtitle: {
    marginBottom: theme.spacing(1),
  }
}))

export { useStyles }
