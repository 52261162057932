/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";

import HeroImage from "assets/images/hero-klubi.png";

import { useStyles } from "./styles";

function HeroSectionKlubi({ allPlansRef }) {
  const classes = useStyles();

  function handleBackClick() {
    allPlansRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Box className={classes.content} component="section">
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.contentLeft} component="div">
          <Box className={classes.contentInfo} component="div">
            <Typography variant="h1" className={classes.infoTitle}>
              A gente faz o que ninguém viu: benefícios e
              descontos... Num só lugar!
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Seja um apoiador da Kultivi e nos ajude a continuar produzindo ainda mais!
            </Typography>
            <Button
              className={classes.cta}
              variant="contained"
              onClick={handleBackClick}
            >
              Quero apoiar a Kultivi 🚀
            </Button>
          </Box>
        </Box>

        <Box className={classes.contentRight} component="div">
          <img src={HeroImage} className={classes.heroThumbnail} alt="" />
        </Box>
      </Container>
    </Box>
  );
}

export default HeroSectionKlubi;
