import React, {useState} from 'react'

import {
  TextField,
  CircularProgress,
  Box,
  Button,
} from '@material-ui/core'

import { useFormik } from "formik";
import { useSnackbar } from "app/contexts/SnackbarContext";
import { useAuth } from "app/contexts/AuthContext";
import accountApi from 'app/services/account-api';
import { changeUsernameValidator } from 'app/validators/auth'


const FormChangeUsername = (props) => {

  const { refreshUserInfo } = useAuth();
  const { setSnackValues } = useSnackbar();
  const [sending, setSending] = useState(false);

  const {
    setChanged,
  } = props

  const formik = useFormik({
    initialValues: {
      email: "",
      emailConfirmation: "",
      password: "",
    },
    validationSchema: changeUsernameValidator,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      accountApi.patch('me/change-username', {
        email: values.email,
        password: values.password
      })
      .then((result) => {
        setSnackValues({
          message: `Acesso alterado com sucesso!`,
          open: true,
        });
        refreshUserInfo({email: formik.values.email})
        setChanged(true)
      })
      .catch((error) => {
        const { response } = error;
        if (response && response.data) {
          const {
            data: { errors },
          } = response;
          setSnackValues({
            message: `${errors.at(0)}`,
            open: true,
          });
        }
      })
      .finally( () => actions.setSubmitting(false))
    },
  });

  const handleCopy = (event) => {
    event.preventDefault();
  }

  const handlePaste = (event) => {
    event.preventDefault();
  }


  return (
    <>
      {
        sending ?
          <CircularProgress /> :
          <form name="form-change-username" onSubmit={formik.handleSubmit}>
            <Box mb={2}>
              <TextField
                autoFocus={true}
                label="Seu novo e-mail"
                id="email"
                name="email"
                variant="outlined"
                type="email"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                onCopy={handleCopy}
                onPaste={handlePaste}
                error={
                  formik.touched.email &&
                  Boolean(formik.errors.email)
                }
                helperText={
                  formik.touched.email &&
                  formik.errors.email
                }
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Confirmar e-mail"
                id="emailConfirmation"
                name="emailConfirmation"
                variant="outlined"
                type="email"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.emailConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                onCopy={handleCopy}
                onPaste={handlePaste}
                error={
                  formik.touched.emailConfirmation &&
                  Boolean(formik.errors.emailConfirmation)
                }
                helperText={
                  formik.touched.emailConfirmation &&
                  formik.errors.emailConfirmation
                }
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Digite sua senha"
                id="password"
                name="password"
                variant="outlined"
                type="password"
                fullWidth={true}
                required={true}
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                onCopy={handleCopy}
                onPaste={handlePaste}
                error={
                  formik.touched.password &&
                  Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.password &&
                  formik.errors.password
                }
              />
            </Box>
            <Box component="div" textAlign="right" mb={2}>
              <Button
                color="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}>
                { formik.isSubmitting ? (
                  <CircularProgress size={30} color="inherit" />
                ) : 'Enviar' }
              </Button>
            </Box>
          </form>
      }
    </>
  )

}

export default FormChangeUsername
