import React from 'react'

import api from 'app/services/api'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'

import { useFormik } from 'formik';
import * as yup from 'yup';

import { useStyles } from './styles'

const validationSchema = yup.object({
  report: yup
    .string('Digite o problema')
    .required('Campo obrigatório'),
});

export default (props) => {

  const { open, onClose } = props

  const classes = useStyles()

  const [snackValues, setSnackValues] = React.useState({
    message: '',
    open: false,
  });

  const formik = useFormik({
    initialValues: {
      report: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values,actions) => {
        
        actions.setSubmitting(true);
       
        api.put(`....`, values)
        .then((response) => {
            setSnackValues({message:"Obrigado por ajudar a Kultivi a melhorar cada vez mais.",open:true});
            actions.resetForm()
        })
        .catch((err) => {
            setSnackValues({message:"Ops! Falha ao atualizar seus dados. Tente novamente.",open:true});
            const errors = err.response.data
            for (let index = 0; index < errors.length; index++) {
                const error = errors[index];
                var setErr = {};
                setErr[`${error.field}`] = error.message;
                actions.setErrors(setErr)
            }
        })
        .finally( () => {
            actions.setSubmitting(false);
        })
    },
  });

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Reportar um problema</DialogTitle>
        <DialogContent>
          <form name="form-report" onSubmit={formik.handleSubmit}>
            <FormControl
              fullWidth
            >
              <TextField
                placeholder="Por favor, nos dê detalhes do problema encontrado"
                id="report"
                name="report"
                variant="filled"
                type="textarea"
                multiline
                rows={5}
                rowsMax={8}
                value={formik.values.report}
                onChange={formik.handleChange}
                error={formik.touched.report && Boolean(formik.errors.report)}
                helperText={formik.touched.report && formik.errors.report}
                fullWidth={true}
                required={true}
                autoComplete="off"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button color="primary" type='submit'>Enviar</Button>
        </DialogActions>
    </Dialog>
  )

}
