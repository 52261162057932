import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    carouselItem: {
        paddingRight: theme.spacing(1),
        paddingBottom: '6px',
    },
    title: {
        fontWeight: 800,
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(22),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center'
        }
    }
}))

export { useStyles }