import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    width:"100%",
    maxWidth: 400,
    padding: 0,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    }
  },
  cardLabel: {
    width: 110,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[600],
    fontWeight: 700,
    fontFamily: 'Raleway, sans-serif',
  },
  cardNumber: {
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.grey[800],
    fontWeight: 900,
  },
  cardBorder: {
    height: 10,
    width: "50%",
    borderBottom: `15px solid ${theme.palette.secondary['main']}`
  },
  borderLeft: {
    marginRight: theme.spacing(1),
    borderRadius: "0 16px 0 0"
  },
  borderRight: {
    marginLeft: theme.spacing(1),
    borderRadius: "16px 0 0"
  },
}))

export { useStyles }
