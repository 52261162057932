import React from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@material-ui/core'

import YouTube from 'react-youtube'

import { useStyles } from './styles'

import { useSnackbar } from "app/contexts/SnackbarContext";

import studentApi from 'app/services/student-api';

export default (props) => {

  const classes = useStyles()
  const { setSnackValues } = useSnackbar();
  const { open, toggleDialog, pillId } = props

  const [loading, setLoading] = React.useState(true);
  const [pill, setPill] = React.useState(null);

  React.useEffect(() => {

    if(!pillId) return;

    const fetchData = async () => {
      try {
        setLoading(true)
        const { data } = await studentApi.get(`/pills/${pillId}`);
        setPill(data)
      } catch (error) {
        setSnackValues({
          message: "Ops! Falha ao carregar dados. Tente novamente.",
          open: true,
        });
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pillId])


  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
      color: 'white',
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      onClose={toggleDialog(false, null)}
      disableScrollLock={true}
      PaperProps={{
        style: {
          maxWidth: 335,
          width: '90%',
          margin: 'auto',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent
        style={{padding: 0, margin: 0, backgroundColor: 'transparent'}}
      >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {loading ?
              <CircularProgress size={60} color="primary" /> :
              <YouTube
                onEnd={toggleDialog(false, null)}
                videoId={pill?.embed}
                containerClassName={`${classes.videoWrapperContainer}`}
                opts={opts}
              />
            }
          </Box>
      </DialogContent>
    </Dialog>
  )
}
