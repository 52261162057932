import React from 'react'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'


const PublicRoute = ({
  component: Component,
  isAuthenticated = false,
  isConfirmed = false,
  ...rest
}) => {

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {

        if(!isAuthenticated)
          return <Component {...rest} />

        const redirectTo = isConfirmed ? '/dashboard' : '/confirm-account'

        return <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location }
            }}
          />
      }}
    />
  )
}

export default PublicRoute;
