import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    padding: "5rem 2rem",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#292929",
    alignItems: "center",
  },
  title: {
    color: "#FFFFFF",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "Raleway, sans-serif",
    fontSize: theme.typography.pxToRem(27),
    marginBottom: "1.5rem",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  titleBankNext: {
    color: "#FFFFFF",
    fontFamily: "Raleway, sans-serif",
    fontSize: theme.typography.pxToRem(18),
    textDecoration: "underline",
    marginBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  container: {
    paddingTop: "6rem",
    borderRadius: "2rem",
    backgroundColor: theme.palette.grey[700],
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  listOption: {
    marginTop: "4.7rem",
    width: "47%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  option: {
    display: "flex",
    marginBottom: "19px",
    color: "#FFFFFF",
    position: "relative",
  },
  question: {
    marginLeft: "0.7rem",
    width: "0.8rem",
    cursor: "pointer",
  },
  list: {
    display: "flex",
    justifyContent: "space-around",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  contentOption: {
    position: "absolute",

    left: "13rem",
    padding: "1rem",
    color: theme.palette.grey[800],
    backgroundColor: "#ffffFF",
    width: "80%",

    borderRadius: "1rem",
    zIndex: "10",
  },
  close: {
    position: "absolute",
    right: "8px",
    width: "12px",
    cursor: "pointer",
  },
  titleOption: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: "Nunito Sans, sans-serif",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  descriptionOption: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(11),
    fontFamily: "Nunito Sans, sans-serif",
  },
  icons: {
    width: "60px",
    margin: "1rem 1rem 0 1rem",
  },
  titleFooterPlans: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(18),
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
  },
  titleNext: {
    color: "#fff",
    fontSize: theme.typography.pxToRem(17),
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    marginTop: "3rem",
    fontWeight: "400",
  },
  subTitleNext: {
    color: "#fff",
    fontSize: theme.typography.pxToRem(18),
    fontFamily: "Raleway, sans-serif",
    textAlign: "center",
    fontWeight: "700",
  },
  ButtonNext: {
    width: "15rem",
    height: "3.5rem",
    color: "#4F5B61",
    backgroundColor: "#FFF",
    margin: "2rem 0",
  },
  footerPlans: {
    margin: "2rem 0 1rem ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listPayments: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
}));

export { useStyles };
