import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  loading: {
    backgroundColor: "#000",
  },
  filterBox: {
    position: 'absolute',
    bottom: '10px',
    right: '5px',
  },
  buttonFilter: {
    backgroundColor: "#FFF",
    boxShadow: '0px 0px 4px rgba(34, 40, 43, 0.16)',
  },
  wrap: {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.overrides.MuiDrawer.paper.width + 70,
      width: `calc(100% - ${theme.overrides.MuiDrawer.paper.width + 70}px)`,
    },
    flexGrow: 1,
    width: "100%",
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}))

export { useStyles }
