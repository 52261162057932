export const sendPulsePopUp = () => {
  const script = document.createElement('script');
  script.setAttribute("data-chats-widget-id",'11ac2a1e-fbef-44da-b8e5-eda41539a2c5');
  script.src = 'https://pop-ups.sendpulse.com/assets/loader.js';
  script.async = true;
  return script;
}

export const sendPulseWebPush = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://web.webpushs.com/js/push/6fc0ba4dd7ff1750dae4c1f425cdf985_1.js';
  return script;
}
