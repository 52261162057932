import React from 'react';
import { useHistory } from 'react-router-dom'

import {
  LinearProgress,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  Avatar,
} from '@material-ui/core';

import {
  useStyles
} from './styles'

import NewReleasesIcon from '@material-ui/icons/NewReleases';

function LinearProgressWithLabel(props) {

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{props.value}%</Typography>
      </Box>
    </Box>
  );
}


export default (props) => {

  const classes = useStyles();
  const history = useHistory()

  const getQuiz = (props) => {
    if(!props.demo) {
      history.push(`/dashboard/quizzes/${props.uuid}`)
    }
  }

  return (
    <Card className={classes.root} style={{backgroundColor:props.category?.color ?? '#0F5B7A'}}>
      <CardActionArea className={classes.cardActionArea} onClick={()=> getQuiz(props)}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" flexDirection="column" mt="auto" width="100%">
              <Typography component="h4" className={classes.cardTitle}>{props.title ?? ''}</Typography>
              <Box component="div" display="inline" width="100%" alignItems="center" className={classes.cardFooter}>
                {!props.demo ? (
                  props.isFinished ? `Ver resultado` : <LinearProgressWithLabel value={props.totals?.progress} />
                ) : (
                  <>
                  <Box component="div" display="flex" flexDirection="row">
                    <Box component="div" display="flex" flexDirection="row" alignItems="center">
                      <Avatar className={classes.avatar}>K</Avatar>
                      <Box className={classes.creatorName}>Kultivi</Box>
                    </Box>
                    <NewReleasesIcon className={classes.checkedProfile} />
                  </Box>
                  </>
                )}
              </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
