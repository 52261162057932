import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
    },
    cardTitle: {
        fontWeight: 700,
        color: theme.palette.grey[600],
        fontSize: theme.typography.pxToRem(18),
        marginBottom: theme.spacing(3)
    },
    cardSubtitle: {
        marginBottom: theme.spacing(2)
    },
    tabPanel: {
        flexGrow: 1
    },
    tabs: {
        minWidth: 250,
    },
    tabLabel: {
    },
    formControl: {
        padding: theme.spacing(1),
    },
    ctaKlubiTitle: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(18),
        marginBottom: theme.spacing(2),
    },
    klubiTitleStatus: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(18),
        marginBottom: theme.spacing(2),
    },
    emptyLottie: {
        maxWidth: 100,
        padding: 0,
        margin: 0,
    }
    
}))

export { useStyles }