import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[800],
  },
  brand: {
    maxWidth: 25,
    marginRight: theme.spacing(3),
    cursor: 'pointer'
  },
  menu: {
    width: '100%',
    maxWidth: 400,
    top: 0,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    '& > li:first-child': {
      paddingTop: theme.spacing(1),
    },
    '& > li:last-child': {
      paddingBottom: theme.spacing(1),
    }
  },
  optionMenu: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.primary.main,
    },
  },
  subMenuItem: {
    //
  },
  subMenuItemFeatured: {
    // backgroundColor: theme.palette.secondary.main,
  },
  subItemTitle: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    letterSpacing: '.2px',
    fontWeight: 600,
  },
  subItemDescription: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '20px',
    marginTop: '4px',
    width: 'inherit'
  },
  drawerMenu: {
    flexShrink: 1
  }
}));

export default useStyles;
