import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Hidden,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import systemApi from "app/services/system-api";


import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

// Styles files
import { useStyles } from './styles'

const responsive = {
  large: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
}

export default (props) => {
  const { context, courseSlug } = props
  const [campaigns, setCampaigns] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [showArrows, setShowArrows] = React.useState(false)
  const [infiniteLoop, setInfiniteLoop] = React.useState(false)
  const [autoPlay, setAutoplay] = React.useState(false)

  const classes = useStyles()
  const history = useHistory();

  const goTo = ({link,target}) => {
    if(!link) {
      return;
    }

    if(!target || target === '_self') {
      history.push(`${link}`)
    } else {
      window.open(`${link}`, '_blank');
    }
  }

  useEffect(() => {
    systemApi.get('vendor?context=dashboard')
      .then((response) => {
        const campaigns = response.data
        setShowArrows(campaigns.length > 1)
        setInfiniteLoop(campaigns.length > 1)
        setAutoplay(campaigns.length > 1)
        setCampaigns(campaigns)
      })
      .catch((error) => {
        console.warn(error)
      }).finally(() => {
        setLoading(false);
      })
  }, [])

  return (

    loading ? <Skeleton variant="rect" width='100%' height={190} />
    :
      <Carousel
        itemClass={classes.carouselItem}
        responsive={responsive}
        autoPlaySpeed={3000}
        focusOnSelect={true}
        infinite={infiniteLoop}
        autoPlay={autoPlay}
        arrows={showArrows}
      >
        {campaigns.map((campaign, index) => (

          <Box component="div" display="flex" key={index}>
            <Hidden xsDown>
              <div
                key={campaign.id}
                onClick={() => goTo(campaign)}
                className={classes.link}
              >
                <img
                  className={classes.carouselImg}
                  src={campaign.photo}
                  alt="Banner de campanha"
                />
              </div>
            </Hidden>
            <Hidden smUp>
              <div
                key={campaign.id}
                onClick={() => goTo(campaign)}
                className={classes.link}
              >
                <img
                  className={classes.carouselImg}
                  src={campaign.photoMobile}
                  alt="Banner de campanha"
                />
              </div>
            </Hidden>
          </Box>
        ))}
      </Carousel>

  )
}
