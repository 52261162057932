import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close';

// Forms
import FormChangeUsername from 'app/components/forms/ChangeUsername'
import { useStyles } from './styles'

export default (props) => {

  const classes  = useStyles()
  const {open, toggleDialog, email} = props;

  const [changed, setChanged] = useState(false);

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Box component="div" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" className={classes.closeButton} onClick={toggleDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!changed && "Alterar usuário!"}
      </DialogTitle>
      {changed ? (
        <DialogContent>
          <DialogContentText classes={{root:classes.copy}}>
            <Box mb={2}>Em alguns instantes você receberá no email <strong>{email}</strong> o seu novo código de confirmação!</Box>
            <small>Ah ... não esqueça de verificar também sua caixa de spam ;)</small>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText classes={{root:classes.copy}}>
            <span role="img">⚠️</span> Seu novo e-mail de cadastro será também o seu novo usuário.
          </DialogContentText>
          <FormChangeUsername setChanged={setChanged} />
        </DialogContent>
      )}
    </Dialog>
  )
}
