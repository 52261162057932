import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

// Material UI Imports
import {
  Container,
  Box,
  Button,
} from '@material-ui/core'

// Lotties animations
import Lottie from 'react-lottie'
import animationData from 'lotties/500.json'

// Styles files
import { useStyles } from './styles'

export default function BadRequest() {

  const history = useHistory()


  const classes = useStyles()

  const handleNavBack = useCallback(() => {
    history.push('/')
  }, [history])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="lg">
        <Box component="div" className={classes.root}>
          <h1>Erro interno :(</h1>
          <Lottie
            options={defaultOptions}
            style={{
              maxWidth: 400,
              padding: 0,
              margin: 0,
            }}
          />
          <h2>Ops! Parece que houve um erro inesperado! Tente novamente</h2>
          <Button onClick={handleNavBack} variant="contained" color="primary">
            Voltar para início
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
