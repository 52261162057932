
import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Link,
    Typography,
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/LockOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import ColorBox from 'app/components/utils/thumbnail/MediaColor';
import { useScopes } from 'app/contexts/ScopesContext'
import { useHistory } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { AdsTop } from 'app/components/ads/freestar'

import { useStyles } from './styles'
import { formatDate } from 'app/utils'
import studentApi from 'app/services/student-api'

export default (props) => {

  const classes = useStyles()

  const [certificates, setCertificates] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const { scopes } = useScopes();
  const history = useHistory();

  const download = (url) => {
    window.open(`${url}`, "_blank");
  }

  const cta = (status) => {
    if(status === 'CONCLUDED') {
      return 'EMITIR CERTIFICADO'
    }

    return 'PROCESSANDO CERTIFICADO'
  }

  useEffect(() => {

    studentApi.get(`subscribed-courses/certificates`).then(response => {
      setCertificates(response.data)
      setIsLoading(false)
    }).catch(error => {
      console.warn(error)
    })
  }, [])

  return (
    <>
      <Container maxWidth='md'>
        <Box component="div" mb={7}>
          <Box mb={3}>
            <Typography component="h2" variant="h1" className={classes.sectionTitle}>Meus certificados</Typography>
            <AdsTop mb={5}/>
          </Box>
          {isLoading && (
            <>
              {[0,1,2].map((item) => (
                  <Skeleton variant='rect' height={150} style={{marginBottom: 10}}></Skeleton>
              ))}
            </>
          )}
          {!isLoading && certificates.length === 0 && (
            <Box mx='auto' maxWidth={700}>
              <Card className={classes.incompleteBox}>
                <CardContent className='content'>
                  <Box mb={3} textAlign='center'>
                    <Typography component='p' variant='body2'>
                      Você está no caminho certo para adquirir novos conhecimentos e habilidades.
                      Não desista! Explore nossos cursos e encontre aquele que melhor se encaixa nos seus objetivos.
                      Cada passo que você dá é uma conquista rumo ao seu crescimento pessoal e profissional.
                    </Typography>
                  </Box>
                  <Box mb={5} textAlign='center'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => history.push('/dashboard/enrollments')}>
                        <>
                          <Box mr={2}>CONTINUAR CURSOS</Box>
                          <ArrowRightAltIcon fontSize='small'/>
                        </>
                      </Button>
                  </Box>
                  <Box className='tipBox'>
                    <Typography component='div' className='tip'>Dica:</Typography>
                    <Typography component='p'><span role='img' aria-label='bulb'>💡</span> Defina metas semanais e reserve um tempo dedicado ao estudo. Assim, você verá seu progresso rapidamente!</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}

          <Box mb={4} className={classes.lockedInfo}>
            <Typography className='title'>Nossa Missão: Educação de Qualidade para Todos</Typography>
            <Box mb={2}>
              <Typography component='p' variant='body2'>
                Na Kultivi, acreditamos que a educação é a chave para transformar vidas.
                Nosso compromisso é tornar o conhecimento acessível a todos, sem barreiras.
                E você é parte essencial desse sonho!
              </Typography>
            </Box>
            <Box>
              <Typography component='p' variant='body2'>
                Convidamos você a compartilhar essa oportunidade com mais pessoas.
                Indique amigos para conhecer a Kultivi e ajude-nos a espalhar a educação de qualidade para ainda mais pessoas.
                Juntos, podemos fazer a diferença!{" "}
                <Link component='button' underline='always' style={{fontWeight: 700}} onClick={() => history.push('/dashboard/referrals')}>Saiba mais aqui!</Link>
              </Typography>
            </Box>
          </Box>
          {
            !isLoading && certificates.map((certificate, key) => (
              <Card style={{marginBottom: 20}} key={`cert_${key}`}>
                <CardContent className={classes.certificate}>
                  <Box className='thumbnail'>
                    <ColorBox color={certificate.course.category.color} />
                  </Box>
                  <Box className='content'>
                    <Box mb={3}>
                      <Typography className='title'>{certificate.course.title}</Typography>
                      <Typography component='span' className='info'>Concluído em: {formatDate(certificate.finishedAt)} / Total de aulas: {certificate.course.classes}</Typography>
                    </Box>
                    <Box>
                      <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        onClick={() => download(certificate.url)}
                        disabled={certificate.description !== 'CONCLUDED'}
                      >
                        <Box mr={2}>EMITIR CERTIFICADO</Box>
                        <ArrowRightAltIcon fontSize='small'/>
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          }
        </Box>
      </Container>
    </>
  )
}
