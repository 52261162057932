import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  title: {
    fontSize: theme.typography.pxToRem(23),
    marginBottom: theme.spacing(1),
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 800,
    lineHeight: 1.3,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(4),
    maxWidth: 500,
  },
  button: {
    backgroundColor: '#FFF',
    maxWidth: 300
  },
  hero: {
    width: 130
  }
}))

export { useStyles }
