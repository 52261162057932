import React from 'react';

export default (props) => {
  const { color } = props;

  var rgb = color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
  .substring(1).match(/.{2}/g).map(x => parseInt(x, 16))

  var brightness = .2;

  const dark = [
      Math.floor((255-rgb[0])*brightness),
      Math.floor((255-rgb[1])*brightness),
      Math.floor((255-rgb[2])*brightness)
  ];

  return (
    <div
        style={{
          backgroundColor:`${color}`,
          height: 160,
          position: 'relative',
          overflow: 'hidden',
        }}>
        <div style={{
            position: 'absolute',
            width: 65,
            height: 100,
            borderStyle: 'solid',
            borderBottomRightRadius:90,
            borderTopRightRadius:90,
            borderColor: 'transparent',
            backgroundColor: `rgba(255, 255, 255, 0.2)`,
            left: -20,
            top: -25,
            transform: 'rotate(90deg)',
        }}></div>
        <div style={{
            position: 'absolute',
            width: 180,
            height: 90,
            borderStyle: 'solid',
            borderWidth: 24,
            borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
            borderColor: `rgba(${dark.join(", ")},.3)`,
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
            right: -45,
            bottom: -15,
            transform: 'rotate(-50deg)',
        }}></div>
    </div>
  )
}
